import React from "react";
import Table from "../../components/Table";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../../constants/Data";

const ReturnDebts = () => {
  return (
    <div>
      <Table
        pageTitle={"قەرزە گەڕێندراوەکان"}
        searchName={"قەرزە گەڕێندراوەکان"}
        head={TableHeaders[0].agentPayDebtsHeader}
        sortData={TableDropDowns[0].customerDropDowns}
        query={"agentTransferPayDebts"}
        _editFields={editFields[0].companyReturnDebtFields}
      />
    </div>
  );
};

export default ReturnDebts;
