import React, { useState, useEffect } from "react";
import { CloseIcon, TotalPriceIcon } from "../constants/Icons";
import Select from "react-select";
import TextField from "./TextField";

const SelectItemModal = ({
  branch,
  items,
  setSelectItemModal,
  onSelectItem,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [itemQuantity, setItemQuantity] = useState(null);
  const [showItemCode, setShowItemCode] = useState(false); // New state to toggle label

  const handleSelectChange = (selectedOption) => {
    const selectedItem = branch
      ? items.find((item) => item.item?._id === selectedOption.value)
      : items.data.data.find((item) => item._id === selectedOption.value);
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      item_id: selectedOption.value,
      name: selectedOption?.name,
      code: selectedOption?.code,
      purchase_price: branch
        ? selectedItem.item?.purchase_price
        : selectedItem.purchase_price,
      sell_price: branch
        ? selectedItem.item?.sell_price
        : selectedItem.sell_price,
    }));
    setItemQuantity(null); // Reset item quantity when item changes
  };

  const filteredItems = branch
    ? items.filter((item) => item.quantity !== 0 && item.item !== null)
    : items.data?.data?.filter((item) => item.pieces !== 0);
  let options = branch
    ? filteredItems &&
      filteredItems.map((item) => ({
        value: item.item?._id,
        label: showItemCode ? item.item?.code : item.item?.name, // Change label based on showItemCode state
        code: item.item?.code,
        name: item.item?.name,
      }))
    : filteredItems &&
      filteredItems.map((item) => ({
        value: item._id,
        label: showItemCode ? item.code : item.name, // Change label based on showItemCode state
        code: item.code,
        name: item.name,
      }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "px",
      border: "1px solid #097969",
      borderRadius: "0.375rem",
      padding: "0.200rem",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#a0aec0",
    }),
  };

  const Fields = [
    {
      id: 1,
      label: "نرخی فرۆشتن",
      name: "sell_price",
      type: "number",
      placeholder: "نرخی فرۆشتن",
      icon: <TotalPriceIcon fill={"#A0AEC0"} />,
    },
    {
      id: 2,
      label: "دانە",
      name: "quantity",
      type: "number",
      placeholder: "دانەی ماددە دیاری بکە",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedItems({
      ...selectedItems,
      [name]: value,
    });
  };

  const handleCloseModal = () => {
    setSelectItemModal(false);
  };

  const handleAdd = () => {
    onSelectItem(
      selectedItems.name,
      selectedItems.item_id,
      selectedItems.quantity,
      selectedItems.purchase_price,
      selectedItems.sell_price,
      selectedItems.code
    );
    setSelectItemModal(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="w-[1000px] flex flex-col">
        <div className="bg-white p-2 rounded">
          <div className="flex justify-between mx-3 my-5 border items-center p-2 rounded-md bg-primary">
            <button type="button" className="p-2" onClick={handleCloseModal}>
              <CloseIcon fill={"#fff"} />
            </button>
            <h1 className="text-2xl text-white font-medium flex justify-center w-full tracking-wide">
              دیاری کردنی ماددە
            </h1>
          </div>
          <div className="grid grid-cols-1 mx-3">
            <div className="flex items-center w-full gap-5">
              <Select
                options={options}
                styles={customStyles}
                isSearchable={true}
                onChange={handleSelectChange}
                placeholder={"ماددەیەک دیاری بکە"}
                className="w-[90%]"
              />
              <button
                onClick={() => setShowItemCode(!showItemCode)} // Toggle label on button click
                className="w-[30%] p-2 px-10 text-xl font-medium text-black focus:outline-none 
                  rounded-lg border border-primary hover:bg-primary hover:text-white focus:z-10 
                  focus:ring-4 focus:ring-gray-100 duration-200 ease-in-out"
              >
                ماددەکان بە پێی {showItemCode ? "ناو" : "کۆد"}
              </button>

              {showModal && (
                <ShowModal
                  onCancel={() => setShowModal(false)}
                  itemQuantity={itemQuantity}
                />
              )}
            </div>
            <div>
              {Fields.map((field) => (
                <TextField
                  key={field.id}
                  _id={field.id}
                  _for={field.name}
                  _type={field.type}
                  _placeholder={field.placeholder}
                  _labelText={field.label}
                  _icon={field.icon}
                  _additionalClasses="w-full"
                  onChange={handleInputChange}
                  _value={selectedItems[field.name] || ""}
                />
              ))}
            </div>
            <button
              onClick={handleAdd}
              className="mt-4 p-2 bg-primary text-white rounded-md"
            >
              زیادکردن
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectItemModal;

const ShowModal = ({ onCancel, itemQuantity }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="w-[1000px] flex flex-col">
        <div className="bg-white p-2 rounded">
          <div className="flex justify-between mx-3 my-5 border items-center p-2 rounded-md bg-primary">
            <button type="button" className="p-2" onClick={onCancel}>
              <CloseIcon fill={"#fff"} />
            </button>
            <h1 className="text-2xl text-white font-medium flex justify-center w-full tracking-wide">
              زانیاری ماددە
            </h1>
          </div>
          <div className="p-4">
            <h2 className="text-2xl font-bold">ڕێژەی دانەی ماددە لە لق </h2>
            <p className="py-4 text-xl">
              {itemQuantity !== null
                ? `دانە: ${itemQuantity}`
                : "هیچ ڕێژەیەک بەردەست نیە"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
