import React from "react";
import Table from "../components/Table";
import { TableDropDowns, TableHeaders } from "../constants/Data";

const UserLogs = () => {
  return (
    <div>
      <Table
        pageTitle={"چالاکیەکان"}
        searchName={"چالاکیەکان"}
        head={TableHeaders[0].userLogsTableHeader}
        query={"logs"}
        sortData={TableDropDowns[0].debtDropDowns}
      />
    </div>
  );
};

export default UserLogs;
