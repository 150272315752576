import {
  CustomersIcon,
  DashbordIcon,
  ItemsIcon,
  LocationIcon,
  NameIcon,
  PhoneIcon,
  UserIcon,
  DebtIcon,
  CodeIcon,
  MeterIcon,
  ExpensesIcon,
  CashIcon,
  DebtInputIcon,
  TotalPriceIcon,
  OwnerIcon,
  BranchesIcon,
  OwnersIcon,
  DaxilaIcon,
  NotficationIcon,
  BanksIcon,
  TransfersIcon,
  CompanysIcon,
  AgentsIcon,
  ReturnsIcon,
  PurchasesIcon,
  ReportIcon,
} from "./Icons";

import { IoIosArrowBack } from "react-icons/io";
import { RiFunctionAddLine } from "react-icons/ri";
import { MdOutlinePriceCheck } from "react-icons/md";

const notificationNumber = localStorage.getItem("notfication") || 0;

export const sideBarLinks = [
  {
    superAdminLinks: [
      {
        id: 1,
        name: "داشبۆرد",
        link: "/",
        icon: <DashbordIcon />,
      },
      {
        id: 2,
        name: "بەڕێوبەرەکان",
        link: "#",
        icon: <OwnersIcon fill={"currentColor"} />,
        subLinks: [
          {
            id: 41,
            name: "هەموو",
            link: "/Owners",
            icon: <IoIosArrowBack />,
          },
          {
            id: 42,
            name: "قازانجەکان",
            link: "/Owners/profit",
            icon: <IoIosArrowBack />,
          },
        ],
      },
      {
        id: 3,
        name: "بەکارهێنەرەکان",
        link: "#",
        icon: <UserIcon />,
        subLinks: [
          {
            id: 41,
            name: "هەموو بەکارهێنەرەکان",
            link: "/Users",
            icon: <IoIosArrowBack />,
          },
          {
            id: 42,
            name: "زیادکردنی بەکارهێنەرەکان",
            link: "/AddUsers",
            icon: <IoIosArrowBack />,
          },
          {
            id: 43,
            name: "چالاکی بەکار‌‌‌هێنەرەکان",
            link: "/Logs",
            icon: <IoIosArrowBack />,
          },
        ],
      },
      {
        id: 4,
        name: "کڕیارەکان",
        link: "/Customers",
        icon: <CustomersIcon fill={"currentColor"} />,
      },
      {
        id: 5,
        name: "ماددەکان",
        link: "#",
        icon: <ItemsIcon fill={"currentColor"} />,
        subLinks: [
          {
            id: 41,
            name: "هەموو ماددەکان",
            link: "/Items",
            icon: <IoIosArrowBack />,
          },
          {
            id: 42,
            name: "زیادکردنی ماددە",
            link: "/AddItems",
            icon: <IoIosArrowBack />,
          },
        ],
      },
      {
        id: 6,
        name: "فرۆش",
        link: "Purchases",
        icon: <PurchasesIcon fill={"currentColor"} />,
        subLinks: [
          {
            id: 41,
            name: "هەموو فرۆشەکان",
            link: "/Purchases",
            icon: <IoIosArrowBack />,
          },
          {
            id: 42,
            name: "زیادکردنی فرۆش",
            link: "/AddPurchase",
            icon: <IoIosArrowBack />,
          },
        ],
      },
      {
        id: 7,
        name: "قەرزەکان",
        link: "Debts",
        icon: <DebtIcon fill={"currentColor"} />,
      },
      {
        id: 8,
        name: "خەرجیەکان",
        link: "Expenses",
        icon: <ExpensesIcon />,
      },
      {
        id: 9,
        name: "دەخیلە",
        link: "Daxila",
        icon: <DaxilaIcon />,
      },
      {
        id: 10,
        name: "لقەکان",
        link: "Branches",
        icon: <BranchesIcon fill={"currentColor"} />,
        // subLinks: [
        //   {
        //     id: 41,
        //     name: "لقەکان",
        //     link: "/Branches",
        //     icon: <IoIosArrowBack />,
        //   },
        //   {
        //     id: 42,
        //     name: "دەخیلەی لقەکان",
        //     link: "/DaxilaBranch",
        //     icon: <IoIosArrowBack />,
        //   },
        // ],
      },
      {
        id: 11,
        name: "بانقەکان",
        link: "Banks",
        icon: <BanksIcon fill={"currentColor"} />,
      },
      {
        id: 12,
        name: "حەواڵەکان",
        link: "Transfers",
        icon: <TransfersIcon fill={"currentColor"} />,
      },
      {
        id: 13,
        name: "کۆمپانیاکان",
        link: "#",
        icon: <CompanysIcon fill={"currentColor"} />,
        subLinks: [
          {
            id: 41,
            name: "بینینی هەموو",
            link: "/Companies",
            icon: <IoIosArrowBack />,
          },
          {
            id: 42,
            name: "قەرزی کۆمپانیاکان",
            link: "/CompanyDebts",
            icon: <IoIosArrowBack />,
          },
          {
            id: 43,
            name: "قەرزە گەڕێندراوەکان",
            link: "/CompanyPayDebts",
            icon: <IoIosArrowBack />,
          },
        ],
      },
      {
        id: 14,
        name: "نوسینگەکان",
        link: "#",
        icon: <AgentsIcon fill={"currentColor"} />,
        subLinks: [
          {
            id: 41,
            name: "بینینی هەموو",
            link: "/Agents",
            icon: <IoIosArrowBack />,
          },
          {
            id: 42,
            name: "قەرزی نوسینگەکان",
            link: "/AgentDebts",
            icon: <IoIosArrowBack />,
          },
          {
            id: 43,
            name: "قەرزە گەڕێندراوەکان",
            link: "/AgentPayDebts",
            icon: <IoIosArrowBack />,
          },
        ],
      },
      {
        id: 15,
        name: "ئاگاداریەکان",
        link: "Notfications",
        icon: <NotficationIcon fill={"currentColor"} />,
      },
      {
        id: 16,
        name: "گەڕاندنەوەکان",
        link: "Returns",
        icon: <ReturnsIcon fill={"currentColor"} />,
      },
      {
        id: 17,
        name: "ڕاپۆرت",
        link: "Report",
        icon: <ReportIcon fill={"currentColor"} />,
      },
    ],
  },
];

export const TableHeaders = [
  {
    usersTableHeaders: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "ناوی بەکارهێنەر",
        field: "username",
      },
      {
        id: 3,
        name: "جۆری بەکارهێنەر",
        field: "role",
      },
      {
        id: 4,
        name: "لق",
        field: "branch",
      },
      {
        id: 5,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 6,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    userLogsTableHeader: [
      {
        id: 1,
        name: "ناو",
        field: "user",
      },
      {
        id: 2,
        name: "کردار",
        field: "action",
      },
      {
        id: 3,
        name: "تێبینی",
        field: "description",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    customersTableHeader: [
      {
        id: 1,
        name: "ناو",
        field: "detailName",
      },
      {
        id: 2,
        name: "ژمارە مۆبایل",
        field: "phoneNumber",
      },
      {
        id: 3,
        name: "ناونیشان",
        field: "address",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 5,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 6,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    returnHeader: [
      {
        id: 1,
        name: "ماددەکان",
        field: "items",
      },
      {
        id: 2,
        name: "کڕیار",
        field: "returnCustomer",
      },
      {
        id: 3,
        name: "دانە",
        field: "itemQuantity",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 5,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
    ],
    agentsDebtHeader: [
      {
        id: 1,
        name: "نوسینگە",
        field: "agentTransfer",
      },
      {
        id: 2,
        name: "ڕێژەی قەرز",
        field: "debt_price",
      },
      {
        id: 3,
        name: "ڕێژەی قەرزی ماو",
        field: "debt_amount",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 5,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 6,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    companyDebtTableHeader: [
      {
        id: 1,
        name: "کۆمپانیا",
        field: "company",
      },
      {
        id: 2,
        name: "ڕێژەی قەرز",
        field: "debt_price",
      },
      {
        id: 3,
        name: "ڕێژەی قەرزی ماو",
        field: "debt_amount",
      },
      {
        id: 4,
        name: "تێبینی",
        field: "note",
      },
      {
        id: 5,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 6,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 7,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    agentsTableHeader: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "ژمارە مۆبایل",
        field: "phoneNumber",
      },
      {
        id: 3,
        name: "ناونیشان",
        field: "address",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 5,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 6,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    agentPayDebtsHeader: [
      {
        id: 1,
        name: "ڕێژەی قەرز",
        field: "debt_price",
      },
      {
        id: 2,
        name: "ڕێژەی قەرزی ماوە",
        field: "debt_amount",
      },
      {
        id: 3,
        name: "پارەی گەڕێندراوە",
        field: "pay",
      },
      {
        id: 4,
        name: "تێبینی",
        field: "description",
      },
      {
        id: 5,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 6,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 7,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    companyPayDebtsHeader: [
      {
        id: 1,
        name: "ڕێژەی قەرز",
        field: "debt_price1",
      },
      {
        id: 2,
        name: "ڕێژەی قەرزی ماوە",
        field: "debt_amount1",
      },
      {
        id: 3,
        name: "پارەی گەڕێندراوە",
        field: "pay",
      },
      {
        id: 4,
        name: "تێبینی",
        field: "description",
      },
      {
        id: 5,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 6,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 7,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    transfersTableHeader: [
      {
        id: 1,
        name: "پارە",
        field: "money",
      },
      {
        id: 2,
        name: "عمولە",
        field: "fee",
      },
      {
        id: 3,
        name: "کۆمپانیا",
        field: "company",
      },
      {
        id: 4,
        name: "بانق / نوسینگە",
        field: "bank",
      },
      {
        id: 5,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 6,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 7,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    bankHeaders: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "ناونیشان",
        field: "address",
      },
      {
        id: 3,
        name: "ژمارە تەلەفون",
        field: "phoneNumber",
      },
      {
        id: 4,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 5,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 6,
        name: "کردارەکان",
        field: "actions",
      },
    ],

    itemHeaders: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "کۆد",
        field: "code",
      },
      {
        id: 3,
        name: "دانە",
        field: "pieces",
      },
      {
        id: 4,
        name: "تێچو",
        field: "fee",
      },
      {
        id: 5,
        name: "کاش",
        field: "cash",
      },
      {
        id: 6,
        name: "نرخی کڕین",
        field: "purchase_price",
      },
      {
        id: 7,
        name: "نرخی فرۆشتن",
        field: "sell_price",
      },
      {
        id: 8,
        name: "کۆی گشتی نرخی ماددە",
        field: "totalItemPrice",
      },
      {
        id: 9,
        name: "کۆمپانیا",
        field: "company",
      },
      {
        id: 10,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 11,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 12,
        name: "کردارەکان",
        field: "actions",
      },
      {
        id: 13,
        name: "ناردن بۆ لق",
        field: "sendItem",
      },
      {
        id: 14,
        name: "گەڕاندنەوە لە لق",
        field: "returnItem",
      },
      {
        id: 15,
        name: "زیادکردنی دانە",
        field: "addPieces",
      },
    ],
    reportItemHeaders: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "کۆد",
        field: "code",
      },
      {
        id: 3,
        name: "دانە",
        field: "pieces",
      },
      {
        id: 4,
        name: "تێچو",
        field: "fee",
      },
      {
        id: 5,
        name: "کاش",
        field: "cash",
      },
      {
        id: 6,
        name: "نرخی کڕین",
        field: "purchase_price",
      },
      {
        id: 7,
        name: "نرخی فرۆشتن",
        field: "sell_price",
      },
      {
        id: 8,
        name: "کۆمپانیا",
        field: "company",
      },
      {
        id: 9,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 10,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 11,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    branchItemHeader: [
      {
        id: 1,
        name: "ناو",
        field: "name",
        path: "itemName",
      },
      {
        id: 2,
        name: "کۆد",
        field: "code",
        path: "itemCode",
      },
      {
        id: 3,
        name: "دانە",
        field: "quantity",
      },
      {
        id: 4,
        name: "تێچو",
        field: "fee",
        path: "itemFee",
      },
      {
        id: 5,
        name: "کاش",
        field: "cash",
      },
      {
        id: 6,
        name: "نرخی کڕین",
        field: "purchase_price",
        path: "itemPurchase_price",
      },
      {
        id: 7,
        name: "نرخی فرۆشتن",
        field: "psell_price",
        path: "itemPsell_price",
      },
      // {
      //   id: 8,
      //   name: "کۆمپانیا",
      //   field: "company",
      //   path: "itemCompany",
      // },
      // {
      //   id: 9,
      //   name: "بەکارهێنەر",
      //   field: "addedBy",
      //   path: "itemAddedBy",
      // },
      {
        id: 10,
        name: "بەروار",
        field: "itemCreatedAt",
      },
    ],
    notficationItemHeaders: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "کۆد",
        field: "code",
      },
      {
        id: 3,
        name: "دانە",
        field: "pieces",
      },
      {
        id: 4,
        name: "تێچو",
        field: "fee",
      },
      {
        id: 5,
        name: "نرخی کڕین",
        field: "sell_price",
      },
      {
        id: 6,
        name: "نرخی فرۆشتن",
        field: "purchase_price",
      },
      {
        id: 7,
        name: "کۆمپانیا",
        field: "company",
      },
      {
        id: 8,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 9,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    companyItemsDetailHeader: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "کۆد",
        field: "code",
      },
      {
        id: 3,
        name: "دانە",
        field: "pieces",
      },
      {
        id: 4,
        name: "نرخی کڕین",
        field: "sell_price",
      },
      {
        id: 5,
        name: "نرخی فرۆشتن",
        field: "purchase_price",
      },
      {
        id: 6,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 7,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 8,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    userDetailHeader: [
      {
        id: 1,
        name: "کردار",
        field: "action",
      },
      {
        id: 2,
        name: "تێبینی",
        field: "description",
      },
      {
        id: 3,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    debtHeader: [
      {
        id: 1,
        name: "ڕێژەی قەرز",
        field: "debt_price",
      },
      {
        id: 2,
        name: "ڕێژەی قەرزی ماوە",
        field: "debt_amount",
      },
      {
        id: 3,
        name: "کڕیار",
        field: "customer",
      },
      {
        id: 4,
        name: "لق",
        field: "branch",
      },
      {
        id: 5,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 6,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 7,
        name: "کردارەکان",
        field: "actions",
      },
      {
        id: 8,
        name: "زانیاری قەرز",
        field: "info",
      },
      {
        id: 9,
        name: "کردارەکان",
        field: "return",
      },
    ],
    reportDebtHeader: [
      {
        id: 1,
        name: "ڕێژەی قەرز",
        field: "debt_price",
      },
      {
        id: 2,
        name: "ڕێژەی قەرزی ماوە",
        field: "debt_amount",
      },
      {
        id: 3,
        name: "کڕیار",
        field: "customer",
      },
      {
        id: 4,
        name: "لق",
        field: "branch",
      },
      {
        id: 5,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 6,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 7,
        name: "کردارەکان",
        field: "actions",
      },
      {
        id: 8,
        name: "زانیاری قەرز",
        field: "info",
      },
    ],
    notficationDebtHeader: [
      {
        id: 1,
        name: "ڕێژەی قەرز",
        field: "debt_price",
      },
      {
        id: 2,
        name: "ڕێژەی قەرزی ماوە",
        field: "debt_amount",
      },
      {
        id: 3,
        name: "کڕیار",
        field: "customer",
      },
      {
        id: 4,
        name: "لق",
        field: "branch",
      },
      {
        id: 5,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 6,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    debtDetailHeader: [
      {
        id: 1,
        name: "ڕێژەی قەرز",
        field: "debt_price",
      },
      {
        id: 2,
        name: "ڕێژەی قەرزی ماوە",
        field: "debt_amount",
      },

      {
        id: 3,
        name: "لق",
        field: "branch",
      },
      {
        id: 4,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 5,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 6,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    customerDebtPrint: [
      {
        id: 1,
        name: "ڕێژەی قەرز",
        field: "debt_price",
      },
      {
        id: 2,
        name: "ڕێژەی قەرزی ماوە",
        field: "debt_amount",
      },
      {
        id: 3,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    customerPayDebtDetailHeader: [
      {
        id: 1,
        name: "پارە",
        field: "pay",
      },
      {
        id: 2,
        name: "تێبینی",
        field: "description",
      },
      {
        id: 3,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 5,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    customerPayDebtPrint: [
      {
        id: 1,
        name: "پارە",
        field: "pay",
      },
      {
        id: 2,
        name: "تێبینی",
        field: "description",
      },
      {
        id: 3,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    expensesHeader: [
      {
        id: 1,
        name: "بڕی خەرجی",
        field: "price",
      },
      {
        id: 2,
        name: "تێبینی",
        field: "note",
      },
      {
        id: 3,
        name: "لق",
        field: "branch",
      },
      {
        id: 4,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 5,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 6,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    ownersHeader: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "پشک",
        field: "shares",
      },
      {
        id: 3,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 5,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    branchHeaders: [
      {
        id: 1,
        name: "ناو",
        field: "detailName",
      },
      {
        id: 2,
        name: "ناونیشان",
        field: "address",
      },
      {
        id: 3,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 5,
        name: "کردارەکان",
        field: "actions",
      },
      {
        id: 6,
        name: "دەخیلە",
        field: "debtInfo",
      },
    ],
    purchaseHeader: [
      {
        id: 1,
        name: "ماددەکان",
        field: "items",
      },
      {
        id: 2,
        name: "نرخی فرۆشتنی ماددە",
        field: "itemPrice",
      },
      {
        id: 3,
        name: "دانە",
        field: "itemQuantity",
      },
      {
        id: 4,
        name: "کاش",
        field: "pay_cash",
      },
      {
        id: 5,
        name: "قەرز",
        field: "pay_debt",
      },
      {
        id: 6,
        name: "کۆی گشتی",
        field: "total_price",
      },
      {
        id: 7,
        name: "داشکاندن",
        field: "discount",
      },
      {
        id: 8,
        name: "کڕیار",
        field: "customer",
      },
      {
        id: 9,
        name: "وەسڵ",
        field: "code",
      },
      {
        id: 10,
        name: "لق",
        field: "branch",
      },

      {
        id: 11,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 12,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    purchaseDetailHeader: [
      {
        id: 1,
        name: "ماددەکان",
        field: "items",
      },
      {
        id: 2,
        name: "کاش",
        field: "pay_cash",
      },
      {
        id: 3,
        name: "دانە",
        field: "itemQuantity",
      },
      {
        id: 4,
        name: "قەرز",
        field: "pay_debt",
      },
      {
        id: 5,
        name: "کۆی گشتی",
        field: "total_price",
      },
      {
        id: 6,
        name: "داشکاندن",
        field: "discount",
      },
      {
        id: 7,
        name: "لق",
        field: "branch",
      },
      {
        id: 8,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 9,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    customerPurchasePrint: [
      {
        id: 1,
        name: "ماددەکان",
        field: "items",
      },
      {
        id: 2,
        name: "کاش",
        field: "pay_cash",
      },
      {
        id: 3,
        name: "قەرز",
        field: "pay_debt",
      },
      {
        id: 4,
        name: "کۆی گشتی",
        field: "total_price",
      },
      {
        id: 5,
        name: "داشکاندن",
        field: "discount",
      },

      {
        id: 6,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    ownerProfitHeader: [
      {
        id: 1,
        name: "بەڕێوبەر",
        field: "owner",
      },
      {
        id: 2,
        name: "قازانج",
        field: "profit",
      },
    ],
    daxilaBranchDetailHeader: [
      {
        id: 1,
        name: "پارە",
        field: "price",
      },
      {
        id: 2,
        name: "تێبینی",
        field: "note",
      },
      {
        id: 3,
        name: "لق",
        field: "branch",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 5,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 6,
        name: "کردارەکان",
        field: "actions",
      },
    ],
    branchItemsDetail: [
      {
        id: 1,
        name: "ماددەکان",
        field: "items",
      },
      {
        id: 2,
        name: "دانە",
        field: "quantity",
      },
    ],
    branchUsersDetail: [
      {
        id: 1,
        name: "ناو",
        field: "name",
      },
      {
        id: 2,
        name: "وشەی بەکارهێنەر",
        field: "username",
      },
      {
        id: 2,
        name: "جۆری بەکارهێنەر",
        field: "role",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    payesDetailHeader: [
      {
        id: 1,
        name: "پارەی گەڕیندراوە",
        field: "pay",
      },
      {
        id: 2,
        name: "تێبینی",
        field: "description",
      },
      {
        id: 3,
        name: "بەکارهێنەر",
        field: "addedBy",
      },
      {
        id: 4,
        name: "بەروار",
        field: "createdAt",
      },
    ],
    daxilaHeader: [
      {
        id: 1,
        name: "ڕێژەی پارە",
        field: "price",
      },
      {
        id: 2,
        name: "تێبینی",
        field: "note",
      },
      {
        id: 3,
        name: "بەروار",
        field: "createdAt",
      },
      {
        id: 4,
        name: "کردارەکان",
        field: "actions",
      },
    ],
  },
];
export const TableDropDowns = [
  {
    customerDropDowns: [
      {
        title: "کات",
        description: "ڕێکخستن بەپێی کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
    ],
    expensesDropDown: [
      {
        title: "کات",
        description: "ڕێکخستن بەپێی کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
      {
        title: "خەرجی",
        description: "ڕێکخستن بەپێی خەرجی",
        options: [
          { id: 1, label: "زۆرترین ڕێژەی خەرجی", value: "-price" },
          { id: 2, label: "کەمترین ڕێژەی خەرجی", value: "+price" },
        ],
      },
    ],
    statusDropDowns: [
      {
        title: "ڕێکخستن بەپێی کات",
        description: "کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
    ],
    itemDropDowns: [
      {
        title: "کات",
        description: "ڕێکخستن بەپێی کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
      {
        title: "فرۆشتن",
        description: "ڕێکخستن بەپێی نرخی فرۆشتن",
        options: [
          { id: 1, label: "هەرزانترین نرخ", value: "+sell_price" },
          { id: 2, label: "گرانترین نرخ", value: "-sell_price" },
        ],
      },
      {
        title: "کڕین",
        description: "ڕێکخستن بەپێی نرخی کڕین",
        options: [
          { id: 1, label: "هەرزانترین نرخ", value: "+purchase_price" },
          { id: 2, label: "گرانترین نرخ", value: "-purchase_price" },
        ],
      },
      {
        title: "تێچو",
        description: "ڕێکخستن بەپێی تێچو",
        options: [
          { id: 1, label: "کەمترین تێچو", value: "+fee" },
          { id: 2, label: "زۆرترین تێچو", value: "-fee" },
        ],
      },
      {
        description: "ڕێکخستن بەپێی کاش",
        title: "کاش",
        options: [
          { id: 1, label: "قەرز", value: "+cash" },
          { id: 2, label: "کاش", value: "-cash" },
        ],
      },
    ],
    purchaseDropDown: [
      {
        title: "کات",
        description: "ڕێکخستن بەپێی کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
      {
        title: "کاش",
        description: "ڕێکخستن بەپێی کاش",
        options: [
          { id: 1, label: "نزمترین ڕێژەی کاش", value: "+pay_cash" },
          { id: 2, label: "بەرزترین ڕیژەی کاش", value: "-pay_cash" },
        ],
      },
      {
        title: "قەرز",
        description: "ڕێکخستن بەپێی قەرز",
        options: [
          { id: 1, label: "نزمترین ڕێژەی قەرز", value: "+pay_debt" },
          { id: 2, label: "بەرزترین ڕێژەی قەرز", value: "-pay_debt" },
        ],
      },
    ],
    ownerDropDowns: [
      {
        title: "کات",
        description: "ڕێکخستن بەپێی کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
      {
        title: "پشکەکان",
        description: "ڕێکخستن بەپێی پشکەکان",
        options: [
          { id: 1, label: "زۆرترین پشک", value: "-shares" },
          { id: 2, label: "کەمترین پشک", value: "+shares" },
        ],
      },
    ],
    transferDropDowns: [
      {
        title: "کات",
        description: "ڕێکخستن بەپێی کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
      {
        title: "پارە",
        description: "ڕێکخستن بەپێی ڕێژەی پارە",
        options: [
          { id: 1, label: "زۆرترین ڕێژەی پارە", value: "-money" },
          { id: 2, label: "کەمترین ڕێژەی پارە", value: "+money" },
        ],
      },
      {
        title: "عمولە",
        description: "ڕێکخستن بەپێی ڕێژەی عمولە",
        options: [
          { id: 1, label: "زۆرترین ڕێژەی عمولە", value: "-fee" },
          { id: 2, label: "کەمترین ڕێژەی عمولە", value: "+fee" },
        ],
      },
    ],
    userDropDowns: [
      {
        title: "کات",
        title: "ڕێکخستن بەپێی کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
    ],
    debtDropDowns: [
      {
        title: "کات",
        description: "ڕێکخستن بەپێی کات",
        options: [
          { id: 1, label: "کۆنترین کات بۆ تازەترین", value: "+createdAt" },
          { id: 2, label: "تازەترین کات بۆ کۆنترین", value: "-createdAt" },
        ],
      },
      {
        title: "قەرز",
        description: "ڕێکخستن ڕێژەی قەرز",
        options: [
          { id: 1, label: "زۆرترین ڕێژەی قەرز", value: "-debt_price" },
          { id: 2, label: "کەمترین ڕێژەی قەرز", value: "+debt_price" },
        ],
      },
    ],
  },
];
export const addModalFields = [
  {
    daxilaFields: [
      {
        id: 1,
        label: "ڕێژەی پارە",
        name: "price",
        type: "number",
        placeholder: "ڕێژەی پارە دیاری بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "تێبینی",
        name: "note",
        type: "text",
        placeholder: "تێبینیەکت هیە ؟ ",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
    ],
    daxilaBranchFields: [
      {
        id: 1,
        label: "ڕێژەی پارە",
        name: "price",
        type: "number",
        placeholder: "ڕێژەی پارە دیاری بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "تێبینی",
        name: "note",
        type: "text",
        placeholder: "تێبینیەکت هیە ؟ ",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "لق",
        name: "branch",
        type: "object",
        placeholder: "تێبینیەکت هیە ؟ ",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
    ],
    bankfields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
        placeholder: "ناوی بانق داخل بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "ژمارەی تەلەفون",
        name: "phoneNumber",
        type: "number",
        placeholder: "ژمارەی تەلەفون",
        error: " slaw ",
        icon: <PhoneIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "ناونیشان",
        name: "address",
        type: "text",
        placeholder: "ناونیشان",
        error: "",
        icon: <LocationIcon fill={"#A0AEC0"} />,
      },
    ],
    companyFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
        placeholder: "ناوی بانق داخل بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "ژمارەی تەلەفون",
        name: "phoneNumber",
        type: "number",
        placeholder: "ژمارەی تەلەفون",
        error: " slaw ",
        icon: <PhoneIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "ناونیشان",
        name: "address",
        type: "text",
        placeholder: "ناونیشان",
        error: "",
        icon: <LocationIcon fill={"#A0AEC0"} />,
      },
    ],
    transfersFields: [
      {
        id: 1,
        label: "پارە",
        name: "money",
        type: "number",
        placeholder: "ڕێژەی پارە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "عمولە",
        name: "fee",
        type: "number",
        placeholder: "ڕێژەی عمولە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "کۆمپانیا",
        name: "company",
        type: "object",
        placeholder: "کۆمپانیایەک دیاری بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 4,
        label: "بانق",
        name: "bank",
        type: "object",
        placeholder: "بانقێک دیاری بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 5,
        label: "نوسینگە",
        name: "agentTransfer",
        type: "object",
        placeholder: "نوسینگەیەک دیاری بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
    ],
    payDebtFields: [
      {
        id: 1,
        label: "پارە",
        name: "pay",
        type: "number",
        placeholder: "ڕێژەی گەڕاندنەوە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "تێبینی",
        name: "description",
        type: "text",
        placeholder: "تێبینیەکت هەیە ؟",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
    ],
    addDebtFields: [
      {
        id: 1,
        label: "ڕێژەی قەرز",
        name: "debt_amount",
        type: "number",
        placeholder: "ڕێژەی قەرز",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "کڕیار",
        name: "customer",
        type: "object",
        placeholder: "کڕیارێک دیاری بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
    ],
    agentTRansferDebtsField: [
      {
        id: 1,
        label: "پارە",
        name: "debt_amount",
        type: "number",
        placeholder: "ڕێژەی قەرز",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "نوسینگە",
        name: "agentTransfer",
        type: "object",
        placeholder: "نوسینگەیەک دیاری بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
    ],
    companyDebtsField: [
      {
        id: 1,
        label: "پارە",
        name: "debt_price",
        type: "number",
        placeholder: "ڕێژەی قەرز",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "تێبینی",
        name: "note",
        type: "text",
        placeholder: "تێبینیەکت هەیە ؟",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "کۆمپانیا",
        name: "company",
        type: "object",
        placeholder: "کۆمپانیایەک دیاری بکە",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
    ],
    customerFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
        placeholder: "ناو",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "ژمارەی تەلەفون",
        name: "phoneNumber",
        type: "number",
        placeholder: "ژمارەی تەلەفون",
        error: " slaw ",
        icon: <PhoneIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "ناونیشان",
        name: "address",
        type: "text",
        placeholder: "ناونیشان",
        error: "",
        icon: <LocationIcon fill={"#A0AEC0"} />,
      },
    ],
    agentFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
        placeholder: "ناو",
        error: "تکەیە ناوێک داخل بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "ژمارەی تەلەفون",
        name: "phoneNumber",
        type: "number",
        placeholder: "ژمارەی تەلەفون",
        error: " slaw ",
        icon: <PhoneIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "ناونیشان",
        name: "address",
        type: "text",
        placeholder: "ناونیشان",
        error: "",
        icon: <LocationIcon fill={"#A0AEC0"} />,
      },
    ],
    sendItemsToBranch: [
      {
        id: 1,
        label: "دانە",
        name: "quantity",
        type: "number",
        placeholder: "ڕێژەی ماددە دیاری بکە",
        error: "",
        icon: <ItemsIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "لق",
        name: "branchId",
        type: "object",
        placeholder: "تکایە لقێک دیاری بکە",
        error: "",
        icon: <LocationIcon fill={"#A0AEC0"} />,
      },
    ],
    expensesFields: [
      {
        id: 1,
        label: "بڕی خەرجی",
        name: "price",
        type: "number",
        placeholder: "بڕی خەرجی",
        icon: <PhoneIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "تێبینی",
        name: "note",
        type: "text",
        placeholder: "هیچ تێبینیەکت هەیە؟",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
    ],
    ownersFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
        placeholder: "ناوێک داخڵ بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "پشک",
        name: "shares",
        type: "number",
        placeholder: "ڕێژەی پشک دیاری بکە",
        icon: <OwnerIcon fill={"#A0AEC0"} />,
      },
    ],
    branchFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
        placeholder: "ناوێک داخڵ بکە",
        icon: <NameIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "ناونیشان",
        name: "address",
        type: "text",
        placeholder: "ناونیشانێك بنووسە",
        icon: <LocationIcon fill={"#A0AEC0"} />,
      },
    ],
  },
];
export const addFields = [
  {
    itemFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
        placeholder: "ناوی ماددە",
        error: "",
        icon: <RiFunctionAddLine fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "کۆد",
        name: "code",
        type: "text",
        placeholder: "کۆدی ماددە",
        icon: <CodeIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "دانە",
        name: "pieces",
        type: "number",
        placeholder: "ڕیژەی دانەی ماددە",
        icon: <MeterIcon fill={"#A0AEC0"} />,
      },
      {
        id: 4,
        label: "نرخی کڕین",
        name: "purchase_price",
        type: "number",
        placeholder: "نرخی کڕینی ماددە",
        icon: <MdOutlinePriceCheck fill={"#A0AEC0"} />,
      },
      {
        id: 5,
        label: "نرخی فرۆشتن",
        name: "sell_price",
        type: "number",
        placeholder: "نرخی فرۆشتنی ماددە",
        icon: <MdOutlinePriceCheck fill={"#A0AEC0"} />,
      },
      {
        id: 6,
        label: "تێچوو",
        name: "fee",
        type: "number",
        placeholder: " تێچوی ماددە",
        icon: <MdOutlinePriceCheck fill={"#A0AEC0"} />,
      },
      {
        id: 7,
        label: "کاش",
        name: "cash",
        type: "object",
        placeholder: "کاش",
        icon: <MdOutlinePriceCheck fill={"#A0AEC0"} />,
      },
      {
        id: 8,
        label: "کۆمپانیا",
        name: "company",
        type: "object",
        placeholder: "کۆمپانیا دیاری بکە",
        icon: <MdOutlinePriceCheck fill={"#A0AEC0"} />,
      },
    ],
    purchaseFields: [
      {
        id: 1,
        label: "کڕیار",
        name: "customer",
        type: "object",
        placeholder: "کڕیارێک دیاری بکە",
        icon: <TotalPriceIcon fill={"#A0AEC0"} />,
      },
      {
        id: 2,
        label: "کاش",
        name: "pay_cash",
        type: "number",
        placeholder: "ڕێژەی  کاش",
        icon: <CashIcon fill={"#A0AEC0"} />,
      },
      {
        id: 3,
        label: "قەرز",
        name: "pay_debt",
        type: "number",
        placeholder: "ڕێژەی  قەرز",
        icon: <DebtInputIcon fill={"#A0AEC0"} />,
      },
      {
        id: 4,
        label: "داشکاندن",
        name: "discount",
        type: "number",
        placeholder: "ڕێژەی  داشکاندن",
        icon: <DebtInputIcon fill={"#A0AEC0"} />,
      },
      {
        id: 5,
        label: "کات",
        name: "createdAt",
        type: "date",
        placeholder: "ڕێژەی  قەرز",
        icon: <DebtInputIcon fill={"#A0AEC0"} />,
      },
      {
        id: 6,
        label: "",
        name: "items",
        type: "select",
        placeholder: "کۆی گشتی نرخ",
        icon: <TotalPriceIcon fill={"#A0AEC0"} />,
      },
    ],
  },
];
export const editFields = [
  {
    daxilaFields: [
      {
        id: 1,
        label: "ڕێژەی پارە",
        name: "price",
        type: "number",
      },
      {
        id: 2,
        label: "تێبینی",
        name: "note",
        type: "text",
      },
    ],
    customerFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
      },
      {
        id: 2,
        label: "ژمارەی مۆبایل",
        name: "phoneNumber",
        type: "number",
      },
      {
        id: 3,
        label: "ناونیشان",
        name: "address",
        type: "text",
      },
    ],
    customerFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
      },
      {
        id: 2,
        label: "ژمارەی مۆبایل",
        name: "phoneNumber",
        type: "number",
      },
      {
        id: 3,
        label: "ناونیشان",
        name: "address",
        type: "text",
      },
    ],
    selectedItemsField: [
      {
        id: 1,
        label: "دانە",
        name: "quantity",
        type: "number",
      },
      {
        id: 2,
        label: "نرخی فرۆشتن",
        name: "sell_price",
        type: "number",
      },
    ],
    companySelfDebtFields: [
      {
        id: 1,
        label: "تێبینی",
        name: "note",
        type: "text",
      },
      {
        id: 2,
        label: "ڕێژەی قەرزی ماوە",
        name: "debt_amount",
        type: "number",
      },
    ],
    companyReturnDebtFields: [
      {
        id: 1,
        label: "تێبینی",
        name: "description",
        type: "text",
      },
    ],
    agentDebtFields: [
      {
        id: 1,
        label: "نوسینگە",
        name: "agentTransfer",
        type: "object",
      },
      {
        id: 2,
        label: "نوسینگە",
        name: "debt_amount",
        type: "number",
      },
    ],
    transfersFields: [
      {
        id: 1,
        label: "پارە",
        name: "money",
        type: "number",
      },
      {
        id: 2,
        label: "عمولە",
        name: "fee",
        type: "number",
      },
      {
        id: 3,
        label: "کۆمپانیا",
        name: "company",
        type: "object",
      },
      {
        id: 4,
        label: "بانق",
        name: "bank",
        type: "object",
      },
      {
        id: 5,
        label: "نوسینگە",
        name: "agentTransfer",
        type: "object",
      },
    ],
    expensesFields: [
      {
        id: 1,
        label: "بڕی خەرجی",
        name: "price",
        type: "number",
      },
      {
        id: 2,
        label: "تێبینی",
        name: "note",
        type: "text",
      },
      {
        id: 3,
        label: "لق",
        name: "branch",
        type: "object",
      },
    ],
    itemsFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
      },
      {
        id: 2,
        label: "کۆد",
        name: "code",
        type: "text",
      },
      {
        id: 3,
        label: "نرخی کڕین",
        name: "purchase_price",
        type: "number",
      },
      {
        id: 4,
        label: "نرخی فرۆشتن",
        name: "sell_price",
        type: "number",
      },
      {
        id: 5,
        label: "کۆمپانیا",
        name: "company",
        type: "object",
      },
      {
        id: 6,
        label: "کاش",
        name: "cash",
        type: "object",
      },
    ],
    currencyFields: [
      {
        id: 1,
        label: "دینار",
        name: "dinar",
        type: "number",
      },
      { id: 2, label: "یۆن", name: "yuan", type: "number" },
    ],
    ownersFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
      },
      {
        id: 2,
        label: "پشک",
        name: "shares",
        type: "number",
      },
    ],
    usersFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
      },
    ],
    branchFields: [
      {
        id: 1,
        label: "ناو",
        name: "name",
        type: "text",
      },
      {
        id: 2,
        label: "ناونیشان",
        name: "address",
        type: "text",
      },
    ],
    debtFields: [
      {
        id: 1,
        label: "قەرزی ماوە",
        name: "debt_amount",
        type: "number",
      },
    ],
    purchaseFields: [
      {
        id: 1,
        label: "کاش",
        name: "pay_cash",
        type: "number",
      },
      {
        id: 2,
        label: "قەرز",
        name: "pay_debt",
        type: "number",
      },
    ],
    payDebtFields: [
      {
        id: 1,
        label: "پارەی گەڕێندراوە",
        name: "pay",
        type: "number",
      },
      {
        id: 2,
        label: "تێبینی",
        name: "description",
        type: "text",
      },
    ],
  },
];

export const printHeaders = [
  {
    purchasePrintHeader: [
      {
        id: 1,
        name: "ناوی کاڵا",
        field: "item_name",
      },
      {
        id: 2,
        name: "کۆدی کاڵا",
        field: "item_code",
      },
      {
        id: 3,
        name: "بڕ",
        field: "quantity",
      },
      {
        id: 4,
        name: "نرخی فرۆشتن",
        field: "sell_price",
      },
      {
        id: 5,
        name: "کۆ",
        field: "total_price",
      },
    ],
    payDebtHeader: [
      {
        id: 1,
        name: "پارەی گەڕێندراوە",
        field: "pay",
      },
      {
        id: 2,
        name: "داشکاندن",
        field: "discount",
      },
      {
        id: 3,
        name: "تێبینی",
        field: "description",
      },
    ],
  },
];
