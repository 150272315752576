import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetResourceQuery } from "../../features/api/apiSlice";
import PageTitle from "../../components/PageTitle";
import { TbTournament } from "react-icons/tb";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import DetailTable from "../../components/DetailTable";
import { TableHeaders } from "../../constants/Data";
import { ItemNameIcon, LoadingIcon } from "../../constants/Icons";
import { HoverEffect } from "../../components/HoverEffect";

const CompanyDetail = () => {
  const { id } = useParams();

  const [searchQueries, setSearchQueries] = useState({});
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const {
    data: companyData,
    error,
    isLoading: loading,
  } = useGetResourceQuery(`/companies/${id}`);
  if (loading)
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  if (error) return <p>Error loading customer data</p>;

  const branchInfo = companyData && companyData.data?.data;

  const { name, address, items, selfDebts, phoneNumber } = branchInfo;
  const {
    totalCashItems,
    totalDebt,
    totalDebtItems,
    totalDebtPrice,
    totalItems,
  } = companyData;
  const customer = [
    { id: 1, icon: <TbTournament />, data: name },
    { id: 2, icon: <FaLocationDot />, data: address },
    { id: 3, icon: <FaPhone />, data: phoneNumber },
  ];

  const tableConfigs = [
    {
      searchName: "ماددەکان",
      tableTitle: "ماددەکان",
      data: items,
      head: TableHeaders[0].companyItemsDetailHeader,
      // sort: TableDropDowns[0].itemDropDowns,
    },
    {
      searchName: "قەرزەکان",
      tableTitle: "قەرزەکان",
      data: selfDebts,
      head: TableHeaders[0].debtDetailHeader,
      // sort: TableDropDowns[0].customerDropDowns,
      _returnAllButton: true,
    },
  ];
  const totalConfigs = [
    {
      id: 1,
      title: "کۆی گشتی ماددەکان",
      icon: <ItemNameIcon />,
      description: totalItems,
    },
    {
      id: 2,
      title: "کۆی گشتی ماددەی کاش",
      icon: <ItemNameIcon />,
      description: totalCashItems,
    },
    {
      id: 3,
      title: "کۆی گشتی ماددەی قەرز ",
      icon: <ItemNameIcon />,
      description: totalDebtItems,
    },
    {
      id: 4,
      title: "کۆی گشتی قەرزەکان",
      icon: <ItemNameIcon />,
      description: totalDebt,
    },
    {
      id: 5,
      title: "کۆی گشتی قەرزی ماوە",
      icon: <ItemNameIcon />,
      description: totalDebtPrice,
    },
  ];
  const allPrintData = {
    customer: name,
    phone: phoneNumber,
    location: address,
    customer_id: id,
    remainDebt: totalDebt,
  };
  return (
    <div className="w-full">
      <PageTitle title={"زانیاری کۆمپانیا"} />
      <div className="flex flex-col lg:flex-row justify-between lg:py-5 lg:px-8 my-8 py-3 px-4 mx-2 lg:mx-   2xl:mx-16 ">
        <div className="flex p-5">
          {customer.map((item) => (
            <div key={item.id} className="flex items-center px-10 text-2xl">
              <div className="ml-2  text-primary">{item.icon}</div>
              <h1 className="">{item.data}</h1>
            </div>
          ))}
        </div>
      </div>
      <div>
        <HoverEffect items={totalConfigs} />
      </div>
      <div className=" mx-2 lg:mx-12 2xl:mx-16 pb-20 ">
        {companyData &&
          tableConfigs.map((config, index) => (
            <DetailTable
              key={index}
              searchName={config.searchName}
              tableTitle={config.tableTitle}
              data={config.data}
              searchQuery={searchQueries[index] || ""}
              setSearchQuery={(value) => handleSearchQueryChange(index, value)}
              sortQuery={sortQuery}
              setSortQuery={setSortQuery}
              page={page}
              setPage={setPage}
              query={config.query}
              head={config.head}
              itemsPerPage={10}
              sortData={config.sort}
              queryId={id}
              payAllDebtQuery={`selfPayDebts/distribute`}
              _returnAllButton={config._returnAllButton}
              allPrintData={allPrintData}
            />
          ))}
      </div>
    </div>
  );
};

export default CompanyDetail;
