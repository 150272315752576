import React from "react";
import Table from "../../components/Table";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../../constants/Data";
import { useParams } from "react-router-dom";

const ExpensesBranch = () => {
  const { id } = useParams();
  let fields = addModalFields[0].expensesFields.filter(
    (headerItem) => headerItem.name !== "branch"
  );

  let editField = editFields[0].expensesFields.filter(
    (headerItem) => headerItem.name !== "branch"
  );

  return (
    <div>
      <Table
        _addButton={true}
        pageTitle={"خەرجیەکان"}
        searchName={"خەرجیەکان"}
        head={TableHeaders[0].expensesHeader}
        sortData={TableDropDowns[0].expensesDropDown}
        _textfield={fields}
        query={`expenses/getAllExpensesByBranch/${id}`}
        _editFields={editField}
      />
    </div>
  );
};

export default ExpensesBranch;
