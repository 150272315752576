import React, { useState, useEffect } from "react";
import { CloseIcon } from "../constants/Icons";
import PrimaryButton from "./PrimaryButton";
import toast from "react-hot-toast";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  useGetResourceQuery, // Keep API query
  useUpdateResourceMutation, // Keep update mutation
} from "../features/api/apiSlice";
import { useLocation } from "react-router-dom";

const EditModal = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const { onCancel, _editFields, initialData, id, _query } = props;

  const initialItem = initialData?.data
    ? initialData.data?.data?.find((item) => item._id === id)
    : initialData.find((item) => item._id === id);
  const [formData, setFormData] = useState(initialItem || {});
  const [selectedItem, setSelectedItem] = useState(formData.items?.[0] || {}); // Default to the first item in formData.items
  const [errMsg, setErrMsg] = useState(null);

  //API calls

  const { data: banks } = useGetResourceQuery(`/banks`);
  const { data: company } = useGetResourceQuery(`/companies`);
  const { data: agentTransfers } = useGetResourceQuery(`/agentTransfers`);
  const { data: branches } = useGetResourceQuery(`/branches`);

  const [updateResource, { error, isLoading }] = useUpdateResourceMutation();

  useEffect(() => {
    if (formData.items && formData.items.length > 0) {
      setSelectedItem({
        ...formData.items[0],
        quantity: formData.items[0]?.quantity || 0,
        sell_price: formData.items[0]?.sell_price || 0,
      });
    }
  }, [formData.items]);

  //handlers
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newValue = name === "quantity" ? value || 0 : value; // Set quantity to 0 if empty

    if (name === "quantity" && formData.items) {
      const updatedItems = [...formData.items];
      updatedItems[index] = { ...updatedItems[index], quantity: newValue };
      setFormData({ ...formData, items: updatedItems });
    } else if (name === "sell_price" && formData.items) {
      const updatedItems = [...formData.items];
      updatedItems[index] = { ...updatedItems[index], sell_price: value };
      setFormData({ ...formData, items: updatedItems });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  // Update selected item when changing the dropdown selection
  const handleSelectItemChange = (selectedOption) => {
    const item = formData.items.find((i) => i._id === selectedOption.value);
    setSelectedItem(item); // Update the selected item in the state
  };

  // Handle changes to the quantity and sell_price of the selected item
  const handleItemChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "quantity" ? value || 0 : value; // Set quantity to 0 if empty

    setSelectedItem((prev) => ({ ...prev, [name]: newValue })); // Update the selected item

    // Update the item within formData
    const updatedItems = formData.items.map((item) =>
      item._id === selectedItem._id ? { ...item, [name]: newValue } : item
    );
    setFormData((prev) => ({ ...prev, items: updatedItems }));
  };
  // Sync selectedItem state when formData.items or selected item changes
  useEffect(() => {
    const updatedItem = formData.items?.find(
      (item) => item._id === selectedItem._id
    );
    setSelectedItem(updatedItem || {});
  }, [formData.items, selectedItem._id]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const totalPrice = calculateTotalPrice();
    const totalPayment =
      parseFloat(formData.pay_cash || 0) + parseFloat(formData.pay_debt || 0);

    if (totalPayment > totalPrice) {
      toast.error("کۆی پارەی دراو پێویستە یەکسان بێت بە کۆی نرخەکان.", {
        className: "py-4 text-xl",
        style: { direction: "rtl" },
      });
      return;
    }

    try {
      let requestData = {
        ...formData,
        items:
          formData.items &&
          formData.items.map(({ item, ...rest }) => ({
            item: item._id, // Keep only the item ID
            ...rest, // Spread the rest of the item properties
          })),
      };
      await updateResource({
        url: `/${_query}/${id}`,
        formData: requestData,
      }).unwrap;

      toast.success("بە سەرکەوتوی نوێ کرایەوە", {
        className: "py-4 text-xl",
        style: { direction: "rtl" },
      });
      onCancel();
    } catch (err) {
      console.log("err :", err);
      if (err.data?.error) {
        const validationErrors = {};
        props.errorNames &&
          props.errorNames.forEach((error) => {
            const fieldName = error.name;
            if (err.data?.error?.errors?.[fieldName]?.message) {
              validationErrors[fieldName] = t(
                err.data?.error?.errors?.[fieldName]?.message
              );
            }
          });

        setErrMsg(validationErrors);
      }
    }
  };

  const calculateTotalPrice = () => {
    if (!formData.items) return 0;
    return formData.items.reduce((total, item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const price = parseFloat(item.sell_price) || 0;
      return total + quantity * price;
    }, 0);
  };

  const itemOptions = formData.items?.map((item) => ({
    value: item._id,
    label: item.item?.name || "Unnamed Item",
  }));

  const handleSelectChange = (selectedOption, field) => {
    if (selectedOption) {
      setFormData({ ...formData, [field]: selectedOption.value });
    } else {
      setFormData({ ...formData, [field]: null }); // or set to any default value you prefer
    }
  };

  const inputs = _editFields.map((item, index) => {
    if (item.type === "object") {
      let options = [];
      if (item.name === "company" && company) {
        options = company.data?.data?.map((item) => ({
          value: item._id,
          label: item.name,
        }));
      } else if (
        item.name === "bank" &&
        banks &&
        formData.hasOwnProperty("bank")
      ) {
        options = banks.data?.data?.map((item) => ({
          value: item._id,
          label: item.name,
        }));
      } else if (
        item.name === "agentTransfer" &&
        agentTransfers &&
        formData.hasOwnProperty("agentTransfer")
      ) {
        options = agentTransfers.data?.data?.map((item) => ({
          value: item._id,
          label: item.name,
        }));
      } else if (item.name === "branch" && branches) {
        options = branches.data?.data?.map((item) => ({
          value: item._id,
          label: item.name,
        }));
      } else if (item.name === "cash") {
        options = [
          { value: true, label: "بەڵێ" },
          { value: false, label: "نەخێر" },
        ];
      }

      if (
        (item.name === "bank" && !formData.hasOwnProperty("bank")) ||
        (item.name === "agentTransfer" &&
          !formData.hasOwnProperty("agentTransfer"))
      ) {
        return null; // Skip rendering if bank or agentTransfer is not in formData
      }

      const customStyles = {
        control: (provided) => ({
          ...provided,
          minHeight: "2.5rem",
          border: "1px solid #097969",
          borderRadius: "0.375rem",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#a0aec0",
        }),
      };

      const defaultValue = {
        value: formData[item.name]?._id,
        label: formData[item.name]?.name,
      };

      return (
        <div key={item.name} className="mt-4">
          <label className="flex justify-start items-start mb-2 text-md font-medium text-black">
            {item.label}
          </label>
          <Select
            options={options}
            styles={customStyles}
            isSearchable={true}
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, item.name)
            }
            isClearable={true}
            defaultValue={defaultValue}
          />
        </div>
      );
    }

    return (
      <div key={item.name} className="mt-4">
        <label className="flex justify-start items-start mb-2 text-md font-medium text-black">
          {item.label}
        </label>
        <input
          type={item.type}
          name={item.name}
          value={formData[item.name] || ""}
          onChange={(e) => handleChange(e, index)}
          className="block w-full px-3 py-2 text-sm border border-primary rounded-lg"
          required
        />
      </div>
    );
  });

  const itemsInput = formData.items && (
    <>
      <div className="mt-4">
        <label className="flex justify-start items-start mb-2 text-md font-medium text-black">
          ماددەکان
        </label>
        <Select
          options={itemOptions}
          onChange={handleSelectItemChange}
          defaultValue={itemOptions[0]} // Set default value to the first item
          className="block w-full"
          styles={{
            control: (provided) => ({
              ...provided,
              minHeight: "2.5rem",
              border: "1px solid #097969",
              borderRadius: "0.375rem",
            }),
          }}
        />
      </div>

      <div className="mt-4">
        <label className="flex justify-start items-start mb-2 text-md font-medium text-black">
          دانە
        </label>
        <input
          type="number"
          name="quantity"
          value={selectedItem.quantity || ""}
          onChange={handleItemChange}
          className="block w-full px-3 py-2 text-sm border border-primary rounded-lg"
        />
      </div>

      <div className="mt-4">
        <label className="flex justify-start items-start mb-2 text-md font-medium text-black">
          نرخ
        </label>
        <input
          type="number"
          name="sell_price"
          value={selectedItem.sell_price || ""}
          onChange={handleItemChange}
          className="block w-full px-3 py-2 text-sm border border-primary rounded-lg"
        />
      </div>
    </>
  );

  console.log("formData :", formData);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="w-[1000px] flex flex-col">
        <div className="bg-white p-2 rounded">
          <div className="flex justify-between mx-3 my-5 border items-center p-2 rounded-md bg-primary">
            <button type="button" className="p-2" onClick={onCancel}>
              <CloseIcon fill={"#fff"} />
            </button>
            <h1 className="text-2xl text-white font-medium flex justify-center w-full tracking-wide">
              نوێکردنەوە
            </h1>
          </div>
          <div>
            <form className="px-5 ">
              <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
                {inputs}
                {path === "/Branches" ? "" : itemsInput}

                {path === "/Branches"
                  ? ""
                  : formData.items && (
                      <div className="mt-4 col-span-2">
                        <h1 className="text-md font-medium text-black">
                          کۆی گشتی نرخ: {calculateTotalPrice().toFixed(2)}
                        </h1>
                      </div>
                    )}
              </div>
              <div className="col-span-2 mb-4">
                <PrimaryButton
                  _text={"نوێکردنەوە"}
                  _additionalClasses={"w-full mt-5"}
                  onClick={handleUpdate}
                  _isLoading={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;

// //API calls
// const [updateResource, { error, isLoading }] = useUpdateResourceMutation();
// const { data: banks } = useGetResourceQuery(`/banks`);
// const { data: company } = useGetResourceQuery(`/companies`);
// const { data: agentTransfers } = useGetResourceQuery(`/agentTransfers`);
// const { data: branches } = useGetResourceQuery(`/branches`);
