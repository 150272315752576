import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../components/PageTitle";
import TextField from "../components/TextField";
import { useGetResourceQuery } from "../features/api/apiSlice";
import { useLocation, useParams } from "react-router-dom";
import DetailTable from "../components/DetailTable";
import { TableHeaders } from "../constants/Data";
import { BsFillPersonFill } from "react-icons/bs";
import { GrUserAdmin } from "react-icons/gr";

const UserDetail = () => {
  const { id } = useParams();
  //states
  const [searchQuery, setSearchQuery] = useState("");
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const [errMsg, setErrMsg] = useState("");

  const itemsPerPage = 10;
  //API calls
  const { data, isLoading, isError } = useGetResourceQuery(
    `/users/${id}?search=${searchQuery}&sort=${sortQuery}&page=${page}&limit=${itemsPerPage}`
  );
  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading customer data</p>;
  const logsInfo = data && data.data?.data;

  const { logs, name, role } = logsInfo;

  const userInfo = [
    { id: 1, icon: <BsFillPersonFill />, data: name },
    { id: 2, icon: <GrUserAdmin />, data: role },
  ];
  return (
    <div className="w-full">  
      <PageTitle title={"پڕۆفایل"} />
      <div className="flex flex-col lg:flex-row justify-between  ">
        <div className="flex p-5">
          {userInfo.map((item) => (
            <div
              key={item.id}
              className="flex items-center px-1 text-xl lg:px-10 lg:text-2xl"
            >
              <div className="ml-2  text-primary">{item.icon}</div>
              <h1 className="">{item.data}</h1>
            </div>
          ))}
        </div>
      </div>
      <div className="lg:mx-16 mx-2 mt-16 xl:mt-5 p-4 ">
        <div className="">
          <DetailTable
            searchName={""}
            data={logs}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            sortQuery={sortQuery}
            setSortQuery={setSortQuery}
            page={page}
            setPage={setPage}
            head={TableHeaders[0].userDetailHeader}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
