import React, { useState } from "react";
import { ItemsIcon, LoadingIcon } from "../constants/Icons";
import PrimaryButton from "./PrimaryButton";
import TextField from "./TextField";
import { MdArrowBack, MdArrowForward, MdSearch } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { Pagination } from "flowbite-react";
import { Flowbite } from "flowbite-react";
import { FaSearch } from "react-icons/fa";
import {
  useDeleteResourceMutation,
  useGetResourceQuery,
  useUpdateResourceMutation,
} from "../features/api/apiSlice";
import ServerError from "../pages/ServerError";
import PageTitle from "./PageTitle";
import DropDown from "./DropDown";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import ConfirmModal from "./ConfirmModal";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addModalFields } from "../constants/Data";

const Table = (props) => {
  const [updateResource, isLoading] = useUpdateResourceMutation();
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";
  let branch = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
      branch = userData.branch; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }
  // props
  const {
    query,
    head,
    searchName,
    pageTitle,
    sortData,
    _textfield,
    _editFields,
    addPath,
    _addButton,
    _blockButton,
    actionButtonText,
    _totalItem,
    _detailPath,
    _returnButton,
    errorNames,
    printButton,
  } = props;
  // states
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const [openAddModal, setOpenAddModal] = useState(false);
  const [payDebtModal, setPayDebtModal] = useState(false);
  const [companyPayDebtModal, setCompanyPayDebtModal] = useState(false);
  const [agentPayDebtModal, setAgentPayDebtModal] = useState(false);
  const [branchModal, setBranchModal] = useState(false);
  const [returnItem, setReturnItem] = useState(false);
  const [pieceModal, setPieceModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [title, setTitle] = useState(false);
  const [blockData, setBlockData] = useState({
    blocked: true,
  });
  const [_id, setId] = useState("");
  const [printData, setPrintData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;

  //API calls
  const branchUrl =
    path === "/Customers"
      ? `${query}?search=${searchQuery}&sort=${sortQuery}&page=${page}&limit=${limit}`
      : `/${query}?branch=${branch}&search=${searchQuery}&sort=${sortQuery}&page=${page}&limit=${limit}`;

  const {
    data: data,
    error: error,
    isLoading: loading,
    isFetching,
  } = useGetResourceQuery(
    `${
      branch
        ? branchUrl
        : `/${query}?search=${searchQuery}&sort=${sortQuery}&page=${page}&limit=${limit}`
    }`
  );
  const { data: banks } = useGetResourceQuery(`/banks?limit=${1000000}`);
  const { data: company } = useGetResourceQuery(`/companies?limit=${1000000}`);
  const { data: agents } = useGetResourceQuery(
    `/agentTransfers?limit=${1000000}`
  );
  const { data: branches } = useGetResourceQuery(`/branches?limit=${1000000}`);
  const { data: customers } = useGetResourceQuery(
    `/customers?limit=${1000000}`
  );
  const [deleteResource, { isLoading: isDeleting }] =
    useDeleteResourceMutation();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  }
  if (error) {
    return <ServerError />;
  }
  const rowsData = data?.data?.data ? data?.data?.data : data?.data;
  const rows = rowsData.map((dataItem, rowIndex) => {
    const includedFields = head.map((item) => {
      if (item.field === "createdAt") {
        const date = new Date(dataItem[item.field]);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")} ${date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`;
        return <div dir="ltr">{formattedDate}</div>;
      }

      if (item.field === "addedBy") {
        return dataItem.addedBy ? dataItem.addedBy.username : "سڕاوەتەوە";
      }
      if (item.field === "company") {
        return dataItem.company ? dataItem.company.name : "";
      }
      if (item.field === "bank") {
        return dataItem.bank
          ? dataItem.bank.name
          : dataItem.agentTransfer
          ? dataItem.agentTransfer.name
          : "سڕاوەتەوە";
      }
      if (item.field === "detailName") {
        return (
          <Link
            to={`/${_detailPath}/${dataItem._id}`}
            className="text-primary/80 hover:underline hover:text-primary duration-300 ease-in-out transition-all"
          >
            {dataItem.name}
          </Link>
        );
      }
      if (item.field === "info") {
        return (
          <Link
            to={`/${_detailPath}/${dataItem._id}`}
            className="text-primary/80 hover:underline hover:text-primary duration-300 ease-in-out transition-all"
          >
            بینین
          </Link>
        );
      }
      if (item.field === "company") {
        return dataItem.company ? dataItem.company.name : "سیستەم";
      }
      if (item.field === "branch") {
        return dataItem.branch
          ? dataItem.branch.name
          : dataItem.branch === null
          ? "سڕاوەتەوە"
          : "سیستەم";
      }
      if (item.field === "customer") {
        return (
          <Link
            to={`/Customer/${dataItem.customer._id}`}
            className="text-primary/80 hover:underline hover:text-primary duration-300 ease-in-out transition-all"
          >
            {dataItem.customer ? dataItem.customer.name : "سڕاوەتەوە"}
          </Link>
        );
      }
      if (item.field === "returnCustomer") {
        return dataItem.purchase
          ? dataItem.purchase?.customer?.name
          : "سڕاوەتەوە";
      }

      if (item.field === "items") {
        return dataItem.items
          ? dataItem.items.map((item, index) => (
              <div key={index} class="">
                {item?.item !== null ? item.item?.name : "سڕاوەتەوە"}
              </div>
            ))
          : "سڕاوەتەوە";
      }

      if (item.field === "itemQuantity") {
        return dataItem.items
          ? dataItem.items.map((item, index) => (
              <div key={index} class="">
                {item.quantity}
              </div>
            ))
          : "سڕاوەتەوە";
      }
      if (item.field === "itemPrice") {
        return dataItem.items
          ? dataItem.items.map((item, index) => (
              <div key={index} class="">
                {item.sell_price}
              </div>
            ))
          : "سڕاوەتەوە";
      }
      if (item.field === "cash") {
        return <p>{dataItem.cash === true ? "بەڵێ" : "نەخێر"}</p>;
      }
      if (item.field === "user") {
        return dataItem.user ? dataItem.user.name : "";
      }
      if (item.field === "debt_price") {
        return dataItem.agentTransferDebt
          ? dataItem.agentTransferDebt.debt_price
          : dataItem.debt_price;
      }
      if (item.field === "debt_amount") {
        return dataItem.agentTransferDebt
          ? dataItem.agentTransferDebt.debt_amount
          : dataItem.debt_amount;
      }
      if (item.field === "debt_price1") {
        return dataItem.selfDebt
          ? dataItem.selfDebt.debt_price
          : dataItem.debt_price;
      }
      if (item.field === "debt_amount1") {
        return dataItem.selfDebt
          ? dataItem.selfDebt.debt_amount
          : dataItem.debt_amount;
      }
      if (item.field === "agentTransfer") {
        return dataItem.agentTransfer ? dataItem.agentTransfer.name : "";
      }
      if (item.field === "price") {
        return (
          <p
            className={`${
              dataItem.Added === false ? "text-red-600" : "text-green-600"
            }`}
          >
            {dataItem.price || 0}
            {dataItem.Added === false ? (
              <IoMdArrowDropdown className="inline-block ml-1" />
            ) : (
              <IoMdArrowDropup className="inline-block ml-1" />
            )}
          </p>
        );
      }
      if (item.field === "debtInfo") {
        return (
          <Link
            to={`/DaxilaBranch/${dataItem._id}`}
            className="text-primary/80 hover:underline hover:text-primary duration-300 ease-in-out transition-all"
          >
            بینین
          </Link>
        );
      }
      if (item.field === "username") {
        return (
          <Link
            to={`/Profile/${dataItem._id}`}
            state={{ defUsername: dataItem.username, defName: dataItem.name }}
            className="text-primary/80 hover:text-primary ease-in-out transition-colors duration-200"
          >
            {dataItem.username}
          </Link>
        );
      }
      if (item.field === "sendItem") {
        const handleAdd = async (id) => {
          setBranchModal(true);
          setId(id);
        };

        return (
          <>
            <button
              onClick={() => handleAdd(data?.data?.data[rowIndex]._id)}
              className="text-primary/80 hover:text-primary ease-in-out transition-colors duration-200"
            >
              ناردن
            </button>
          </>
        );
      }
      if (item.field === "returnItem") {
        const handleAdd = async (id) => {
          setReturnItem(true);
          setId(id);
        };
        return (
          <>
            <button
              onClick={() => handleAdd(data?.data?.data[rowIndex]._id)}
              className="text-primary/80 hover:text-primary ease-in-out transition-colors duration-200"
            >
              گەڕاندنەوەی ماددە
            </button>
          </>
        );
      }
      if (item.field === "addPieces") {
        const handleAdd = async (id) => {
          setPieceModal(true);
          setId(id);
        };

        return (
          <>
            <button
              onClick={() => handleAdd(data?.data?.data[rowIndex]._id)}
              className="text-primary/80 hover:text-primary ease-in-out transition-colors duration-200"
            >
              زیادکردن
            </button>
          </>
        );
      }
      if (item.field === "return" && role !== "superAdmin") {
        const handlePayDebt = (id, data) => {
          setPayDebtModal(true);
          setPrintData(data);
          setId(id);
        };
        return (
          _returnButton && (
            <button
              className="text-black cursor-buttonointer"
              onClick={() =>
                handlePayDebt(
                  data?.data?.data[rowIndex]._id,
                  data?.data?.data[rowIndex]
                )
              }
            >
              گەڕاندنەوە
            </button>
          )
        );
      }

      if (item.field === "actions") {
        const handleDelete = async (id) => {
          setConfirmModal(true);
          setId(id);
          setTitle(" ئایا دڵنیای لە سڕینەوە؟");
          setConfirm("delete");
        };
        const handleBlock = async (id) => {
          if (dataItem.blocked === false) {
            setBlockData({ blocked: true });
          } else {
            setBlockData({ blocked: false });
          }
          setConfirmModal(true);
          setId(id);
          setTitle("ئایا دڵنیای لە بلۆک کردن ؟");
          setConfirm("block");
        };
        const handleEdit = async (id) => {
          setEditModal(true);
          setId(id);
        };

        const handlePayDebt = (id, data) => {
          if (path === "/CompanyDebts") {
            setPrintData(data);
            setCompanyPayDebtModal(true);
          } else if (path === "/AgentDebts") {
            setAgentPayDebtModal(true);
            setPrintData(data);
          } else {
            setPayDebtModal(true);
            setPrintData(data);
          }
          setId(id);
        };
        const handlePrint = (data) => {
          setPrintData(data);
          setTitle("ئایا دڵنیای لە دوبارە پرینت کردن؟");
          setPrintModal(true);
        };
        return (
          <div className="flex justify-center gap-3 items-center">
            {path === "/Purchases" ? (
              ""
            ) : (
              <p
                onClick={() => handleDelete(data?.data?.data[rowIndex]._id)}
                className="cursor-pointer text-red-600"
              >
                سڕینەوە
              </p>
            )}

            <p
              className="text-blue-600 cursor-pointer"
              onClick={() => handleEdit(data?.data?.data[rowIndex]._id)}
            >
              نوێکردنەوە
            </p>
            {_blockButton === true && (
              <p
                className="text-black cursor-pointer"
                onClick={() => handleBlock(data?.data?.data[rowIndex]._id)}
              >
                {dataItem.blocked === false ? "بلۆک" : "لابردنی بلۆک"}
              </p>
            )}
            {printButton && (
              <button onClick={() => handlePrint(data?.data?.data[rowIndex])}>
                پرینت کردن
              </button>
            )}
            {_returnButton && (
              <button
                className="text-black cursor-buttonointer"
                onClick={() =>
                  handlePayDebt(
                    data?.data?.data[rowIndex]._id,
                    data?.data?.data[rowIndex]
                  )
                }
              >
                گەڕاندنەوە
              </button>
            )}
          </div>
        );
      }
      return dataItem[item.field];
    });
    return includedFields;
  });

  //Handlers

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSortQueryChange = (value) => {
    setSortQuery(value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleAdd = async (id) => {
    if (path === "/Items" || path === "/Users" || path === "/Purchases") {
      navigate(addPath);
      setId(id);
    } else {
      setOpenAddModal(true);
      setId(id);
    }
  };
  const handleDaxilaBranchModal = async (id) => {
    setOpenAddModal(true);
    setId(id);
  };
  const handleConfirmDelete = async () => {
    try {
      const deletedData = await deleteResource(`/${query}/${_id}`);
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      toast.success("بە سەرکەوتوی سڕایەوە", {
        className: "py-4 text-xl",
        style: { direction: "rtl" },
      });
      setConfirmModal(false);
    }
  };

  const handleConfirmBlock = async () => {
    try {
      const res = await updateResource({
        url: `/${"users"}/${_id}`,
        formData: blockData,
      });
    } catch (error) {
      console.error("Error deleting item:", error);
    }

    setConfirmModal(false);
  };

  //Pagination Theme
  const customTheme = {
    base: "",
    layout: {
      table: {
        base: "text-sm text-gray-700 dark:text-gray-400",
        span: "font-semibold text-gray-900 dark:text-white",
      },
    },
    pages: {
      base: "xs:mt-0 mt-2 inline-flex items-center -space-x-px",
      showIcon: "inline-flex",
      previous: {
        base: "ml-0  border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        icon: "h-5 w-5",
      },
      next: {
        base: " border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        icon: "h-5 w-5",
      },
      selector: {
        base: "w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        active:
          "bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white",
        disabled: "cursor-not-allowed opacity-50",
      },
    },
  };
  const addItemField = [
    {
      id: 1,
      label: "دانە",
      name: "additionalPieces",
      type: "number",
      placeholder: "ڕێژەی دانە دیاری بکە",
      error: "",
      icon: <ItemsIcon fill={"#A0AEC0"} />,
    },
  ];
  return (
    <div>
      <PageTitle title={pageTitle} />
      <div className=" rounded-lg mx-4 xl:mx-16 lg:mt-6">
        {_addButton === true && (
          <PrimaryButton
            _text={`${actionButtonText ? actionButtonText : "زیادکردن"}`}
            onClick={
              query === "daxilaBranch" ? handleDaxilaBranchModal : handleAdd
            }
            _additionalClasses="mt-4 md:w-96 w-full"
          />
        )}

        <div className=" flex flex-col md:flex-row justify-between gap-10 items-center my-10 ">
          <div className="w-full">
            <TextField
              _id="search"
              _type="text"
              _placeholder={`گەڕان بۆ ${searchName}...`}
              _value={searchQuery}
              onChange={handleSearchQueryChange}
              _additionalClasses="w-full"
              _icon={FaSearch}
            />
          </div>
          <div className="w-full ml-2 ">
            {sortData && (
              <DropDown
                sortData={sortData}
                _value={sortQuery}
                onChange={handleSortQueryChange}
              />
            )}
          </div>
        </div>
        <div className=" pb-10">
          {_totalItem && (
            <h1 className="text-xl">
              ڕێژەی پارە لەناو دەخیلە : {data?.data?.totalDaxilaPrice}
            </h1>
          )}
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-center">
            <thead className="bg-primary sticky top-0">
              <tr>
                {head.map((item) => (
                  <th
                    scope="col"
                    className="px-2 py-3 text-xl text-white"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isFetching ? (
                <tr>
                  <td colSpan={head.length}>
                    <div
                      role="status"
                      className="flex justify-center items-center my-10"
                    >
                      <LoadingIcon />
                    </div>
                  </td>
                </tr>
              ) : rows.length > 0 ? (
                rows.map((_data, index) => (
                  <tr key={index} className="hover:bg-primary/20">
                    {Object.entries(_data).map(([key, value], colIndex) => (
                      <td
                        key={colIndex}
                        className="p-4 border-b border-blue-gray-50 text-xl "
                      >
                        {value}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={head.length}
                    className="text-primary font-bold py-5"
                  >
                    <span className="flex items-center justify-center text-xl">
                      <MdSearch size={24} className="mr-2" /> هیچ داتایەک
                      نیە....
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Footer outside of the scrollable container */}
        <div className="bg-gray-200" dir="rtl">
          <div className="flex justify-center py-4">
            <Flowbite theme={{ theme: customTheme }}>
              <Pagination
                layout="pagination"
                previousLabel={<MdArrowForward size={20} />}
                nextLabel={<MdArrowBack size={20} />}
                currentPage={page}
                totalPages={data?.totalPages}
                onPageChange={handlePageChange}
              />
            </Flowbite>
          </div>
        </div>
      </div>

      {/*Modals */}
      <div>
        {openAddModal && (
          <AddModal
            _textfield={_textfield}
            _title={`زیادکردنی ${pageTitle}`}
            onCancel={() => setOpenAddModal(false)}
            _query={query}
            actionButtonText={actionButtonText}
            _role={role}
            _branch={branch}
            errorNames={errorNames}
            banks={banks}
            company={company}
            agents={agents}
            customers={customers}
          />
        )}
        {confirmModal && (
          <ConfirmModal
            onCancel={() => setConfirmModal(false)}
            onConfirm={
              confirm === "delete" ? handleConfirmDelete : handleConfirmBlock
            }
            isLoading={isDeleting}
            _title={title}
            submitButton={true}
          />
        )}
        {editModal && (
          <EditModal
            onCancel={() => setEditModal(false)}
            _editFields={_editFields}
            initialData={data}
            id={_id}
            _query={query}
          />
        )}
        {printModal && (
          <ConfirmModal
            onCancel={() => setPrintModal(false)}
            _title={title}
            printButton={true}
            data={printData}
            customerId={printData?.customer?.id}
          />
        )}
        {payDebtModal && (
          <AddModal
            _textfield={addModalFields[0].payDebtFields}
            _title={`زیادکردنی ${pageTitle}`}
            onCancel={() => setPayDebtModal(false)}
            _query={`payDebts`}
            actionButtonText={actionButtonText}
            id={_id}
            _itemName={"debt"}
            _printButton={true}
            data={printData}
          />
        )}
        {agentPayDebtModal && (
          <AddModal
            _textfield={addModalFields[0].payDebtFields}
            _title={`زیادکردنی ${pageTitle}`}
            onCancel={() => setAgentPayDebtModal(false)}
            _query={`agentTransferPayDebts`}
            actionButtonText={actionButtonText}
            id={_id}
            _itemName={"agentTransferDebt"}
            _printButton={true}
            data={printData}
          />
        )}

        {companyPayDebtModal && (
          <AddModal
            _textfield={addModalFields[0].payDebtFields}
            _title={`زیادکردنی ${pageTitle}`}
            onCancel={() => setCompanyPayDebtModal(false)}
            _query={`selfPayDebts`}
            actionButtonText={actionButtonText}
            id={_id}
            _itemName={"selfDebt"}
            _printButton={true}
            data={printData}
          />
        )}
        {branchModal && (
          <AddModal
            _textfield={_textfield}
            _title={`ناردنی ${pageTitle}`}
            onCancel={() => setBranchModal(false)}
            _query={"items/sendItemToBranch"}
            id={_id}
            _itemName={"itemId"}
            branchs={branches}
            text={"ناردن"}
          />
        )}
        {returnItem && (
          <AddModal
            _textfield={_textfield}
            _title={`گەڕاندنەوەی ${pageTitle}`}
            onCancel={() => setReturnItem(false)}
            _query={"items/sendItemFromBranch"}
            id={_id}
            _itemName={"itemId"}
            branchs={branches}
            text={"گەڕاندنەوە"}
          />
        )}
        {pieceModal && (
          <AddModal
            _textfield={addItemField}
            _title={`زیادکردنی دانە`}
            onCancel={() => setPieceModal(false)}
            _query={"items/addItemPieces"}
            id={_id}
            _itemName={"itemId"}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
