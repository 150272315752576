import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectCurrentUserRoles } from "./authSlice";
import { useGetResourceQuery } from "../api/apiSlice";
import { LoadingIcon } from "../../constants/Icons";

const RequireAuth = ({ allowedRoles, element }) => {
  const userDataFromLocalStorage = localStorage.getItem("user");
  const token = useSelector(selectCurrentToken);
  const userRoles = useSelector(selectCurrentUserRoles);
  const location = useLocation();
  const { isError, data, isLoading, error } = useGetResourceQuery("/users");
  // Handle loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  }

  // Handle different error states
  if (isError) {
    const status = error?.status;

    // // Redirect to login page on unauthorized errors
    if (status === 401 || status === 403) {
      return <Navigate to="/Login" state={{ from: location }} replace />;
    }

    // Redirect to server error page for other server-related errors
    if (status >= 500 && status < 600) {
      return <Navigate to="/ServerError" state={{ from: location }} replace />;
    }
  }

  // If there is no token, redirect to the login page
  if (!userDataFromLocalStorage) {
    return <Navigate to="/Login" state={{ from: location }} replace />;
  }

  // If user is authenticated and token is not expired, check for roles
  if (token && data?.data?.data.length > 0) {
    const userHasAllowedRole = userRoles?.some((role) =>
      allowedRoles?.includes(role)
    );
    if (userHasAllowedRole && data) {
      return element;
    } else {
      return <Navigate to="/Unauthorized" state={{ from: location }} replace />;
    }
  }

  // If it's the first user, redirect to sign-up page
  // if (data && data.data.length === 0) {
  //   return <Navigate to="/signup" state={{ from: location }} replace />;
  // }

  // Otherwise, redirect to login page
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
