import i18n from "i18next";
import kuTranslation from "./ku/translation.json";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    ku: { translation: kuTranslation },
  },
  lng: "ku",
  fallbackLng: "ku",

  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
