import React from "react";
import { useGetResourceQuery } from "../../features/api/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  DebtIcon,
  ItemsIcon,
  LoadingIcon,
  NotficationIcon,
  PurchasesIcon,
} from "../../constants/Icons";
import PageTitle from "../../components/PageTitle";
import { BsFillPersonFill } from "react-icons/bs";
import { GrUserAdmin } from "react-icons/gr";

const BranchDashboard = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    data: data,
    error: error,
    isLoading: loading,
  } = useGetResourceQuery(`/dashboard/${id}`);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  }
  if (error) return <p>{error}</p>;

  const {
    currentUser,
    debtResults,
    itemResults,
    notificationNumber,
    purchaseResults,
  } = data;

  const { name, role } = currentUser;

  const dashboardResults = [
    {
      id: 1,
      title: "کۆی گشتی ماددەکان",
      icon: <ItemsIcon fill={"#fff"} />,
      data: itemResults,
      link: "/BranchItems",
    },
    {
      id: 2,
      title: "کۆی گشتی فرۆشەکان",
      icon: <PurchasesIcon fill={"#fff"} />,
      data: purchaseResults,
      link: "/Purchases",
    },
    {
      id: 3,
      title: "کۆی گشتی قەرزەکان",
      icon: <DebtIcon fill={"#fff"} />,
      data: debtResults,
      link: "/Debts",
    },
    {
      id: 4,
      title: "کۆی گشتی ئاگاداریەکان",
      icon: <NotficationIcon fill={"#fff"} />,
      data: notificationNumber,
      link: "/Notfications",
    },
  ];

  const userInfo = [
    { id: 1, icon: <BsFillPersonFill />, data: name },
    { id: 2, icon: <GrUserAdmin />, data: role },
  ];
  return (
    <div>
      <PageTitle title={"داشبۆرد"} />

      <div className="mx-2 xl:mx-16 mt-6">
        <div className="flex p-5">
          <h1 className="text-xl"> بەکارهێنەری ئێستا : </h1>

          {userInfo.map((item) => (
            <div key={item.id} className="flex items-center text-2xl">
              <div className="ml-2  text-primary ">{item.icon}</div>
              <h1 className="pl-5">{item.data}</h1>
            </div>
          ))}
        </div>
        <h1 className="text-xl">ئەنجام و داتاکانی لق</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 py-4">
          {dashboardResults.map((item, idx) => {
            const handleNavigate = () => {
              navigate(item.link);
            };
            return (
              <div
                key={idx}
                onClick={handleNavigate}
                className="bg-primary/90 p-5 cursor-pointer flex flex-col justify-between rounded-lg  hover:bg-primary transition-colors duration-300"
              >
                <div className="flex items-center justify-between">
                  <h1 className="text-white text-lg font-semibold">
                    {item.title}
                  </h1>
                  <div className=" bg-white/40 rounded-xl   ">
                    <p className="p-4">{item.icon}</p>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-white text-xl">{item.data}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BranchDashboard;
