import React, { useEffect, useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

const DropDown = ({ sortData, _value, onChange }) => {
  const [isOpen, setIsOpen] = useState(null); // Track which dropdown is open
  const [selectedValues, setSelectedValues] = useState({}); // Track selected values for each dropdown

  useEffect(() => {
    const newSelectedValues = {};
    sortData.forEach((sort, index) => {
      const selectedOption = sort.options.find(
        (option) => option.value === _value
      );
      newSelectedValues[index] = selectedOption ? selectedOption.label : "";
    });
    setSelectedValues(newSelectedValues);
  }, [_value, sortData]);

  const toggleDropdown = (index) => {
    setIsOpen(isOpen === index ? null : index);
  };

  const handleOptionClick = (index, label, value) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [index]: label,
    }));
    onChange(value); // Notify the parent component of the change
    setIsOpen(null); // Close the dropdown
  };

  return (
    <div className="mt-6 w-full">
      <div className="grid grid-cols-1 md:flex items-center justify-end gap-5">
        {sortData.map((sort, index) => (
          <div key={index} className="relative w-full lg:w-[30%]">
            <button
              className="w-full text-black border border-primary hover:bg-primary hover:text-white duration-200 ease-in-out font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
              type="button"
              onClick={() => toggleDropdown(index)}
            >
              {selectedValues[index] ? selectedValues[index] : sort.title}
              <span className="flex items-center">
                {isOpen === index ? (
                  <MdExpandLess className="text-xl" />
                ) : (
                  <MdExpandMore className="text-xl" />
                )}
              </span>
            </button>
            {isOpen === index && (
              <div className="absolute right-0 z-10 mt-2 bg-white divide-y divide-primary rounded-lg shadow border border-primary/30 w-full">
                <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                  {sort.description}
                </div>
                <ul
                  className="py-2 text-sm text-gray-700"
                  aria-labelledby="dropdownInformationButton"
                >
                  {sort.options.map((option) => (
                    <li
                      key={option.id}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                      onClick={() =>
                        handleOptionClick(index, option.label, option.value)
                      }
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropDown;
