import React from "react";
import Table from "../../components/Table";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../../constants/Data";

const Agents = () => {
  return (
    <div>
      <Table
        _addButton={true}
        pageTitle={"نوسینگەکان"}
        searchName={"نوسینگەکان"}
        head={TableHeaders[0].agentsTableHeader}
        sortData={TableDropDowns[0].customerDropDowns}
        _textfield={addModalFields[0].agentFields}
        query={"agentTransfers"}
        _editFields={editFields[0].customerFields}
      />
    </div>
  );
};

export default Agents;
