import React from "react";
import UnauthorizedImg from "../assets/images/401 Error Unauthorized-rafiki.png";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/PrimaryButton";
const Unauthorized = () => {
  const navigate = useNavigate();
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";
  let branch = "";
  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
      branch = userData.branch; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }
  const handleBack = () => {
    if (role === "branchSuperAdmin") {
      navigate(`/BranchDashboard/${branch}`);
    } else if (role === "superAdmin") {
      navigate(`/`);
    } else {
      navigate("/Customers");
    }
  };
  return (
    <div className="m-9 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <img
          src={UnauthorizedImg}
          alt="500 Internal Server Error"
          className="w-full max-w-xs md:max-w-md lg:max-w-lg xl:max-w-2xl"
        />

        <p className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-600 mt-2">
          ڕێگەپێدراونیت
        </p>
        <p className="md:text-lg xl:text-xl text-gray-500 mt-4">
          ڕێگەپێدراونیت ئەم بەشە ببینت...
        </p>

        {role === "branchSuperAdmin" ||
        role === "admin" ||
        role === "branchAdmin" ? (
          <PrimaryButton
            _text={`گەڕانەوە`}
            onClick={handleBack}
            _additionalClasses="mt-4 md:w-96 w-full"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Unauthorized;
