import React from "react";
import { useGetResourceQuery } from "../features/api/apiSlice";
import {
  AgentsIcon,
  BanksIcon,
  BranchesIcon,
  CompanysIcon,
  CustomersIcon,
  DebtIcon,
  ItemsIcon,
  LoadingIcon,
  NotficationIcon,
  OwnersIcon,
  PurchasesIcon,
  TransfersIcon,
} from "../constants/Icons";
import PageTitle from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { GrUserAdmin } from "react-icons/gr";
import { BsFillPersonFill } from "react-icons/bs";

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    data: data,
    error: error,
    isLoading: loading,
  } = useGetResourceQuery(`/dashboard`);

  if (loading)
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  if (error) return <p>Error loading customer data</p>;

  const {
    agentTransferResults,
    bankResults,
    branchResults,
    companyResults,
    currentUser,
    customerResults,
    debtResults,
    itemResults,
    notificationNumber,
    ownerResults,
    purchaseResults,
    transferResults,
  } = data;

  const { name, role } = currentUser;
  const dashboardResults = [
    {
      id: 1,
      title: "کۆی گشتی نوسینگەکان",
      icon: <AgentsIcon fill={"#fff"} />,
      data: agentTransferResults,
      link: "/Agents",
    },
    {
      id: 2,
      title: "کۆی گشتی بانقەکان ",
      icon: <BanksIcon fill={"#fff"} />,
      data: bankResults,
      link: "/Banks",
    },
    {
      id: 3,
      title: "کۆی گشتی لقەکان",
      icon: <BranchesIcon fill={"#fff"} />,
      data: branchResults,
      link: "/Branches",
    },
    {
      id: 4,
      title: "کۆی گشتی کۆمپانیاکان",
      icon: <CompanysIcon fill={"#fff"} />,
      data: companyResults,
      link: "/Companies",
    },
    {
      id: 5,
      title: "کۆی گشتی کڕیارەکان",
      icon: <CustomersIcon fill={"#fff"} />,
      data: customerResults,
      link: "/Customers",
    },
    {
      id: 6,
      title: "کۆی گشتی قەرزەکان",
      icon: <DebtIcon fill={"#fff"} />,
      data: debtResults,
      link: "/Debts",
    },
    {
      id: 7,
      title: "کۆی گشتی ماددەکان",
      icon: <ItemsIcon fill={"#fff"} />,
      data: itemResults,
      link: "/Items",
    },
    {
      id: 8,
      title: "کۆی گشتی فرۆشەکان",
      icon: <PurchasesIcon fill={"#fff"} />,
      data: purchaseResults,
      link: "/Purchases",
    },
    {
      id: 9,
      title: "کۆی گشتی بەڕێوبەرەکان",
      icon: <OwnersIcon fill={"#fff"} />,
      data: ownerResults,
      link: "/Owners",
    },
    {
      id: 10,
      title: "کۆی گشتی حەواڵەکان",
      icon: <TransfersIcon fill={"#fff"} />,
      data: transferResults,
      link: "/Transfers",
    },
    {
      id: 11,
      title: "کۆی گشتی ئاگاداریەکان",
      icon: <NotficationIcon fill={"#fff"} />,
      data: notificationNumber,
      link: "/Notfications",
    },
  ];

  const userInfo = [
    { id: 1, icon: <BsFillPersonFill />, data: name },
    { id: 2, icon: <GrUserAdmin />, data: role },
  ];
  return (
    <div>
      <PageTitle title={"داشبۆرد"} />

      <div className="mx-2 xl:mx-16 mt-6">
        <div className="flex p-5 ">
          <h1 className="lg:text-xl"> بەکارهێنەری ئێستا : </h1>

          {userInfo.map((item) => (
            <div
              key={item.id}
              className="flex items-center text-lg  lg:text-2xl"
            >
              <div className="ml-2  text-primary ">{item.icon}</div>
              <h1 className="pl-2 lg:pl-5">{item.data}</h1>
            </div>
          ))}
        </div>
        <h1 className="text-xl">ئەنجام و داتاکانی سیستەم</h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 py-4">
          {dashboardResults.map((item, idx) => {
            const handleNavigate = () => {
              navigate(item.link);
            };
            return (
              <div
                key={idx}
                onClick={handleNavigate}
                className="bg-primary/90 p-5 cursor-pointer flex flex-col justify-between rounded-lg  hover:bg-primary transition-colors duration-300"
              >
                <div className="flex items-center justify-between">
                  <h1 className="text-white text-lg font-semibold">
                    {item.title}
                  </h1>
                  <div className=" bg-white/40 rounded-xl   ">
                    <p className="p-4">{item.icon}</p>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-white text-xl">{item.data}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
