import React, { useState } from "react";
import { TableDropDowns, TableHeaders } from "../../constants/Data";
import DetailTable from "../../components/DetailTable";
import { useGetResourceQuery } from "../../features/api/apiSlice";
import { LoadingIcon } from "../../constants/Icons";

const BranchItems = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";
  let branch = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
      branch = userData.branch; // Correctly access the branch
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const {
    data,
    error,
    isLoading: loading,
  } = useGetResourceQuery(`/branches/${branch}`);

  if (loading)
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  if (error) return <p>Error loading customer data</p>;

  const dataInfo = data && data.data?.data;
  const { items } = dataInfo;

  const filteredItems = dataInfo.items?.filter((item) => item.item !== null);

  return (
    <div className="w-full">
      <div className="mx-2 lg:mx-12 2xl:mx-16 pb-20">
        <DetailTable
          searchName={"ماددەکان"}
          tableTitle={"ماددەکان"}
          data={filteredItems}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          sortQuery={sortQuery}
          setSortQuery={setSortQuery}
          page={page}
          setPage={setPage}
          head={TableHeaders[0].branchItemHeader}
          quantity={items?.quantity}
          itemsPerPage={itemsPerPage}
          sortData={TableDropDowns[0].itemDropDowns}
        />
      </div>
    </div>
  );
};

export default BranchItems;
