import React from "react";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../../constants/Data";
import Table from "../../components/Table";

const Branchs = () => {
  const errorNames = [{ name: "name" }, { name: "address" }];
  return (
    <div className="w-full">
      <Table
        pageTitle={"لقەکان"}
        searchName={"لقەکان"}
        head={TableHeaders[0].branchHeaders}
        sortData={TableDropDowns[0].customerDropDowns}
        _textfield={addModalFields[0].branchFields}
        query={"branches"}
        _editFields={editFields[0].branchFields}
        _addButton={true}
        _detailPath={"Branch"}
        errorNames={errorNames}

      />
    </div>
  );
};

export default Branchs;
