import React from "react";
import Table from "../components/Table";
import {
  TableDropDowns,
  TableHeaders,
  addModalFields,
  editFields,
} from "../constants/Data";

const Customers = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }

  let Header = TableHeaders[0].customersTableHeader;
  if (
    role === "branchSuperAdmin" ||
    role === "admin" ||
    role === "branchAdmin"
  ) {
    Header = Header.filter((headerItem) => headerItem.field !== "actions");
  }

  const errorNames = [{ name: "name" }];

  return (
    <div className="w-full ">
      <Table
        pageTitle={"کڕیارەکان"}
        searchName={"کڕیارەکان"}
        head={Header}
        query={"customers"}
        sortData={TableDropDowns[0].customerDropDowns}
        _textfield={addModalFields[0].customerFields}
        _query={"customers"}
        _editFields={editFields[0].customerFields}
        _addButton={true}
        _detailPath={"Customer"}
        errorNames={errorNames}
      />
    </div>
  );
};

export default Customers;
