import React from "react";
import Add from "../components/Add";
import { addFields } from "../constants/Data";
import imageUrl from "../assets/images/Product quality-rafiki.png";
import { useGetResourceQuery } from "../features/api/apiSlice";

const AddItems = () => {
  const limit = 1000000;
  const {
    data: companiesData,
    error: companiesErr,
    isLoading: compoaniesLoading,
  } = useGetResourceQuery(`/companies?limit=${limit}`);
  if (compoaniesLoading) {
    return <div>Loading...</div>;
  }

  if (companiesErr) {
    return <div>Error: {companiesErr?.message}</div>;
  }

  const errorNames = [
    { name: "name" },
    { name: "code" },
    { name: "company" },
    { name: "pieces" },
    { name: "purchase_price" },
    { name: "sell_price" },
  ];

  return (
    <div className="w-full">
      <Add
        pageTitle={"زیادکردنی ماددەکان"}
        _query={"Items"}
        _textFields={addFields[0].itemFields}
        imageUrl={imageUrl}
        companyOptions={companiesData}
        errorNames={errorNames}
      />
    </div>
  );
};

export default AddItems;
