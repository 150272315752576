import React from "react";
import Table from "../components/Table";
import { editFields, TableDropDowns, TableHeaders } from "../constants/Data";

const Purchases = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }

  let Header = TableHeaders[0].purchaseHeader;
  if (role === "admin" || role === "branchAdmin") {
    Header = Header.filter((headerItem) => headerItem.field !== "actions");
  }
  return (
    <div className="w-full ">
      <Table
        pageTitle={"فرۆشەکان"}
        searchName={"فرۆشەکان"}
        head={Header}
        query={"purchases"}
        _addButton={true}
        addPath={"/AddPurchase"}
        _editFields={editFields[0].purchaseFields}
        printButton={true}
        sortData={TableDropDowns[0].purchaseDropDown}
    
      />
    </div>
  );
};

export default Purchases;
