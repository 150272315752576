import React from "react";
import Table from "../components/Table";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../constants/Data";

const Transfers = () => {
  const errorNames = [{ name: "company" }, { name: "money" }];

  return (
    <div>
      <Table
        pageTitle={"حەواڵەکان"}
        searchName={"حەواڵەکان"}
        head={TableHeaders[0].transfersTableHeader}
        query={"transfers"}
        sortData={TableDropDowns[0].transferDropDowns}
        _textfield={addModalFields[0].transfersFields}
        _editFields={editFields[0].transfersFields}
        _addButton={true}
        errorNames={errorNames}
      />
    </div>
  );
};

export default Transfers;
