import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCreateResourceMutation,
  useGetResourceQuery,
} from "../features/api/apiSlice";
import toast from "react-hot-toast";
import Select from "react-select";
import TextField from "../components/TextField";
import PrimaryButton from "../components/PrimaryButton";
import imageUrl from "../assets/images/Add User-cuate.png";
import PageTitle from "../components/PageTitle";

const Signup = () => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [role, setRole] = useState("");
  const [branch, setBranch] = useState(""); // State for selected branch
  const [createResource, { isSuccess, isError, isLoading }] =
    useCreateResourceMutation();
  const nameRef = useRef();
  const userRef = useRef();
  const passRef = useRef();
  const passConfirmRef = useRef();
  const errRef = useRef();

  const handleNameInput = (e) => setName(e.target.value);
  const handleUserInput = (e) => setUsername(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handlePwdConfirmInput = (e) => setPasswordConfirm(e.target.value);

  const roleOptions = [
    { value: "superAdmin", label: "Super Admin" },
    { value: "admin", label: "Admin" },
    { value: "branchSuperAdmin", label: "Branch Super Admin" },
    { value: "branchAdmin", label: "Branch Admin" },
  ];

  const handleRoleChange = (selectedOption) => {
    setRole(selectedOption ? selectedOption.value : "");
  };

  const { data: data } = useGetResourceQuery(`/branches`);

  const branchOptions =
    data &&
    data.data.data.map((item) => ({
      value: item._id,
      label: item.name,
    }));

  const handleBranchChange = (selectedOption) => {
    setBranch(selectedOption ? selectedOption.value : "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name,
      username,
      password,
      passwordConfirm,
      role,
      ...(branch && { branch }),
    };
    try {
      const response = await createResource({
        url: `/users`,
        formData: formData,
      }).unwrap();

      toast.success("بە سەرکەوتوی زیادکرا", {
        className: "py-4 text-xl",
        style: { direction: "rtl" },
      });
      // Reset form fields
      setName("");
      setUsername("");
      setPassword("");
      setPasswordConfirm("");
      setRole("");
      setBranch("");
      setErrors({});
    } catch (err) {
      if (err.data?.error) {
        const validationErrors = {
          name: t(err.data.error.errors?.name?.message),
          username: t(err.data.error.errors?.username?.message),
          role: t(err.data.error.errors?.role?.message),
          password: t(err.data.error.errors?.password?.message),
          passwordConfirm: t(err.data.error.errors?.passwordConfirm?.message),
        };
        setErrors(validationErrors);
      }
      console.log(err);
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "px",
      border: "1px solid #097969",
      borderRadius: "0.375rem",
      padding: "0.200rem",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#a0aec0",
    }),
  };

  return (
    <div>
      {" "}
      <PageTitle title={"زیادکردنی بەکارهێنەر"} />
      <div className="container mx-auto flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div className="lg:w-1/2 flex flex-col items-center mx-10">
          <form className="w-full">
            <TextField
              _labelText={"ناو"}
              _placeholder={"ناوی بەکارهێنەر"}
              _id={"name"}
              _type={"text"}
              _additionalClasses={"w-full"}
              value={name}
              onChange={handleNameInput}
              _ref={nameRef}
            />
            {errors.name && (
              <span className="text-sm pt-2 text-red-500">{errors.name}</span>
            )}
            <TextField
              _labelText={"وشەی بەکارهێنەر"}
              _placeholder={"وشەی بەکارهێنەر"}
              _id={"username"}
              _type={"text"}
              _additionalClasses={"w-full"}
              value={username}
              onChange={handleUserInput}
              _ref={userRef}
            />
            {errors.username && (
              <span className="text-sm pt-2 text-red-500">
                {errors.username}
              </span>
            )}
            <TextField
              _labelText={"وشەی نهێنی"}
              _placeholder={"وشەی نهێنی"}
              _id={"password"}
              _type={"password"}
              _additionalClasses={"w-full"}
              value={password}
              onChange={handlePwdInput}
              _ref={passRef}
            />
            {errors.password && (
              <span className="text-sm pt-2 text-red-500">
                {errors.password}
              </span>
            )}
            <TextField
              _labelText={"دڵنیاکردنەوەی وشەی نهێنی"}
              _placeholder={"دڵنیاکردنەوەی وشەی نهێنی"}
              _id={"confirmPassword"}
              _type={"password"}
              _additionalClasses={"w-full"}
              value={passwordConfirm}
              onChange={handlePwdConfirmInput}
              _ref={passConfirmRef}
            />
            {errors.passwordConfirm && (
              <span className="text-sm pt-2 text-red-500">
                {errors.passwordConfirm}
              </span>
            )}
            <div className="my-4">
              <label
                htmlFor="role"
                className="flex justify-start items-start mb-2 text-md font-medium text-black"
              >
                ڕۆڵی بەکارهێنەر
              </label>
              <Select
                id="role"
                name="role"
                value={roleOptions.find((option) => option.value === role)}
                onChange={handleRoleChange}
                options={roleOptions}
                styles={customStyles}
                placeholder="ڕۆڵیک دیاری بکە"
                isClearable={true}
              />
              {errors.role && (
                <span className="text-sm pt-2 text-red-500">{errors.role}</span>
              )}
            </div>
            {role === "branchSuperAdmin" || role === "branchAdmin" ? (
              <div className="my-4">
                <label
                  htmlFor="branch"
                  className="flex justify-start items-start mb-2 text-md font-medium text-black"
                >
                  لق
                </label>
                <Select
                  options={branchOptions}
                  styles={customStyles}
                  isSearchable={true}
                  onChange={handleBranchChange}
                  placeholder="لقێک دیاری بکە"
                  isClearable={true}
                />
              </div>
            ) : null}
          </form>
          <PrimaryButton
            _text={"زیادکردن"}
            onClick={handleSubmit}
            _isLoading={isLoading}
            _additionalClasses="mt-4 w-full"
          />
        </div>
        <div className="lg:w-1/2 flex justify-center items-center">
          <img
            src={imageUrl}
            alt="Right Side"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
