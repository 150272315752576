import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../features/auth/authApiSlice";
import { setCredentials } from "../features/auth/authSlice";
import TextField from "../components/TextField";
import PrimaryButton from "../components/PrimaryButton";
import { FaUser } from "react-icons/fa";

import loginImage from "../assets/images/Tablet login-bro.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login = () => {
  // states
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [login, { isLoading, isError, error }] = useLoginMutation();

  const userRef = useRef();
  const passRef = useRef();
  const errRef = useRef();

  useEffect(() => {
    userRef.current.focus();
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  // Handlers
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userData = await login({ username, password }).unwrap();
      dispatch(setCredentials({ ...userData, username }));
      // Navigate based on user role
      if (userData?.user?.role === "superAdmin") {
        navigate("/");
      } else if (userData?.user?.role === "branchSuperAdmin") {
        navigate(`/BranchDashboard/${userData?.user?.branch}`);
      } else {
        navigate("/Customers");
      }
      setUsername("");
      setPassword("");
    } catch (err) {
      console.error("Login failed:", err);
      setErrMsg(t(err?.data?.message));
    }
  };

  const handleUserInput = (e) => setUsername(e.target.value);

  const handlePwdInput = (e) => setPassword(e.target.value);

  return (
    <div className="size-full flex justify-center items-center">
      <div className="lg:w-[60%] w-full lg:m-0 flex flex-col lg:flex-row rounded-lg ">
        <div className="lg:w-1/2 w-full flex flex-col p-5 justify-center ">
          <div className="mr-1 mt-1">
            <h1 className="text-black font-bold text-2xl">داخڵ بوون</h1>
          </div>
          <p
            ref={errRef}
            className={
              errMsg && password && username != null
                ? "text-white text-lg font-semibold bg-red-500 flex justify-center items-center m-5 py-3 rounded-lg"
                : "hidden"
            }
            aria-live="assertive"
          >
            {errMsg}
          </p>

          <form
            className={`flex flex-col ${errMsg ? "" : "py-5"} `}
            onSubmit={handleSubmit}
          >
            <TextField
              _labelText={"وشەی بەکارهێنەر"}
              _placeholder={"وشەی بەکار‌هێنەر بنووسە..."}
              _id={"username"}
              _type={"text"}
              _additionalClasses={"lg:w-full w-full"}
              value={username}
              onChange={handleUserInput}
              _ref={userRef}
              _error={
                username == "" && errMsg
                  ? "تکایە ناوی بەکار‌هێنەر داخڵ بکە"
                  : null
              }
              _icon={FaUser}
            />

            <TextField
              _labelText={"وشەی تێپەر"}
              _placeholder={"وشەی تێپەر بنووسە..."}
              _id={"password"}
              _type={"password"}
              _additionalClasses={"lg:w-full w-full"}
              value={password}
              onChange={handlePwdInput}
              _ref={passRef}
              _error={
                password == "" && errMsg ? "تکایە وشەی تێپەڕ داخڵ بکە" : null
              }
            />

            <PrimaryButton
              _text={"داخڵ بوون"}
              _additionalClasses="mt-10 w-full"
              disabled={isLoading}
              onClick={handleSubmit}
              _isLoading={isLoading}
            />
          </form>
          {isError && <p className="text-red-500 text-sm">{error.message}</p>}
        </div>
        <div className="hidden lg:flex lg:w-1/2  justify-center items-center bg-secondary">
          <img src={loginImage} alt="Login" className="w-full h-auto" />
        </div>
      </div>
    </div>
  );
};

export default Login;
