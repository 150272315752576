import React from "react";
import ServerErrorImage from "../assets/images/500 Internal Server Error-pana.png";

const ServerError = () => {
  return (
    <div className="m-9 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <img
          src={ServerErrorImage}
          alt="500 Internal Server Error"
          className="w-full max-w-xs md:max-w-md lg:max-w-lg xl:max-w-2xl"
        />
        <p className="text-5xl md:text-6xl lg:text-7xl font-bold tracking-wider text-gray-600 mt-8">
          500
        </p>
        <p className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-600 mt-2">
          کێشەی سێرڤەر
        </p>
        <p className="md:text-lg xl:text-xl text-gray-500 mt-4">
         کێشەیەک لە سێرڤەر هەیە تکایە چاوەڕوان بە...
        </p>
      </div>
    </div>
  );
};

export default ServerError;
