import React, { useEffect, useState } from "react";
import { useCreateResourceMutation } from "../features/api/apiSlice";
import PageTitle from "../components/PageTitle";
import DetailTable from "../components/DetailTable";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../constants/Data";
import { HoverEffect } from "../components/HoverEffect";
import { ItemNameIcon, LoadingIcon } from "../constants/Icons";
import DateButtons from "../components/DateButtons";

const Report = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let branchId = "";
  let role = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
      branchId = userData.branch; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }
  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const startDate = () => {
    const now = new Date();
    now.setMonth(now.getMonth() - 1);
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [data, setData] = useState();
  const [formData, setFormData] = useState({
    startDate: startDate(-1),
    endDate: getCurrentDate(),
  });

  const [searchQueries, setSearchQueries] = useState({});
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const handleDateChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const [createResource, { isError, isLoading, error }] =
    useCreateResourceMutation();
  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const requestData = branchId
      ? { ...formData, branch: branchId }
      : { ...formData };

    try {
      const response = await createResource({
        url: `/reports`,
        formData: requestData,
      }).unwrap();
      setData(response);
    } catch (err) {
      console.log("err :", err?.data?.message);
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);
  if (isLoading)
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  if (isError) return <p className="p-20">{error.data?.message}</p>;

  const handleSearchQueryChange = (tableIndex, value) => {
    setSearchQueries((prev) => ({
      ...prev,
      [tableIndex]: value,
    }));
  };
  const {
    agentTransfers,
    banks,
    branches,
    companies,
    customers,
    daxila,
    debts,
    expenses,
    items,
    ownerProfits,
    payDebts,
    purchases,
    totalCashSales,
    totalDaxilaPrice,
    totalDebtAmount,
    totalDebtPrice,
    totalDebtSales,
    totalExpensePrice,
    totalIncome,
    totalSales,
  } = data ? data.data : "";

  const tableConfigs = [
    {
      searchName: "ماددەکان",
      tableTitle: "ماددەکان",
      data: items,
      head: TableHeaders[0].reportItemHeaders,
      sort: TableDropDowns[0].itemDropDowns,
      editFields: editFields[0].itemsFields,
      query: "items",
    },
    {
      searchName: "کریارەکان",
      tableTitle: "کریارەکان",
      data: customers,
      head: TableHeaders[0].customersTableHeader,
      sort: TableDropDowns[0].customerDropDowns,
      editFields: editFields[0].customerFields,
      query: "customers",
      _detailPath: "Customer",
    },
    {
      searchName: "فرۆشەکان",
      tableTitle: "فرۆشەکان",
      data: purchases,
      head: TableHeaders[0].purchaseDetailHeader,
      editFields: editFields[0].purchaseFields,
    },
    {
      searchName: "قەرزەکان",
      tableTitle: "قەرزەکان",
      data: debts,
      head: TableHeaders[0].reportDebtHeader,
      editFields: editFields[0].debtFields,
      query: "debts",
      _detailPath: "Debt",
      _returnButton: true,
      _textfield: addModalFields[0].payDebtFields,
    },
    {
      searchName: "کۆمپانیاکان",
      tableTitle: "کۆمپانیاکان",
      data: companies,
      head: TableHeaders[0].customersTableHeader,
      editFields: editFields[0].customerFields,
      query: "companies",
      _detailPath: "Company",
    },
    {
      searchName: "لقەکان",
      tableTitle: "لقەکان",
      data: branches,
      head: TableHeaders[0].branchHeaders,
      editFields: editFields[0].branchFields,
      query: "branches",
      _detailPath: "Branch",
    },
    {
      searchName: "نوسینگەکان",
      tableTitle: "نوسینگەکان",
      data: agentTransfers,
      head: TableHeaders[0].agentsTableHeader,
      editFields: editFields[0].customerFields,
      query: "agentTransfers",
    },
    {
      searchName: "بانقەکان",
      tableTitle: "بانقەکان",
      data: banks,
      head: TableHeaders[0].bankHeaders,
      editFields: editFields[0].customerFields,
      query: "banks",
    },
    {
      searchName: "دەخیلە",
      tableTitle: "دەخیلە",
      data: daxila,
      head: TableHeaders[0].daxilaHeader,
      editFields: editFields[0].daxilaFields,
      query: "daxila",
    },
    {
      searchName: "خەرجیەکان",
      tableTitle: "خەرجیەکان",
      data: expenses,
      head: TableHeaders[0].expensesHeader,
      editFields: editFields[0].expensesFields,
      query: "expenses",
    },
    {
      searchName: "قازانجی بەڕێوبەرەکان",
      tableTitle: "قازانجی بەڕێوبەرەکان",
      data: ownerProfits,
      head: TableHeaders[0].ownerProfitHeader,
    },
    {
      searchName: "قەرزە گەڕێندراوەکان",
      tableTitle: "قەرزە گەڕێندراوەکان",
      data: payDebts,
      head: TableHeaders[0].payesDetailHeader,
      editFields: editFields[0].payDebtFields,
      query: "payDebts",
    },
  ];
  const totalDatas = [
    {
      id: 1,
      title: "کۆی گشتی فرۆشەکان",
      icon: <ItemNameIcon />,
      description: totalSales,
    },
    {
      id: 2,
      title: "کۆی گشتی پارەی فرۆش بە کاش",
      icon: <ItemNameIcon />,
      description: totalCashSales,
    },
    {
      id: 3,
      title: "کۆی گشتی پارەی فرۆش بە قەرز",
      icon: <ItemNameIcon />,
      description: totalDebtSales,
    },
    {
      id: 4,
      title: "کۆی گشتی قەرز",
      icon: <ItemNameIcon />,
      description: totalDebtPrice,
    },
    {
      id: 5,
      title: "کۆی گشتی قەرزی ماووە",
      icon: <ItemNameIcon />,
      description: totalDebtAmount,
    },
    {
      id: 6,
      title: "کۆی گشتی پارەی دەخیلە",
      icon: <ItemNameIcon />,
      description: totalDaxilaPrice,
    },
    {
      id: 7,
      title: "کۆی گشتی خەرجیەکان",
      icon: <ItemNameIcon />,
      description: totalExpensePrice,
    },
    {
      id: 8,
      title: "کۆی گشتی قازانج",
      icon: <ItemNameIcon />,
      description: totalIncome,
    },
  ];
  return (
    <div className="w-full">
      <PageTitle title={"ڕاپۆرت"} />
      <DateButtons
        onSubmit={handleSubmit}
        onChange={handleDateChange}
        formData={formData}
      />

      <div className="mx-2 lg:mx-12 2xl:mx-16 pt-20 ">
        <HoverEffect items={totalDatas} />
      </div>
      <div className=" mx-2 lg:mx-12 2xl:mx-16 pb-20 ">
        {data &&
          tableConfigs.map((config, index) => (
            <DetailTable
              key={index}
              searchName={config.searchName}
              tableTitle={config.tableTitle}
              data={config.data}
              searchQuery={searchQueries[index] || ""}
              setSearchQuery={(value) => handleSearchQueryChange(index, value)}
              sortQuery={sortQuery}
              setSortQuery={setSortQuery}
              page={page}
              setPage={setPage}
              query={config.query}
              head={config.head}
              itemsPerPage={10}
              sortData={config.sort}
              _editFields={config.editFields}
              _detailPath={config._detailPath}
              _returnButton={config._returnButton}
              _textfield={config._textfield}
            />
          ))}
      </div>
    </div>
  );
};

export default Report;
