import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  token: localStorage.getItem("token") || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
    },
    logOut: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("notfication");
      return initialState;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

// Selectors to get user, token, and roles from the state
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentUserRoles = (state) =>
  state.auth.user?.role ? [state.auth.user.role] : []; // Extract roles

// Exporting the auth reducer
export default authSlice.reducer;
