import React, { useEffect, useRef, useState } from "react";
import { useCreateResourceMutation } from "../features/api/apiSlice";
import { CloseIcon, LoadingIcon } from "../constants/Icons";
import Select from "react-select";
import TextField from "./TextField";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { printHeaders } from "../constants/Data";
import PrintUi from "./PrintUi";
import ReactToPrint from "react-to-print";

const AddModal = (props) => {
  const { t } = useTranslation();
  const {
    _textfield,
    onCancel,
    _title,
    _query,
    id,
    actionButtonText,
    _itemName,
    banks,
    company,
    agents,
    branchs,
    customers,
    _printButton,
    data,
    text,
    totalDebtAmount,
  } = props;
  const userDataFromLocalStorage = localStorage.getItem("user");
  let role = "";
  let branchId = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role;
      branchId = userData.branch;
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }

  const location = useLocation();
  const path = location.pathname;
  const [formData, setFormData] = useState({});
  const [errMsg, setErrMsg] = useState(null);
  const [errWitthoutName, setErrWithoutName] = useState(false);
  const [isPositiveLoading, setIsPositiveLoading] = useState(false);
  const [isNegativeLoading, setIsNegativeLoading] = useState(false);
  const [isPositiveDisabled, setIsPositiveDisabled] = useState(false);
  const [isNegativeDisabled, setIsNegativeDisabled] = useState(false);
  const [selectedType, setSelectedType] = useState("bank");
  const [code, setCode] = useState("");
  const [pay, setPay] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false); // Track if form is submitted

  const componnentRef = useRef();
  useEffect(() => {
    if (role === "branchSuperAdmin" || role === "branchAdmin") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        branch: branchId,
      }));
    }
  }, [role, branchId]);
  let query = _query;

  if (_query === `expenses/getAllExpensesByBranch/${branchId}`) {
    query = "expenses"; // Fix: use = for assignment
  }
  const [createResource, { isSuccess, isError, isLoading }] =
    useCreateResourceMutation();

  useEffect(() => {
    if (_itemName === "itemId") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        itemId: id,
      }));
    } else if (_itemName === "debt") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        debt: id,
      }));
    } else if (_itemName === "agentTransferDebt") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        agentTransferDebt: id,
      }));
    } else if (_itemName === "selfDebt") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        selfDebt: id,
      }));
    } else if (_itemName === "branch") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        branch: branchId,
      }));
    } else if (_itemName === "companyId") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyId: id,
      }));
    }
  }, [_itemName, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the field is 'payAmount' and convert it to a number
    const updatedValue = name === "payAmount" ? Number(value) : value;

    setFormData({ ...formData, [name]: updatedValue });
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData({
      ...formData,
      [field.name]: selectedOption ? selectedOption.value : null,
    });
  };

  const handleRadioChange = (e) => {
    const newSelectedOption = e.target.value;

    setSelectedType(newSelectedOption);

    // Clear the opposite field in formData
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      if (newSelectedOption === "bank") {
        delete updatedFormData.agentTransfer;
      } else if (newSelectedOption === "agentTransfer") {
        delete updatedFormData.bank;
      }
      return updatedFormData;
    });
  };
  const handleSubmit = async (e, added) => {
    e.preventDefault();

    // Check if payAmount is greater than totalDebtAmount
    if (formData.payAmount > totalDebtAmount) {
      toast.error("ڕێژەی پارە زیاترە لە ڕیژەی قەرز", {
        className: "py-4 text-xl",
        style: { direction: "rtl" }, // Customize the style as needed
      });
      setIsPositiveLoading(false);
      setIsNegativeLoading(false);
      return; // Stop the function if condition is met
    }
    try {
      const response = await createResource({
        url: `/${query}`,
        formData: { ...formData, Added: added },
      }).unwrap();
      setCode(
        response?.data?.payDebt?.code
          ? response?.data?.payDebt?.code
          : response?.data?.agentTransferPayDebt?.code
          ? response?.data?.agentTransferPayDebt?.code
          : response?.data?.SelfpayDebt?.code
      );
      setPay(
        response?.data?.payDebt?.pay
          ? response?.data?.payDebt?.pay
          : response?.data?.agentTransferPayDebt?.pay
          ? response?.data?.agentTransferPayDebt?.pay
          : response?.data?.SelfpayDebt?.pay
      );

      toast.success("بە سەرکەوتوی زیادکرا", {
        className: "py-4 text-xl",
        style: { direction: "rtl" },
      });
      setIsPositiveLoading(false);
      setIsNegativeLoading(false);
      if (
        path !== "/Debts" &&
        path !== "/Report" &&
        path !== "/CompanyDebts" &&
        path !== "/AgentDebts" &&
        path !== `/Customer/${id}` &&
        path !== `/Company/${id}`
      ) {
        onCancel();
        setFormData({});
      }
      setIsSubmitted(true);
    } catch (err) {
      console.log("err :" , err)
      if (err.data?.error?.errors) {
        const validationErrors = {};
        props.errorNames &&
          props.errorNames.forEach((error) => {
            const fieldName = error.name;
            if (err.data?.error?.errors?.[fieldName]?.message) {
              validationErrors[fieldName] = t(
                err.data?.error?.errors?.[fieldName]?.message
              );
            }
          });

        setErrMsg(validationErrors);
      } else if (err?.data?.message) {
        setErrMsg(t(err?.data?.message));
        setErrWithoutName(true);
      }
      setIsPositiveLoading(false);
      setIsNegativeLoading(false);
    }
  };

  const handlePositiveClick = (e) => {
    setIsPositiveLoading(true);
    setIsNegativeDisabled(true);
    handleSubmit(e, true);
  };

  const handleNegativeClick = (e) => {
    setIsNegativeLoading(true);
    setIsPositiveDisabled(true);
    handleSubmit(e, false);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "px",
      border: "1px solid #097969",
      borderRadius: "0.375rem",
      padding: "0.200rem",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#a0aec0",
    }),
  };
  const getRequiredFields = () => {
    switch (path) {
      default:
        return []; // Default, no required fields
    }
  };

  // Get the required fields for the current route
  const requiredFields = getRequiredFields();
  // Updated logic to check if formData is empty based on the required fields for the current page
  const isFormEmpty = requiredFields.length
    ? requiredFields.some((field) => !formData[field])
    : Object.keys(formData).length === 0;

  const printData = {
    customer:
      data && data?.customer?.name
        ? data?.customer?.name
        : data?.company
        ? data?.company?.name
        : data?.agentTransfer
        ? data.agentTransfer?.name
        : data?.customer,
    phone:
      data && data?.customer?.phoneNumber
        ? data?.customer?.phoneNumber
        : data?.company
        ? data?.company?.phoneNumber
        : data?.agentTransfer
        ? data.agentTransfer?.phoneNumber
        : data?.phone,
    location:
      data && data?.customer?.address
        ? data?.customer?.address
        : data?.company
        ? data?.company?.address
        : data?.agentTransfer
        ? data.agentTransfer?.address
        : data?.location,
    pay: formData && formData.pay ? formData.pay : formData.payAmount,
    description: formData && formData.description,
    discount: formData && formData.discount,
    code: code,
    remainDebt: formData.payAmount
      ? data?.remainDebt - formData.payAmount
      : pay
      ? data?.debt_amount - pay
      : 0,
  };

console.log("formData :" , formData)
console.log("_textfield :" , _textfield)
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="w-[1000px] flex flex-col">
        <div className="bg-white p-2 rounded">
          <div className="flex justify-between mx-3 my-5 border items-center p-2 rounded-md bg-primary">
            <button type="button" className="p-2" onClick={onCancel}>
              <CloseIcon fill={"#fff"} />
            </button>
            <h1 className="text-2xl text-white font-medium flex justify-center w-full tracking-wide">
              {_title}
            </h1>
          </div>
          <form className="px-5" onSubmit={handleSubmit}>
            {/* Radio Buttons for Type Selection */}
            {path === "/Transfers" && (
              <div className="mb-4">
                <div className="flex items-center gap-4 mb-4">
                  <div>حەواڵە بۆ : </div>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="type"
                      value="bank"
                      checked={selectedType === "bank"}
                      onChange={handleRadioChange}
                      className="ml-2"
                    />
                    بانق
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="type"
                      value="agentTransfer"
                      checked={selectedType === "agentTransfer"}
                      onChange={handleRadioChange}
                      className="ml-2"
                    />
                    نوسینگە
                  </label>
                </div>
              </div>
            )}

            <div
              className={`flex flex-col lg:grid lg:${
                _textfield.length % 2 === 0 ? "grid-cols-2" : "grid-cols-1"
              } gap-4`}
            >
              {_textfield.map((field) => {
                let options = [];
                if (field.name === "company") {
                  options =
                    company &&
                    company.data?.data?.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }));
                } else if (field.name === "bank") {
                  options =
                    banks &&
                    banks.data?.data?.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }));
                } else if (field.name === "agentTransfer") {
                  options =
                    agents &&
                    agents.data?.data?.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }));
                } else if (field.name === "customer") {
                  options =
                    customers &&
                    customers.data?.data?.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }));
                } else if (field.name === "branchId") {
                  options =
                    branchs &&
                    branchs.data?.data?.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }));
                }
                return (
                  <div key={field.id} className="mb-4">
                    <div className="relative">
                      {field.type === "object" ? (
                        <div key={field.id} className="mt-4 w-full">
                          <label
                            htmlFor={field.name}
                            className="flex justify-start items-start mb-2 text-md font-medium text-black"
                          >
                            {field.label}
                          </label>
                          <Select
                            options={options}
                            styles={customStyles}
                            isSearchable={true}
                            onChange={(selectedOption) =>
                              handleSelectChange(selectedOption, field)
                            }
                            placeholder={field.placeholder}
                            isClearable={true}
                            isDisabled={
                              path === "/Transfers"
                                ? (selectedType === "bank" &&
                                    field.name === "agentTransfer") ||
                                  (selectedType === "agentTransfer" &&
                                    field.name === "bank")
                                : false
                            }
                          />
                        </div>
                      ) : (
                        <TextField
                          _id={field.id}
                          _for={field.name}
                          _type={field.type}
                          _placeholder={field.placeholder}
                          _labelText={field.label}
                          _icon={field.icon}
                          _additionalClasses="w-full"
                          onChange={handleInputChange}
                        />
                      )}
                    </div>
                    {errMsg && errMsg[field.name] && (
                      <span className="text-sm pt-2 text-red-500">
                        {errMsg[field.name]}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="col-span-2 mb-4">
              <div className="flex items-center gap-5">
                <button
                  disabled={isFormEmpty || isPositiveDisabled}
                  type="button"
                  onClick={handlePositiveClick}
                  className={`w-full px-4 py-2 text-black rounded-md border border-primary hover:bg-primary hover:text-white transition-all duration-200 ease-in-out text-xl ${
                    isFormEmpty || isPositiveDisabled
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                >
                  {isPositiveLoading ? (
                    <div
                      role="status"
                      className="flex justify-center items-center"
                    >
                      <LoadingIcon />
                    </div>
                  ) : text ? (
                    text
                  ) : (
                    "زیادکردن"
                  )}
                </button>
                {actionButtonText && (
                  <button
                    disabled={isFormEmpty || isNegativeDisabled}
                    type="button"
                    onClick={handleNegativeClick}
                    className={`w-full px-4 py-2 text-black rounded-md border border-primary hover:bg-primary hover:text-white transition-all duration-200 ease-in-out text-xl ${
                      isFormEmpty || isNegativeDisabled
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                  >
                    {isNegativeLoading ? (
                      <div
                        role="status"
                        className="flex justify-center items-center"
                      >
                        <LoadingIcon />
                      </div>
                    ) : (
                      "بەکارهێنان"
                    )}
                  </button>
                )}

                {_printButton && (
                  <>
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <button
                            disabled={!isSubmitted}
                            type="button"
                            className={`p-1.5 px-10 my-2 mb-2 text-xl font-medium hover:text-white rounded-lg border border-primary hover:bg-primary transition-colors duration-200 ease-in-out mt-4 w-full ${
                              isSubmitted
                                ? "cursor-pointer"
                                : "cursor-not-allowed"
                            }`}
                          >
                            پرینت کردن
                          </button>
                        );
                      }}
                      content={() => componnentRef.current}
                    />
                    <PrintUi
                      ref={componnentRef}
                      _footer={false}
                      _printData={printData}
                      printType={"وەرگرتنەوەی پارە"}
                      header={printHeaders[0].payDebtHeader}
                      bodyFooter={false}
                      payDebtBody={true}
                    />
                  </>
                )}
              </div>
            </div>
          </form>
          {errWitthoutName && (
            <p
              className={
                errMsg
                  ? "text-white text-lg font-semibold bg-red-500 flex justify-center items-center m-5 py-3 rounded-lg"
                  : "hidden"
              }
              aria-live="assertive"
            >
              {errMsg}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddModal;
