import React, { useState, useEffect } from "react";
import { TableDropDowns, TableHeaders } from "../constants/Data";
import DetailTable from "../components/DetailTable";
import { useGetResourceQuery } from "../features/api/apiSlice";
import PageTitle from "../components/PageTitle";
import ServerError from "./ServerError";
import { LoadingIcon } from "../constants/Icons";

const Notfications = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";
  let branch = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role;
      branch = userData.branch;
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }

  const [searchQueries, setSearchQueries] = useState({});
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);

  const {
    data,
    error,
    isLoading: loading,
  } = useGetResourceQuery(
    `${branch ? `/notifications/${branch}` : `/notifications`}`
  );

  // Ensure this useEffect hook is always called
  useEffect(() => {
    if (data) {
      const notificationNumber = data.notificationNumber;
      localStorage.setItem("notfication", notificationNumber);
    }
  }, []); // Dependency array includes only `data`

  if (loading)
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  if (error) return <ServerError />;

  const notfication = data && data.data;
  const { items, debts, lowStockItems } = notfication || {};

  const tableConfigs = [
    {
      searchName: "ماددەکان",
      tableTitle: "ماددەکان",
      data: branch ? lowStockItems : items,
      head: branch
        ? TableHeaders[0].branchItemHeader
        : TableHeaders[0].notficationItemHeaders,
      sort: TableDropDowns[0].itemDropDowns,
    },
    {
      searchName: "قەرزەکان",
      tableTitle: "قەرزەکان",
      data: debts,
      head: TableHeaders[0].notficationDebtHeader,
      sort: TableDropDowns[0].customerDropDowns,
    },
  ];

  const handleSearchQueryChange = (tableIndex, value) => {
    setSearchQueries((prev) => ({
      ...prev,
      [tableIndex]: value,
    }));
  };
  return (
    <div className="w-full">
      <PageTitle title={"ئاگاداریەکان"} />
      {/* <div className="flex flex-col lg:flex-row justify-between lg:py-5 lg:px-8 my-8 py-3 px-4 mx-2 lg:mx-2xl:mx-16">
        <div className="flex p-5"></div>
      </div> */}
      <div className="mx-2 lg:mx-12 2xl:mx-16 pb-20">
        {data &&
          tableConfigs.map((config, index) => (
            <DetailTable
              key={index}
              searchName={config.searchName}
              tableTitle={config.tableTitle}
              data={config.data}
              searchQuery={searchQueries[index] || ""}
              setSearchQuery={(value) => handleSearchQueryChange(index, value)}
              sortQuery={sortQuery}
              setSortQuery={setSortQuery}
              page={page}
              setPage={setPage}
              query={config.query}
              head={config.head}
              itemsPerPage={10}
              sortData={config.sort}
            />
          ))}
      </div>
    </div>
  );
};

export default Notfications;
