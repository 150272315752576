import React from "react";
import { LoadingIcon } from "../constants/Icons";

const PrimaryButton = (props) => {
  const { type, onClick, _disabled, _additionalClasses, _text, _isLoading } =
    props;
  return (
    <button
      type={type}
      className={`p-1.5 px-10 my-2 mb-2 text-xl font-medium text-black focus:outline-none ${
        _additionalClasses.includes("bg") ? "" : "bg-white "
      }
                  rounded-lg border border-primary hover:bg-primary hover:text-white focus:z-10 
                  focus:ring-4 focus:ring-gray-100 duration-200 ease-in-out  ${_additionalClasses}`}
      onClick={onClick}
      disabled={_disabled}
    >
      {_isLoading ? (
        <div role="status" className="flex justify-center items-center">
          <LoadingIcon />
        </div>
      ) : (
        _text
      )}
    </button>
  );
};

export default PrimaryButton;
