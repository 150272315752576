import React, { useEffect, useRef, useState } from "react";
import {
  useCreateResourceMutation,
  useGetResourceQuery,
} from "../features/api/apiSlice";
import PageTitle from "./PageTitle";
import TextField from "./TextField";
import PrimaryButton from "./PrimaryButton";
import toast from "react-hot-toast";
import Select from "react-select";
import SelectItemModal from "./SelectItemModal";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import PrintUi from "./PrintUi";
import { editFields, printHeaders } from "../constants/Data";
import { CloseIcon } from "../constants/Icons";
import { useLocation } from "react-router-dom";
import SelectedItemsTable from "./SelectedItemsTable";

const Add = (props) => {
  const { t } = useTranslation();

  const location = useLocation();
  const path = location.pathname;
  const initialFormData =
    path === "AddPurchase"
      ? { createdAt: new Date().toISOString().split("T")[0] }
      : {};

  const [formData, setFormData] = useState(initialFormData);
  const [requestData, setRequestData] = useState({
    startDate: null,
    endDate: null,
  });
  const [errMsg, setErrMsg] = React.useState(null);
  const [errWitthoutName, setErrWithoutName] = useState(false);
  const [customerData, setCustomerData] = useState();
  const totalDebtsData = customerData && customerData.data?.totalDebts;

  const [isSubmitted, setIsSubmitted] = useState(false); // Track if form is submitted
  const componnentRef = useRef();
  const errRef = useRef();

  useEffect(() => {
    if (props._role === "branchSuperAdmin" || props._role === "branchAdmin") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        branch: props._branch, // Assuming branch information is available in userData
      }));
    }
  }, [props._role, props._branch]);

  const [selectItemModal, setSelectItemModal] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [tableVisible, setTableVisible] = useState(false);
  const [code, setCode] = useState("");
  const [editingItem, setEditingItem] = useState(false); // To track which item is being edited

  const [createResource, { isSuccess, isError, isLoading }] =
    useCreateResourceMutation();
  const { data: currency } = useGetResourceQuery(`/currencies`);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "pay_cash") {
      const remainingDebt = formattedTotalSellPrice - parseFloat(value);
      setFormData({
        ...formData,
        [name]: parseFloat(value), // Parse value to number
        pay_debt: remainingDebt >= 0 ? remainingDebt : 0,
      });
    } else if (name === "pay_debt") {
      const remainingCash = formattedTotalSellPrice - parseFloat(value);
      setFormData({
        ...formData,
        [name]: parseFloat(value), // Parse value to number
        pay_cash: remainingCash >= 0 ? remainingCash : 0,
      });
    } else {
      setFormData({
        ...formData,
        [name]: updatedValue,
      });
    }
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData({
      ...formData,
      [field.name]: selectedOption,
    });
  };

  const totalSellPrice = itemsData.reduce((total, selectedItem) => {
    const { quantity, sell_price } = selectedItem;
    return total + quantity * sell_price;
  }, 0);
  const formattedTotalSellPrice = parseFloat(totalSellPrice);

  const totalPriceInDinar =
    totalSellPrice * (currency?.data?.data[0]?.dinar || 0);

  useEffect(() => {
    const purchaseItems = itemsData.map((item) => ({
      item: item.item_id,
      quantity: item.quantity,
      purchase_price: item.purchase_price,
      sell_price: item.sell_price,
    }));
    if (purchaseItems.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        items: purchaseItems,
        pay_cash: 0, // Set the pay_cash field to the total sell price
        pay_debt: formattedTotalSellPrice,
      }));
    }
  }, [itemsData, formattedTotalSellPrice]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { company, customer, cash, ...restFormData } = formData;

    // Prepare the request data
    const requestData = {
      ...restFormData,
      customer: customer?.value,
      company: company?.value,
      cash: cash?.value,
    };

    // Check if total payment exceeds total price
    const totalPayment = formData.pay_debt + formData.pay_cash;
    if (totalPayment > totalSellPrice) {
      setErrMsg("ڕێژەی پارەدان نابێت لە کۆی گشتی زیاتر بێت");
      setErrWithoutName(true);
      return;
    }

    try {
      const response = await createResource({
        url: `/${props._query}`,
        formData: requestData,
      }).unwrap();
      setCode(response?.data?.code && response?.data?.code);
      toast.success("بە سەرکەوتوی زیادکرا", {
        className: "py-4 text-xl",
        style: { direction: "rtl" },
      });

      setIsSubmitted(true); // Mark the form as submitted
      // Clear items
      if (path !== "/AddPurchase") {
        setFormData({});
        setItemsData([]);
      }
      setErrMsg("");
    } catch (err) {
      console.log(err);
      // Handle validation errors
      if (err.data?.error?.errors) {
        const validationErrors = {};
        props.errorNames &&
          props.errorNames.forEach((error) => {
            const fieldName = error.name;
            if (err.data?.error?.errors?.[fieldName]?.message) {
              validationErrors[fieldName] = t(
                err.data?.error?.errors?.[fieldName]?.message
              );
            }
          });
        if (validationErrors) {
          setErrMsg(validationErrors);
        } else if (err?.data?.error?.keyValue?.code) {
          setErrWithoutName(true);
          setErrMsg("کۆدی ئەم ماددەیە بونی هەیە");
        }
      } else {
        if (err?.data?.message) {
          setErrWithoutName(true);
          setErrMsg(t(err?.data?.message));
        }
      }
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "px",
      border: "1px solid #097969",
      borderRadius: "0.375rem",
      padding: "0.200rem",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#a0aec0",
    }),
  };

  const handleAddSelectedItem = (
    item_name,
    item_id,
    quantity,
    purchase_price,
    sell_price,
    item_code
  ) => {
    const existingItem = itemsData.find(
      (selectedItem) => selectedItem.item_id === item_id
    );

    const newQuantity = parseInt(quantity, 10);
    if (isNaN(newQuantity)) {
      return; // Early exit if quantity is not a valid number
    }

    if (existingItem) {
      if (existingItem.sell_price !== sell_price) {
        existingItem.sell_price = sell_price;
      }
      existingItem.quantity += newQuantity;
    } else {
      setItemsData([
        ...itemsData,
        {
          item_name,
          item_id,
          quantity: newQuantity,
          sell_price,
          purchase_price,
          item_code,
        },
      ]);
    }

    setTableVisible(true);
  };

  const handleEditItem = (item) => {
    setEditingItem(item); // Open the modal with the selected item's data
  };
  const handleSaveEditedItem = (updatedItem) => {
    const updatedItems = itemsData.map((item) =>
      item.item_id === updatedItem.item_id ? updatedItem : item
    );
    setItemsData(updatedItems); // Update the state with the edited item
    setEditingItem(null); // Close the modal after saving
  };

  const handleDeleteItem = (itemId) => {
    const updatedItems = itemsData.filter((item) => item.item_id !== itemId);
    setItemsData(updatedItems);
  };

  const Fields = props?._textFields?.map((field, idx) => {
    if (field.type === "object") {
      if (
        field.name === "branch" &&
        (props._role === "branchSuperAdmin" || props._role === "admin")
      ) {
        return null; // Skip rendering the branch field for branchSuperAdmin and admin roles
      }
      let options = [];
      if (field.name === "company") {
        options =
          props.companyOptions &&
          props.companyOptions.data?.data?.map((item) => ({
            value: item._id,
            label: item.name,
          }));
      } else if (field.name === "customer") {
        options =
          props.customerOptions &&
          props.customerOptions.data?.data?.map((item) => ({
            value: item._id,
            label: item.name,
            phone: item.phoneNumber,
            location: item.address,
          }));
      } else if (field.name === "branch") {
        options =
          props.branchesOptions &&
          props.branchesOptions.data?.data?.map((item) => ({
            value: item._id,
            label: item.name,
          }));
      } else if (field.name === "cash") {
        options = [
          { value: true, label: "بەڵێ" },
          { value: false, label: "نەخێر" },
        ];
      }

      return (
        <div key={idx} className="px-4 my-4 w-full">
          <label
            htmlFor={field.name}
            className="flex justify-start items-start mb-2 text-md font-medium text-black"
          >
            {field.label}
          </label>
          <Select
            options={options}
            styles={customStyles}
            isSearchable={true}
            isLoading={props.isLoading}
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, field)
            }
            placeholder={field.placeholder}
            isClearable={true}
            value={formData[field.name] || null} // Bind value to formData to allow clearing
          />
          {errMsg && errMsg[field.name] && (
            <span className="text-sm pt-2 text-red-500">
              {errMsg[field.name]}
            </span>
          )}
        </div>
      );
    } else if (field.name === "items") {
      const handleOpenModal = () => {
        setSelectItemModal(true);
      };
      return (
        <div key={idx} className="px-4  mt-10 w-full">
          <button
            onClick={handleOpenModal}
            disabled={props.isLoading} // Disable button if loading
            className={`p-2 px-10 my-2 mb-2 text-xl font-medium text-black focus:outline-none 
                rounded-lg border border-primary ${
                  props.isLoading
                    ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                    : "hover:bg-primary hover:text-white"
                } focus:z-10 focus:ring-4 focus:ring-gray-100 duration-200 ease-in-out w-full`}
          >
            دیاری کردنی ماددە
          </button>
          {selectItemModal && (
            <SelectItemModal
              onCancel={() => selectItemModal(false)}
              items={props.items && props.items}
              setSelectItemModal={setSelectItemModal}
              onSelectItem={handleAddSelectedItem}
              branch={props._branch}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-start px-4 mb-4 w-full" key={idx}>
          <TextField
            _id={field.id}
            _for={field.name}
            _type={field.type}
            _placeholder={field.placeholder}
            _labelText={field.label}
            _icon={field.icon}
            _additionalClasses="w-full"
            onChange={handleInputChange}
            _value={formData[field.name] || ""}
          />
          {errMsg && errMsg[field.name] && (
            <span className="text-sm pt-2 text-red-500">
              {errMsg[field.name]}
            </span>
          )}
        </div>
      );
    }
  });

  const printData = {
    customer: formData && formData.customer?.label,
    phone: formData && formData.customer?.phone,
    location: formData && formData.customer?.location,
    customer_id: formData && formData.customer?.value,
    items: itemsData.map((item) => ({
      item_name: item.item_name,
      item_code: item.item_code,
      quantity: item.quantity,
      sell_price: item.sell_price,
      total_price: item.quantity * item.sell_price,
    })),
    discount: formData && formData.discount,
    totalPrice: totalSellPrice,
    cash: formData && formData.pay_cash,
    debt: formData && formData.pay_debt,
    code: code,
    totalDebtAmount: totalDebtsData && totalDebtsData?.totalDebtAmount,
    pervDebtInvoice: formData && formData.pervDebtInvoice,
  };

  const getDebtAmount = async (e) => {
    try {
      const response = await createResource({
        url: `/customers/${printData?.customer_id}`,
        formData: requestData,
      }).unwrap();
      setCustomerData(response);
      console.log("res =>", response);
    } catch (err) {
      console.log("err :", err);
    }
  };

  useEffect(() => {
    getDebtAmount();
  }, [printData?.customer_id, requestData]);
  useEffect(() => {
    if (path === "/AddPurchase" && customerData) {
      const debtAmount = customerData.data?.totalDebts?.totalDebtAmount;
      setFormData((prevFormData) => ({
        ...prevFormData,
        pervDebtInvoice: debtAmount === 0 ? -1 : debtAmount,
      }));
    }
  }, [customerData, path]);

  return (
    <div>
      <PageTitle title={props.pageTitle} />
      <div className="container mx-auto flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div className="lg:w-1/2">
          <div className="grid grid-cols-2">{Fields}</div>
          <div className="">
            {tableVisible && itemsData.length > 0 && (
              <>
                <SelectedItemsTable itemsData={itemsData} />
              </>
            )}

            {tableVisible && itemsData.length > 0 && (
              <div className="flex items-center gap-5 justify-start mt-4">
                <p>
                  {" "}
                  کۆی گشتی نرخ بە دۆلار :{" "}
                  {formattedTotalSellPrice.toLocaleString()}
                </p>
                <p>
                  کۆی گشتی نرخ بە دینار : {totalPriceInDinar.toLocaleString()}
                </p>
              </div>
            )}
          </div>
          {errWitthoutName && (
            <p
              ref={errRef}
              className={
                errMsg
                  ? "text-white text-lg font-semibold bg-red-500 flex justify-center items-center m-5 py-3 rounded-lg"
                  : "hidden"
              }
              aria-live="assertive"
            >
              {errMsg}
            </p>
          )}

          <div
            className={`${
              props._printButton && "flex justify-between gap-10 items-center"
            } px-4 -`}
          >
            <PrimaryButton
              _text={"زیادکردن"}
              onClick={handleSubmit}
              _isLoading={isLoading}
              _additionalClasses="mt-4 w-full"
            />
            {props._printButton && (
              <ReactToPrint
                trigger={() => {
                  return (
                    <button
                      className={`p-1.5 px-10 my-2 mb-2 text-xl font-medium hover:text-white rounded-lg border border-primary hover:bg-primary transition-colors duration-200 ease-in-out mt-4 w-full ${
                        isSubmitted ? "cursor-pointer" : "cursor-not-allowed"
                      }`}
                      disabled={!isSubmitted}
                      type="button"
                    >
                      پرینت کردن
                    </button>
                  );
                }}
                content={() => componnentRef.current}
              />
            )}
            <PrintUi
              ref={componnentRef}
              _footer={true}
              _printData={printData}
              printType={"فرۆشتن"}
              header={printHeaders[0].purchasePrintHeader}
              purchaseBody={true}
              bodyFooter={true}
            />
          </div>
        </div>
        <div className="lg:w-1/2 flex justify-center items-center">
          <img
            src={props.imageUrl}
            alt="Right Side"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
      {editingItem && (
        <EditSelectedItemsModal
          item={editingItem}
          onSave={handleSaveEditedItem}
          onCancel={() => setEditingItem(false)}
          inputs={editFields[0].selectedItemsField}
        />
      )}
    </div>
  );
};

export default Add;

export const EditSelectedItemsModal = ({ item, onSave, onCancel, inputs }) => {
  const [updatedItem, setUpdatedItem] = useState({ ...item });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update the specific field in the updatedItem state
    setUpdatedItem({
      ...updatedItem,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the entire updated item object back to the parent
    onSave(updatedItem);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="w-[1000px] flex flex-col">
        <div className="bg-white p-2 rounded">
          <div className="flex justify-between mx-3 my-5 border items-center p-2 rounded-md bg-primary">
            <button type="button" className="p-2" onClick={onCancel}>
              <CloseIcon fill={"#fff"} />
            </button>
            <h1 className="text-2xl text-white font-medium flex justify-center w-full tracking-wide">
              نوێکردنەوە
            </h1>
          </div>
          <div>
            <form className="px-5" onSubmit={handleSubmit}>
              <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
                {inputs.map((field, idx) => (
                  <div key={field.name} className="mt-4">
                    <label className="flex justify-start items-start mb-2 text-md font-medium text-black">
                      {field.label}
                    </label>
                    <input
                      type={field.type}
                      name={field.name}
                      value={updatedItem[field.name] || ""}
                      onChange={(e) => handleChange(e, idx)}
                      className="block w-full px-3 py-2 text-sm border border-primary rounded-lg"
                      required
                    />
                  </div>
                ))}
              </div>
              <div className="col-span-2 mb-4">
                <PrimaryButton
                  _text={"نوێکردنەوە"}
                  _additionalClasses={"w-full mt-5"}
                  onClick={handleSubmit}
                  type={"submit"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
