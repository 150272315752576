import React from "react";
import {
  TableDropDowns,
  TableHeaders,
  addModalFields,
  editFields,
} from "../constants/Data";
import Table from "../components/Table";

const Items = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }
  let Header = TableHeaders[0].itemHeaders;
  if (role === "admin") {
    Header = Header.filter(
      (headerItem) =>
        headerItem.field !== "actions" &&
        headerItem.field !== "sendItem" &&
        headerItem.field !== "addPieces"
    );
  }

  return (
    <div className="w-full ">
      <Table
        pageTitle={"ماددەکان"}
        searchName={"ماددەکان"}
        head={Header}
        query={"items"}
        _editFields={editFields[0].itemsFields}
        addPath={"/AddItems"}
        _addButton={role !== "branchSuperAdmin" && role !== "admin"}
        _textfield={addModalFields[0].sendItemsToBranch}
        sortData={TableDropDowns[0].itemDropDowns}
      />
    </div>
  );
};

export default Items;
