import React, { useState } from "react";
import Table from "../components/Table";
import { useGetResourceQuery } from "../features/api/apiSlice";
import ServerError from "./ServerError";
import { editFields, TableDropDowns, TableHeaders } from "../constants/Data";
import PageTitle from "../components/PageTitle";

const Users = () => {
  return (
    <div className="w-full ">
      <Table
        _blockButton={true}
        pageTitle={"بەکارهێنەرەکان"}
        searchName={"بەکارهێنەرەکان"}
        head={TableHeaders[0].usersTableHeaders}
        query={"Users"}
        sortData={TableDropDowns[0].userDropDowns}
        addPath={"/AddUsers"}
        _addButton={true}
        _editFields={editFields[0].usersFields}
      />
    </div>
  );
};

export default Users;
