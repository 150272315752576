import { apiSlice } from "../api/apiSlice";
import { setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/users/login",
        method: "POST",
        body: { ...credentials },
      }),
      onSuccess: (data, { dispatch }) => {
        dispatch(setCredentials(data));
      },
    }),
    register: builder.mutation({
      query: (userData) => ({
        url: "/users/signup",
        method: "POST",
        body: { ...userData },
      }),
      onSuccess: (data, { dispatch }) => {
        dispatch(setCredentials(data));
      },
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation } = authApiSlice;
