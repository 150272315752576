import React from "react";
import Table from "../components/Table";
import {
  TableDropDowns,
  TableHeaders,
  addModalFields,
  editFields,
} from "../constants/Data";

const Debts = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }

  let Header = TableHeaders[0].debtHeader;
  if (role !== "superAdmin") {
    Header = Header.filter((headerItem) => headerItem.field !== "actions");
  }
  if (role === "superAdmin") {
    Header = Header.filter((headerItem) => headerItem.field !== "return");
  }

  return (
    <div>
      <Table
        pageTitle={"قەرزەکان"}
        searchName={"قەرزەکان"}
        head={Header}
        sortData={TableDropDowns[0].debtDropDowns}
        query={"debts"}
        _addButton={true}
        _returnButton={true}
        _textfield={addModalFields[0].addDebtFields}
        _detailPath={"Debt"}
        _editFields={editFields[0].debtFields}
      />
    </div>
  );
};

export default Debts;
