import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetResourceQuery } from "../../features/api/apiSlice";
import PageTitle from "../../components/PageTitle";
import { TbTournament } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
import DetailTable from "../../components/DetailTable";
import { TableHeaders } from "../../constants/Data";

const BranchDetail = () => {
  const { id } = useParams();

  const [searchQueries, setSearchQueries] = useState({});
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);

  const {
    data: branchData,
    error,
    isLoading: loading,
  } = useGetResourceQuery(`/branches/${id}`);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading customer data</p>;

  const branchInfo = branchData && branchData.data?.data;

  const { name, address, items, users } = branchInfo;

  const customer = [
    { id: 1, icon: <TbTournament />, data: name },
    { id: 2, icon: <FaLocationDot />, data: address },
  ];
  const tableConfigs = [
    {
      searchName: "ماددەکان",
      tableTitle: "ماددەکان",
      data: items,
      head: TableHeaders[0].branchItemsDetail,
      // sort: TableDropDowns[0].itemDropDowns,
    },
    {
      searchName: "بەکارهێنەرەکان",
      tableTitle: "بەکارهێنەرەکان",
      data: users,
      head: TableHeaders[0].customersTableHeader,
      // sort: TableDropDowns[0].customerDropDowns,
    },
  ];

  const handleSearchQueryChange = (tableIndex, value) => {
    setSearchQueries((prev) => ({
      ...prev,
      [tableIndex]: value,
    }));
  };
  return (
    <div className="w-full">
      <PageTitle title={"زانیاری لق"} />
      <div className="flex flex-col lg:flex-row justify-between lg:py-5 lg:px-8 my-8 py-3 px-4 mx-2 lg:mx-   2xl:mx-16 ">
        <div className="flex p-5">
          {customer.map((item) => (
            <div key={item.id} className="flex items-center px-10 text-2xl">
              <div className="ml-2  text-primary">{item.icon}</div>
              <h1 className="">{item.data}</h1>
            </div>
          ))}
        </div>
      </div>

      <div className=" mx-2 lg:mx-12 2xl:mx-16 pb-20 ">
        {branchData &&
          tableConfigs.map((config, index) => (
            <DetailTable
              key={index}
              searchName={config.searchName}
              tableTitle={config.tableTitle}
              data={config.data}
              searchQuery={searchQueries[index] || ""}
              setSearchQuery={(value) => handleSearchQueryChange(index, value)}
              sortQuery={sortQuery}
              setSortQuery={setSortQuery}
              page={page}
              setPage={setPage}
              query={config.query}
              head={config.head}
              itemsPerPage={10}
              sortData={config.sort}
            />
          ))}
      </div>
    </div>
  );
};

export default BranchDetail;
