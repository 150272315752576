import React from "react";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../../constants/Data";
import Table from "../../components/Table";
const CompanyDebts = () => {
  return (
    <div>
      <Table
        pageTitle={"قەرزی کۆمپانیاکان"}
        searchName={"قەرزی کۆمپانیاکان"}
        head={TableHeaders[0].companyDebtTableHeader}
        query={"selfDebts"}
        sortData={TableDropDowns[0].customerDropDowns}
        _textfield={addModalFields[0].companyDebtsField}
        _editFields={editFields[0].companySelfDebtFields}
        _addButton={true}
        _returnButton={true}
      />
    </div>
  );
};

export default CompanyDebts;
