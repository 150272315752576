import React, { useState } from "react";
import { useGetResourceQuery } from "../features/api/apiSlice";
import { TableHeaders } from "../constants/Data";
import DetailTable from "../components/DetailTable";
import PageTitle from "../components/PageTitle";
import { ItemNameIcon } from "../constants/Icons";
import { HoverEffect } from "../components/HoverEffect";

const OwnerProfits = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const {
    data: data,
    error,
    isLoading: loading,
  } = useGetResourceQuery(
    `/reports/income?search=${searchQuery}&sort=${sortQuery}&page=${page}&limit=${itemsPerPage}`
  );
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading customer data</p>;

  const { dailyProfits, weeklyProfits, monthlyProfits, yearlyProfits } = data;

  const prfitIncomes = [
    {
      id: 1,
      title: "کۆی گشتی قازانجی ڕۆژانە",
      icon: <ItemNameIcon />,
      description: data.dailyIncome,
    },
    {
      id: 2,
      title: "کۆی گشتی قازانجی حەفتانە",
      icon: <ItemNameIcon />,
      description: data?.weeklyIncome,
    },
    {
      id: 3,
      title: "کۆی گشتی قازانجی مانگانە",
      icon: <ItemNameIcon />,
      description: data?.monthlyIncome,
    },
    {
      id: 4,
      title: "کۆی گشتی قازانجی ساڵانە",
      icon: <ItemNameIcon />,
      description: data?.yearlyIncome,
    },
  ];
  return (
    <div className="w-full">
      <PageTitle title={"قازانجی بەڕێوبەرەکان"} />

      <div className="mx-2 lg:mx-12 2xl:mx-16 pt-20 ">
        <HoverEffect items={prfitIncomes} />
      </div>
      <div className=" mx-2 lg:mx-12 2xl:mx-16 pb-20 ">
        <DetailTable
          searchName={"قازانجی ڕۆژانە"}
          tableTitle={"قازانجی ڕۆژانە"}
          data={dailyProfits}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          sortQuery={sortQuery}
          setSortQuery={setSortQuery}
          page={page}
          setPage={setPage}
          query={" qq"}
          head={TableHeaders[0].ownerProfitHeader}
          itemsPerPage={itemsPerPage}
        />
        <DetailTable
          searchName={"قازانجی حەفتانە"}
          tableTitle={"قازانجی حەفتانە"}
          data={weeklyProfits}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          sortQuery={sortQuery}
          setSortQuery={setSortQuery}
          page={page}
          setPage={setPage}
          query={"purchases"}
          head={TableHeaders[0].ownerProfitHeader}
          itemsPerPage={itemsPerPage}
        />
        <DetailTable
          searchName={"قازانجی مانگانە"}
          tableTitle={"قازانجی مانگانە"}
          data={monthlyProfits}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          sortQuery={sortQuery}
          setSortQuery={setSortQuery}
          page={page}
          setPage={setPage}
          query={"purchases"}
          head={TableHeaders[0].ownerProfitHeader}
          itemsPerPage={itemsPerPage}
        />
        <DetailTable
          searchName={"قازانجی ساڵانە"}
          tableTitle={"قازانجی ساڵانە"}
          data={yearlyProfits}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          sortQuery={sortQuery}
          setSortQuery={setSortQuery}
          page={page}
          setPage={setPage}
          query={"purchases"}
          head={TableHeaders[0].ownerProfitHeader}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </div>
  );
};

export default OwnerProfits;
