import React from "react";
import { Route, Routes } from "react-router-dom";
import Users from "../pages/Users";
import Slidebar from "../layouts/Sidebar";
import Dashboard from "../pages/Dashboard";
import Customers from "../pages/Customers";
import Login from "../pages/Login";
import Items from "../pages/Items";
import NotFound from "../pages/404";
import RequireAuth from "../features/auth/RequireAuth";
import Purchases from "../pages/Purchases";
import Owners from "../pages/Owners";
import { sideBarLinks } from "../constants/Data";
import Debts from "../pages/Debts";
import AddItems from "../pages/AddItems";
import AddPurchase from "../pages/AddPurchase";
import UserDetail from "../pages/UserDetail";
import AddUsers from "../pages/AddUsers";
import Expenses from "../pages/Expenses";
import Daxila from "../pages/Daxila";
import Branchs from "../pages/Branch/Branchs";
import Unauthorized from "../pages/Unauthorized";
import CustomerDetail from "../pages/CustomerDetail";
import BranchDetail from "../pages/Branch/BranchDetail";
import Banks from "../pages/Banks";
import DaxilaBranch from "../pages/Branch/Daxila";
import Transfers from "../pages/Transfers";
import Companies from "../pages/Company/Companies";
import CompanyDetail from "../pages/Company/CompanyDetail";
import DebtDetail from "../pages/DebtDetail";
import Agents from "../pages/Agent/Agents";
import AgentTransferDebts from "../pages/Agent/AgentTransferDebts";
import ReturnDebts from "../pages/Agent/ReturnDebts";
import Notfications from "../pages/Notfications";
import Returns from "../pages/Returns";
import CompanyDebts from "../pages/Company/CompanyDebts";
import CompanyReturnDebts from "../pages/Company/ReturnDebts";
import UserLogs from "../pages/UserLogs";
import Signup from "../pages/SignUp";
import BranchDashboard from "../pages/Branch/BranchDashboard";
import ServerError from "../pages/ServerError";
import OwnerProfits from "../pages/OwnerProfits";
import Report from "../pages/Report";
import BranchItems from "../pages/Branch/BranchItems";
import ExpensesBranch from "../pages/Branch/Expenses";

const AppRoute = () => {
  return (
    <Routes>
      {/*Protected routes */}
      <Route
        element={<Slidebar sideBarLinks={sideBarLinks[0].superAdminLinks} />}
      >
        {/*Main routes */}
        <Route
          path="/"
          index
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<Dashboard />}
            />
          }
        />
        <Route
          path="/Users"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<Users />} />
          }
        />
        <Route
          path="/Logs"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<UserLogs />} />
          }
        />
        <Route
          path="/Customers"
          element={
            <RequireAuth
              allowedRoles={[
                "superAdmin",
                "admin",
                "branchSuperAdmin",
                "branchAdmin",
              ]}
              element={<Customers />}
            />
          }
        />
        <Route
          path="/Items"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<Items />} />
          }
        />
        <Route
          path="/BranchItems"
          element={
            <RequireAuth
              allowedRoles={["branchAdmin", "branchSuperAdmin"]}
              element={<BranchItems />}
            />
          }
        />
        <Route
          path="/Purchases"
          element={
            <RequireAuth
              allowedRoles={[
                "superAdmin",
                "admin",
                "branchSuperAdmin",
                "branchAdmin",
              ]}
              element={<Purchases />}
            />
          }
        />
        <Route
          path="/Owners"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<Owners />} />
          }
        />
        <Route
          path="/Owners/profit"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<OwnerProfits />}
            />
          }
        />
        <Route
          path="/Report"
          element={
            <RequireAuth
              allowedRoles={["superAdmin", "branchSuperAdmin"]}
              element={<Report />}
            />
          }
        />
        <Route
          path="/Debts"
          element={
            <RequireAuth
              allowedRoles={[
                "superAdmin",
                "admin",
                "branchSuperAdmin",
                ,
                "branchAdmin",
              ]}
              element={<Debts />}
            />
          }
        />
        <Route
          path="/Expenses"
          element={
            <RequireAuth
              allowedRoles={["superAdmin", "branchSuperAdmin", "branchAdmin"]}
              element={<Expenses />}
            />
          }
        />

        <Route
          path="/Daxila"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<Daxila />} />
          }
        />
        <Route
          path="/Branches"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<Branchs />} />
          }
        />
        <Route
          path="/BranchDashboard/:id"
          element={
            <RequireAuth
              allowedRoles={["superAdmin", "branchSuperAdmin"]}
              element={<BranchDashboard />}
            />
          }
        />
        <Route
          path="/Banks"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<Banks />} />
          }
        />
        <Route
          path="/Transfers"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<Transfers />}
            />
          }
        />
        <Route
          path="/Companies"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<Companies />}
            />
          }
        />
        <Route
          path="/CompanyDebts"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<CompanyDebts />}
            />
          }
        />
        <Route
          path="/CompanyPayDebts"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<CompanyReturnDebts />}
            />
          }
        />
        <Route
          path="/Agents"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<Agents />} />
          }
        />
        <Route
          path="/AgentDebts"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<AgentTransferDebts />}
            />
          }
        />
        <Route
          path="/AgentPayDebts"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<ReturnDebts />}
            />
          }
        />
        <Route path="/Notfications" element={<Notfications />} />
        <Route path="/Returns" element={<Returns />} />

        {/*Detail routes */}
        <Route path="/Profile/:id" element={<UserDetail />} />
        <Route path="/Customer/:id" element={<CustomerDetail />} />
        <Route
          path="/Branch/:id"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<BranchDetail />}
            />
          }
        />
        <Route
          path="/Company/:id"
          element={
            <RequireAuth
              allowedRoles={["superAdmin"]}
              element={<CompanyDetail />}
            />
          }
        />
        <Route path="/Debt/:id" element={<DebtDetail />} />
        <Route
          path="/DaxilaBranch/:id"
          element={
            <RequireAuth
              allowedRoles={["superAdmin", "branchSuperAdmin"]}
              element={<DaxilaBranch />}
            />
          }
        />
        <Route
          path="/ExpensesBranch/:id"
          element={
            <RequireAuth
              allowedRoles={["superAdmin", "branchSuperAdmin", "branchAdmin"]}
              element={<ExpensesBranch />}
            />
          }
        />

        {/*Add routes */}
        <Route
          path="/AddUsers"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<AddUsers />} />
          }
        />
        <Route
          path="/AddItems"
          element={
            <RequireAuth allowedRoles={["superAdmin"]} element={<AddItems />} />
          }
        />
        <Route path="/AddPurchase" element={<AddPurchase />} />
      </Route>

      {/*Public  routes*/}
      <Route path="/Login" element={<Login />} />
      <Route path="/Signup" element={<Signup />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/Unauthorized" element={<Unauthorized />} />
      <Route path="/ServerError" element={<ServerError />} />
    </Routes>
  );
};

export default AppRoute;
