import React from "react";
import Add from "../components/Add";
import { addFields } from "../constants/Data";
import imageUrl from "../assets/images/Shopping bag-rafiki.png";
import { useGetResourceQuery } from "../features/api/apiSlice";
const AddPurchase = () => {
  const limit = 1000000;
  const { data: customers, isLoading } = useGetResourceQuery(
    `/customers?limit=${limit}`
  );
  const { data: branches } = useGetResourceQuery(`/branches?limit=${limit}`);
  const { data: items } = useGetResourceQuery(`/items?limit=${limit}`);

  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";
  let branch = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
      branch = userData.branch; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }
  const { data: branchItems } = useGetResourceQuery(`/branches/${branch}`);
  const itemsInBranch = branchItems?.data?.data?.items;

  return (
    <div>
      <Add
        pageTitle={"زیادکردنی فرۆش"}
        _query={"purchases"}
        _textFields={addFields[0].purchaseFields}
        imageUrl={imageUrl}
        customerOptions={customers}
        branchesOptions={branches}
        items={branch ? itemsInBranch : items}
        _role={role}
        _branch={branch}
        _printButton={true}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddPurchase;
