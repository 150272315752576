import React from "react";
import Table from "../components/Table";
import {
  TableDropDowns,
  TableHeaders,
  addModalFields,
  editFields,
} from "../constants/Data";
const Owners = () => {
  const errorNames = [{ name: "name" }];

  return (
    <div>
      <Table
        pageTitle={"بەڕێوبەرەکان"}
        searchName={"بەڕێوبەرەکان"}
        head={TableHeaders[0].ownersHeader}
        sortData={TableDropDowns[0].customerDropDowns}
        _textfield={addModalFields[0].ownersFields}
        query={"owners"}
        _editFields={editFields[0].ownersFields}
        _addButton={true}
        errorNames={errorNames}
      />
    </div>
  );
};

export default Owners;
