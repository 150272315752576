import React, { useState } from "react";
import Logo from "../assets/images/logo.png";
import { useGetResourceQuery } from "../features/api/apiSlice";
import { TableHeaders } from "../constants/Data";
import CustomerTables from "./CustomerTables";
const CustomerPrint = React.forwardRef((props, ref) => {
  // props
  const { _printData } = props;
  //states
  const [isPrintMode] = useState(false);
  //API calls

  const { data: currency } = useGetResourceQuery(`/currencies`);

  const totalPriceInDollar = _printData.totalPrice;
  const totalPriceInDinar =
    totalPriceInDollar * (currency?.data?.data[0]?.dinar || 0);

  const tableConfigs = [
    {
      tableTitle: "فرۆشەکان",
      data: _printData?.purchases,
      head: TableHeaders[0].customerPurchasePrint,
    },
    {
      tableTitle: "قەرزەکان",
      data: _printData?.debts,
      head: TableHeaders[0].customerDebtPrint,
    },
    {
      tableTitle: "گەڕاندنەوەی قەرز",
      data: _printData?.allPayDebts,
      head: TableHeaders[0].customerPayDebtPrint,
      printButton: true,
    },
  ];
  return (
    <div
      dir="rtl"
      ref={ref}
      className={`w-full print:block p-3 px-10 ${
        isPrintMode ? "print:block" : "hidden"
      }`}
      style={{ fontFamily: "k24-bold" }}
    >
      {/*Header */}
      <div className="w-full border-black border-2  flex justify-between items-center px-7 py-5">
        <div className="flex flex-col gap-2 items-center">
          <h1 className="text-center ">
            کۆمپانیای ڕۆژی عێراق <br />
            بۆ بازرگانی گشتی سنوردار
          </h1>
          <h1 dir="ltr" className="text-center">
            ڕێبین <br /> 0770 0240404
          </h1>
        </div>

        <div className="flex justify-center items-center flex-col gap-2">
          <img src={Logo} alt="logo" className="w-24 h-28" />
          <h1 className="text-red-500"> HLA تاکە بریکاری کۆمپانیای </h1>
          <h1 className="text-primary">خاوەنی : دانا حاجی محمد پێنجوێنی</h1>
        </div>
        <div className="flex justify-start flex-col gap-2">
          <h1 className="text-center ">
            شركة روجي العراق <br />
            للتجارة العامة المحدودة
          </h1>
          <h1 dir="ltr" className="text-center">
            ڕێباز <br /> 0770 0230303 <br /> 0750 0230303
          </h1>
        </div>
      </div>
      {/*Customer Info */}
      <div className="pt-5 text-red-500">
        <h1>کەشف حساب</h1>
      </div>
      <div className="flex flex-row justify-between items-center my-5 border border-black h-10">
        <div className="flex items-center gap-3 text-md ">
          <p className="border border-black border-y-0  border-r-0 py-2 px-4">
            بەڕێز
          </p>
          <p className="px-4">{_printData?.customer}</p>
        </div>
        <div className="flex items-center">
          <p className="border border-black border-y-0 py-2 px-4">له</p>
          <p className="px-4">{_printData?.startDate}</p>
        </div>
        <div className="flex  items-center">
          <p className="border border-black border-y-0 py-2 px-4">بۆ</p>
          <p className="px-4">{_printData?.endDate}</p>
        </div>
      </div>

      {/*Body */}
      {_printData &&
        tableConfigs.map((config, index) => (
          <CustomerTables
            key={index}
            tableTitle={config.tableTitle}
            data={config.data}
            head={config.head}
          />
        ))}
    </div>
  );
});

export default CustomerPrint;
