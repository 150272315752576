import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import {
  useCreateResourceMutation,
  useGetResourceQuery,
} from "../features/api/apiSlice";
import { useLocation } from "react-router-dom";
const PrintUi = React.forwardRef((props, ref) => {
  const location = useLocation();
  const path = location.pathname;
  // props
  const {
    _footer,
    _printData,
    printType,
    header,
    bodyFooter,
    purchaseBody,
    payDebtBody,
  } = props;
  //states
  const [isPrintMode] = useState(false);
  //API calls
  const { data: currency } = useGetResourceQuery(`/currencies`);

  const totalPriceInDollar = _printData.totalPrice;
  const totalPriceInDinar =
    totalPriceInDollar * (currency?.data?.data[0]?.dinar || 0);
  const customerInfo = [
    {
      id: 1,
      title: "کڕیار",
      data: _printData?.customer,
    },
    {
      id: 2,
      title: "مۆبایل",
      data: _printData?.phone,
    },
    {
      id: 3,
      title: "ناونیشان",
      data: _printData?.location,
    },
  ];

  // functions
  function formatDateToInputValue(date) {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = date.toLocaleString("en-IQ", options);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedCustomDate = `${day}/${month}/${year} ${formattedDate}`;

    return formattedCustomDate;
  }

  const tableRows = (_printData.items || []).map((dataItem, rowIndex) => {
    const includedFields = header.map((item) => {
      return dataItem[item.field];
    });
    return includedFields;
  });

  const footerClass =
    _printData.items?.length > 15 ? "" : "absolute bottom-3 right-3 left-3";

  return (
    <div
      dir="rtl"
      ref={ref}
      className={`w-full print:block p-3 ${
        isPrintMode ? "print:block" : "hidden"
      }`}
      style={{ fontFamily: "k24-bold" }}
    >
      {/*Header */}
      <div className="w-full border-black border-2  flex justify-between items-center px-7 py-5">
        <div className="flex flex-col gap-2 items-center">
          <h1 className="text-center ">
            کۆمپانیای ڕۆژی عێراق <br />
            بۆ بازرگانی گشتی سنوردار
          </h1>
          <h1 dir="ltr" className="text-center">
            ڕێبین <br /> 0770 0240404
          </h1>
        </div>

        <div className="flex justify-center items-center flex-col gap-2">
          <img src={Logo} alt="logo" className="w-24 h-28" />
          <h1 className="text-red-500"> HLA تاکە بریکاری کۆمپانیای </h1>
          <h1 className="text-primary">خاوەنی : دانا حاجی محمد پێنجوێنی</h1>
        </div>
        <div className="flex justify-start flex-col gap-2">
          <h1 className="text-center ">
            شركة روجي العراق <br />
            للتجارة العامة المحدودة
          </h1>
          <h1 dir="ltr" className="text-center">
            ڕێباز <br /> 0770 0230303 <br /> 0750 0230303
          </h1>
        </div>
      </div>
      {/*Customer Info */}
      <div className="w-full border-black border-2 border-t-0  flex justify-between px-7 py-5">
        <div className="flex w-full justify-between items-center">
          <div className="">
            {customerInfo.map((customer, idx) => (
              <div key={idx} className="">
                <h1>
                  {customer.title} : {customer.data}
                </h1>
              </div>
            ))}
          </div>
          <div className="">
            <h1 dir="ltr">جۆری پسوڵە : {printType}</h1>
            <h1 dir="ltr">پسوڵە : {_printData.code}</h1>
            <h1 dir="ltr"> {formatDateToInputValue(new Date())} : بەروار</h1>
          </div>
        </div>
      </div>
      {/*Body */}
      <div className="w-full  flex justify-center pt-5">
        <table className="w-full text-center border-collapse">
          <thead className="bg-gray-300">
            <tr>
              {header.map((item) => (
                <th
                  scope="col"
                  className="border border-black px-4 py-2"
                  key={item.id}
                >
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {purchaseBody &&
              tableRows.map((_data, index) => (
                <tr key={index}>
                  {Object.entries(_data).map(([key, value], colIndex) => (
                    <td
                      key={colIndex}
                      className="border border-black px-4 py-2"
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            {payDebtBody && (
              <tr>
                <td className="border border-black px-4 py-2">
                  ${_printData.pay}
                </td>
                <td className="border border-black px-4 py-2">
                  {_printData.discount}
                </td>
                <td className="border border-black px-4 py-2">
                  {_printData.description}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {bodyFooter && (
        <div className="w-full mt-4 flex items-center">
          <div className="grid grid-cols-2">
            <div className="col-span-1">
              <div className="p-2 flex items-center gap-2">
                <h1 className="text-right">کۆی پسوڵه به دۆلار : </h1>
                <h1 className="text-right">
                  {totalPriceInDollar && totalPriceInDollar.toFixed(2)}
                </h1>
              </div>
              <div className="p-2 flex items-center gap-2">
                <h1 className="text-right ">داشکاندن : </h1>
                <h1 className="text-right ">{_printData.discount || 0}</h1>
              </div>

              <div className="p-2 flex items-center gap-2">
                <h1 className="text-right ">ڕەسیدی ئەم پسوڵەیە : </h1>
                <h1 className="text-right ">{_printData?.debt || 0}</h1>
              </div>
              <div className="p-2 flex items-center gap-2">
                {_printData.pervDebtInvoice === 0 ? (
                  ""
                ) : (
                  <>
                    <h1 className="text-right">کۆی گشتی ڕەسید : </h1>
                    <h1 className="text-right">
                      {_printData.pervDebtInvoice === -1
                        ? _printData.debt
                        : _printData.pervDebtInvoice + _printData.debt}
                    </h1>
                  </>
                )}
              </div>
            </div>
            <div className="col-span-1">
              <div className="p-2 flex items-center gap-2">
                <h1 className="text-right">کۆی پسوڵه به دینار : </h1>
                <h1 className="text-right">{totalPriceInDinar.toFixed(2)}</h1>
              </div>
              <div className="p-2 flex items-center gap-2">
                <h1 className="text-right ">بڕی واسڵکراو : </h1>
                <h1 className="text-right ">{_printData.cash || 0}</h1>
              </div>
              <div className="p-2 flex items-center gap-2">
                {_printData.pervDebtInvoice === 0 ? (
                  ""
                ) : (
                  <>
                    <h1 className="text-right "> ڕەسید پێش ئەم پسوڵەیە : </h1>
                    <h1 className="text-right ">
                      {_printData.pervDebtInvoice === -1
                        ? 0
                        : _printData.pervDebtInvoice}
                    </h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="py-3 px-1">
        {printType === "وەرگرتنەوەی پارە" && (
          <p> باقیاتی قەرز : ${_printData?.remainDebt}</p>
        )}
      </div>
      {/*Footer */}
      {_footer && (
        <div className={footerClass}>
          <div className="w-full border-black border-2  flex justify-between px-7 py-5 ">
            <div className="flex flex-col items-start">
              <h1>
                لقی یەکەم/ ژاڵە - کۆگاکانی ژوری بازرگانی - کۆگای ژمارە ٥٢١
              </h1>
              <h1>لقی سێیەم/ شەقامی کاوە - کۆڵانی دارتاشەکان</h1>
            </div>
            <div className="flex">
              <h1>لقی دووەم/ شەقامی کاوە - بازاری خانی تازە</h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default PrintUi;
