import React from "react";
import Table from "../../components/Table";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../../constants/Data";

const Companies = () => {
  return (
    <div>
      <Table
        pageTitle={"کۆمپانیاکان"}
        searchName={"کۆمپانیاکان"}
        head={TableHeaders[0].customersTableHeader}
        query={"companies"}
        sortData={TableDropDowns[0].customerDropDowns}
        _textfield={addModalFields[0].companyFields}
        _editFields={editFields[0].customerFields}
        _addButton={true}
        _detailPath={"Company"}
      />
    </div>
  );
};

export default Companies;
