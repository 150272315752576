import React from "react";
import Table from "../components/Table";
import { TableDropDowns, TableHeaders } from "../constants/Data";

const Returns = () => {
  return (
    <div>
      <Table
        pageTitle={"گەڕێندراوەکان"}
        searchName={"گەڕێندراوەکان"}
        head={TableHeaders[0].returnHeader}
        sortData={TableDropDowns[0].customerDropDowns}
        query={"returns"}
      />
    </div>
  );
};

export default Returns;
