import React from "react";

const PageTitle = ({ title }) => {
  return (
    <div className="lg:mx-16 mx-2 lg:mt-16 xl:mt-5 p-4  text-black flex  items-center">
      <h1 className="text-2xl tracking-wide font-bold">{title}</h1>
    </div>
  );
};

export default PageTitle;
