import React from "react";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../../constants/Data";
import Table from "../../components/Table";

const AgentTransferDebts = () => {
  return (
    <div>
      <div className="w-full">
        <Table
          pageTitle={"قەرزی نوسینگەکان"}
          searchName={"قەرزی نوسینگەکان"}
          head={TableHeaders[0].agentsDebtHeader}
          query={"agentTransferDebts"}
          sortData={TableDropDowns[0].customerDropDowns}
          _textfield={addModalFields[0].agentTRansferDebtsField}
          _editFields={editFields[0].agentDebtFields}
          _addButton={true}
          _returnButton={true}
        />
      </div>
    </div>
  );
};

export default AgentTransferDebts;
