function CustomerTables({ data, head, tableTitle }) {
  const rows = data.map((dataItem, rowIndex) => {
    const includedFields = head.map((item) => {
      if (item.field === "createdAt") {
        const date = new Date(dataItem[item.field]);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")} ${date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`;
        return <div dir="ltr">{formattedDate}</div>;
      }
      if (item.field === "items") {
        return dataItem.items
          ? dataItem.items.map((item, index) => (
              <div key={index} class="">
                {item?.item !== null ? item.item?.name : "سڕاوەتەوە"}
              </div>
            ))
          : "سڕاوەتەوە";
      }
      if (item.field === "addedBy") {
        return dataItem.addedBy ? dataItem.addedBy.username : "";
      }

      return dataItem[item.field];
    });
    return includedFields;
  });

  return (
    <div className="">
      <h1 className="text-xl tracking-wide font-bold py-5">{tableTitle}</h1>

      <table className="w-full text-center ">
        <thead className="bg-gray-300">
          <tr>
            {head.map((item) => (
              <th
                scope="col"
                className="border border-black px-4 py-2"
                key={item.id}
              >
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows.map((_data, index) => (
              <tr key={index} className="hover:bg-primary/20">
                {_data.map((value, colIndex) => (
                  <td key={colIndex} className="border border-black px-4 py-2">
                    {value}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={head.length}
                className=" text-primary font-bold py-5 border border-black"
              >
                <span className="flex items-center justify-center text-xl">
                  هیچ داتایەک نیە....
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
export default CustomerTables;
