import { Flowbite, Pagination } from "flowbite-react";
import React, { useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";

const SelectedItemsTable = ({ itemsData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Calculate total pages
  const totalPages = Math.ceil(itemsData.length / itemsPerPage);

  // Get current items for the page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = itemsData.slice(startIndex, startIndex + itemsPerPage);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const customTheme = {
    base: "",
    layout: {
      table: {
        base: "text-sm text-gray-700 dark:text-gray-400",
        span: "font-semibold text-gray-900 dark:text-white",
      },
    },
    pages: {
      base: "xs:mt-0 mt-2 inline-flex items-center -space-x-px",
      previous: {
        base: "ml-0 border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        icon: "h-5 w-5",
      },
      next: {
        base: "border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        icon: "h-5 w-5",
      },
    },
  };

  return (
    <div>
      {currentItems.length > 0 && (
        <>
          <h1 className="py-5">ماددە دیاری کراوەکان</h1>
          <table className="w-full text-center">
            <thead className={`bg-primary sticky top-0`}>
              <tr>
                <th scope="col" className="px-2 py-3 text-xl text-white">
                  ناو
                </th>
                <th scope="col" className="px-2 py-3 text-xl text-white">
                  دانە
                </th>
                <th scope="col" className="px-2 py-3 text-xl text-white">
                  نرخی فرۆشتن
                </th>
                <th scope="col" className="px-2 py-3 text-xl text-white">
                  کۆی نرخ
                </th>
                <th scope="col" className="px-2 py-3 text-xl text-white">
                  کردار
                </th>
              </tr>
            </thead>
            <tbody className="bg-white rounded-md">
              {currentItems.map((item) => (
                <tr key={item.item_id} className="bg-white border-primary">
                  <td className="p-4 border-b border-blue-gray-50 text-xl ">
                    {item.item_name}
                  </td>
                  <td className="p-4 border-b border-blue-gray-50 text-xl ">
                    {item.quantity}
                  </td>
                  <td className="p-4 border-b border-blue-gray-50 text-xl ">
                    {item.sell_price}
                  </td>
                  <td className="p-4 border-b border-blue-gray-50 text-xl ">
                    {item.quantity * item.sell_price}
                  </td>
                  <td className="p-4 border-b border-blue-gray-50 text-xl ">
                    <button
                      onClick={() => console.log(`Delete ${item.item_id}`)}
                      className="cursor-pointer text-red-600 px-2"
                    >
                      سڕینەوە
                    </button>
                    <button
                      onClick={() => console.log(`Edit ${item.item_id}`)}
                      className="ml-4 cursor-pointer text-blue-600"
                    >
                      نوێکردنەوە
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {/* Pagination Controls */}
      <div className="bg-gray-200 mt-4" dir="rtl">
        <div className="flex justify-center py-4">
          <Flowbite theme={{ theme: customTheme }}>
            <Pagination
              layout="pagination"
              previousLabel={<MdArrowForward size={20} />}
              nextLabel={<MdArrowBack size={20} />}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Flowbite>
        </div>
      </div>
    </div>
  );
};

export default SelectedItemsTable;
