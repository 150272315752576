import React from "react";
import Table from "../components/Table";
import {
  TableDropDowns,
  TableHeaders,
  addModalFields,
  editFields,
} from "../constants/Data";

const Expenses = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";
  let branch = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
      branch = userData.branch; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }
  let fields = addModalFields[0].expensesFields;
  if (role === "branchSuperAdmin") {
    fields = fields.filter((headerItem) => headerItem.name !== "branch");
  }
  let editField = editFields[0].expensesFields;
  if (role === "branchSuperAdmin") {
    editField = editField.filter((headerItem) => headerItem.name !== "branch");
  }
  return (
    <div>
      <Table
        _addButton={true}
        pageTitle={"خەرجیەکان"}
        searchName={"خەرجیەکان"}
        head={TableHeaders[0].expensesHeader}
        sortData={TableDropDowns[0].expensesDropDown}
        _textfield={fields}
        query={"expenses"}
        _editFields={editField}
      />
    </div>
  );
};

export default Expenses;
