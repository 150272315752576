import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import CustomerPrint from "./CustomerPrint";

const DateButtons = ({
  onSubmit,
  formData,
  onChange,
  _printButton,
  printData,
}) => {
  const componnentRef = useRef();
  return (
    <div>
      <div className="lg:py-5 lg:px-8 my-8 py-3 px-4 mx-2  xl:mx-16 bg-primary rounded flex flex-col lg:flex-row justify-between text-white shadow ">
        <form className="flex flex-col md:flex-row w-full gap-5 text-black ">
          <div className="flex gap-2">
            <div className="flex flex-col gap-2">
              <label className="text-white">بەرواری دەستپێک</label>
              <input
                className="w-full p-2 border rounded"
                type="date"
                value={formData.startDate}
                onChange={(e) => onChange("startDate", e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-white">بەرواری کۆتای </label>
              <input
                className="w-full p-2 border rounded"
                type="date"
                value={formData.endDate}
                onChange={(e) => onChange("endDate", e.target.value)}
              />
            </div>
          </div>
          <button
            className="border border-white  p-2 rounded-md mt-[32px] text-white hover:scale-90 transition-all duration-200   "
            type="button" // Change to "button" to avoid form submission
            onClick={onSubmit} // Trigger form submit manually
          >
            دیاری بکە
          </button>
        </form>
        <div className="w-full lg:w-48">
          {_printButton && (
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    type="button"
                    className="border w-full border-white  p-2 rounded-md mt-[32px] text-white hover:scale-90 transition-all duration-200   "
                  >
                    پرینت کردن
                  </button>
                );
              }}
              content={() => componnentRef.current}
            />
          )}
          {_printButton && (
            <CustomerPrint ref={componnentRef} _printData={printData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateButtons;
