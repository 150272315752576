import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  setCredentials,
  logOut,
  selectCurrentToken,
} from "../../features/auth/authSlice";

// "https://rozhiiraq-backend-subscription-a6gjaahmddcgcsaa.westeurope-01.azurewebsites.net/api/v1",
const baseQuery = fetchBaseQuery({
  baseUrl:
    "https://rozhiiraq-backend-subscription-a6gjaahmddcgcsaa.westeurope-01.azurewebsites.net/api/v1",

  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = selectCurrentToken(getState());
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 403) {
    console.log("sending refresh token");
    const refreshResult = await baseQuery("/refresh", api, extraOptions);
    console.log(refreshResult);

    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createResource: builder.mutation({
      query: ({ url, body, formData }) => ({
        url,
        method: "POST",
        body: body ? body : formData,
        formData: formData ? true : false,
      }),
      invalidatesTags: [{ type: "Resource" }],
    }),
    getResource: builder.query({
      query: (url) => url,
      providesTags: [{ type: "Resource" }],
    }),
    updateResource: builder.mutation({
      query: ({ url, body, formData }) => ({
        url,
        method: "PATCH",
        body: body ? body : formData,
        formData: formData ? true : false,
      }),
      invalidatesTags: [{ type: "Resource" }],
    }),
    deleteResource: builder.mutation({
      query: (url) => ({
        url,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Resource" }],
    }),
  }),
});

export const {
  useCreateResourceMutation,
  useGetResourceQuery,
  useUpdateResourceMutation,
  useDeleteResourceMutation,
} = apiSlice;
