import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetResourceQuery } from "../features/api/apiSlice";
import { TbTournament } from "react-icons/tb";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import PageTitle from "../components/PageTitle";
import DetailTable from "../components/DetailTable";
import { BsFillPersonFill } from "react-icons/bs";
import { ItemNameIcon, LoadingIcon } from "../constants/Icons";
import { TableHeaders } from "../constants/Data";

const DebtDetail = () => {
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }

  let Header = TableHeaders[0].customersTableHeader;

  const { id } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const {
    data: debtData,
    error,
    isLoading: loading,
  } = useGetResourceQuery(
    `/debts/${id}?search=${searchQuery}&sort=${sortQuery}&page=${page}&limit=${itemsPerPage}`
  );

  if (loading)
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  if (error) return <p>Error loading customer data</p>;

  const debtInfo = debtData && debtData.data?.data;
  const { customer, branch, debt_price, debt_amount, payes } = debtInfo;

  const customerInfo = [
    { id: 1, icon: <BsFillPersonFill />, data: customer.name },
    { id: 2, icon: <FaPhone />, data: customer.phoneNumber },
    { id: 3, icon: <FaLocationDot />, data: customer.address },
  ];
  let branchInfo = [];
  if (role !== "branchSuperAdmin" && branch) {
    branchInfo = [
      { id: 1, icon: <TbTournament />, data: branch.name },
      { id: 2, icon: <FaLocationDot />, data: branch.address },
    ];
  }

  const debtAmounts = [
    {
      id: 1,
      title: "کۆی گشتی قەرز",
      icon: <ItemNameIcon />,
      description: debt_price,
    },
    {
      id: 2,
      title: "کۆی گشتی قەرزی ماوە",
      icon: <ItemNameIcon />,
      description: debt_amount,
    },
  ];
  return (
    <div className="w-full">
      <PageTitle title={"زانیاری قەرز"} />
      <div className="flex flex-col lg:flex-row justify-between  lg:py-5 lg:px-8 my-8 py-3 px-4 mx-2 lg:mx-   2xl:mx-16 ">
        <div className="flex justify-between  w-full p-5">
          <div className="flex items-center">
            <h1 className="text-2xl">زانیاری کڕیار : </h1>
            {customerInfo.map((item) => (
              <div key={item.id} className="flex items-center px-5 text-2xl">
                <div className="ml-2  text-primary">{item.icon}</div>
                <h1 className="">{item.data}</h1>
              </div>
            ))}
          </div>
          {role !== "branchSuperAdmin" && branch && (
            <div className="flex items-center">
              <h1 className="text-2xl">زانیاری لق : </h1>

              {branchInfo.map((item) => (
                <div key={item.id} className="flex items-center px-5 text-2xl">
                  <div className="ml-2  text-primary">{item.icon}</div>
                  <h1 className="">{item.data}</h1>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className=" lg:py-5 lg:px-8 my-8 py-3 px-4 mx-2 lg:mx-12 2xl:mx-16 flex ">
        {debtAmounts.map((debt) => (
          <div key={debt.id} className="flex  items-center px-5 text-2xl">
            <h1 className="ml-2">{debt.title} : </h1>
            <p className="">{debt.description}</p>
          </div>
        ))}
      </div>
      <div className=" mx-2 lg:mx-12 2xl:mx-16 pb-20 ">
        <DetailTable
          searchName={"گەڕاندنەوەکان"}
          tableTitle={"گەڕاندنەوەکان"}
          data={payes}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          sortQuery={sortQuery}
          setSortQuery={setSortQuery}
          page={page}
          setPage={setPage}
          head={TableHeaders[0].payesDetailHeader}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </div>
  );
};

export default DebtDetail;
