import AppRoute from "./Routes/AppRoute";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div
      style={{ fontFamily: "k24-bold" }}
      dir="rtl"
      className="w-full h-screen "
    >
      <Toaster />
      <AppRoute />
    </div>
  );
}

export default App;
