import React, { useState } from "react";
import {
  TableDropDowns,
  TableHeaders,
  addModalFields,
  editFields,
} from "../../constants/Data";
import { useParams } from "react-router-dom";
import { useGetResourceQuery } from "../../features/api/apiSlice";
import DetailTable from "../../components/DetailTable";
import Table from "../../components/Table";

const DaxilaBranch = () => {
  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);

  const {
    data: debtData,
    error,
    isLoading: loading,
  } = useGetResourceQuery(`/daxilaBranch?branch=${id}`);
  if (loading) return <p>Loading...</p>;
  if (error) return <p className="pt-20">{error?.data?.message}</p>;

  const totalDaxilaPrice = debtData && debtData.data?.totalDaxilaPrice;
  const data = debtData && debtData.data?.data;

  return (
    <div className="w-full ">
      <div className=" mx-2 lg:mx-12 2xl:mx-16 pb-20 ">
        <Table
          _totalItem={true}
          _addButton={true}
          actionButtonText={"بەکارهێنان"}
          pageTitle={"دەخیلە"}
          searchName={"دەخیلە"}
          query={"daxilaBranch"}
          head={TableHeaders[0].daxilaHeader}
          sortData={TableDropDowns[0].customerDropDowns}
          _textfield={addModalFields[0].daxilaFields}
          _editFields={editFields[0].daxilaFields}
        />
        
      </div>
    </div>
  );
};

export default DaxilaBranch;
