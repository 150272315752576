import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { MdAccountCircle, MdExpandMore, MdExpandLess } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logOut } from "../features/auth/authSlice";
import {
  CustomersIcon,
  DashbordIcon,
  DaxilaIcon,
  DebtIcon,
  DolarIcon,
  ExpensesIcon,
  ItemsIcon,
  NotficationIcon,
  PurchasesIcon,
  ReturnsIcon,
  SignOut,
  YuandIcon,
} from "../constants/Icons";
import { useGetResourceQuery } from "../features/api/apiSlice";
import EditModal from "../components/EditModal";
import { editFields } from "../constants/Data";
import { IoIosArrowBack } from "react-icons/io";

const Slidebar = ({ sideBarLinks }) => {
  const userDataFromLocalStorage = localStorage.getItem("user");
  const notficationNumber = localStorage.getItem("notfication") || 0;

  let branch = "";
  let role = "";
  let userId = "";
  let username = "";
  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role;
      branch = userData.branch;
      userId = userData._id;
      username = userData.username;
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }

  const adminLinks = [
    {
      id: 1,
      name: "کڕیارەکان",
      link: "/Customers",
      icon: <CustomersIcon fill={"currentColor"} />,
    },
    // {
    //   id: 2,
    //   name: "ماددەکان",
    //   link: "/Items",
    //   icon: <ItemsIcon fill={"currentColor"} />,
    // },
    {
      id: 2,
      name: "فرۆش",
      link: "/Purchases",
      icon: <PurchasesIcon fill={"currentColor"} />,
      subLinks: [
        {
          id: 41,
          name: "هەموو فرۆشەکان",
          link: "/Purchases",
          icon: <IoIosArrowBack />,
        },
        {
          id: 42,
          name: "زیادکردنی فرۆش",
          link: "/AddPurchase",
          icon: <IoIosArrowBack />,
        },
      ],
    },
    {
      id: 3,
      name: "قەرزەکان",
      link: "/Debts",
      icon: <DebtIcon fill={"currentColor"} />,
    },
    // {
    //   id: 5,
    //   name: "خەرجیەکان",
    //   link: "/Expenses",
    //   icon: <ExpensesIcon />,
    // },
    {
      id: 4,
      name: `ئاگاداریەکان `, // Display notification number
      link: "/Notfications",
      icon: <NotficationIcon fill={"currentColor"} />,
    },
    {
      id: 5,
      name: "گەڕاندنەوەکان",
      link: "/Returns",
      icon: <ReturnsIcon fill={"currentColor"} />,
    },
  ];

  const branchSuperAdminLinks = [
    {
      id: 1,
      name: "داشبۆرد",
      link: `/BranchDashboard/${branch}`,
      icon: <DashbordIcon />,
    },
    {
      id: 2,
      name: "کڕیارەکان",
      link: "/Customers",
      icon: <CustomersIcon fill={"currentColor"} />,
    },
    {
      id: 3,
      name: "ماددەکان",
      link: "/BranchItems",
      icon: <ItemsIcon fill={"currentColor"} />,
    },
    {
      id: 4,
      name: "فرۆش",
      link: "#",
      icon: <PurchasesIcon fill={"currentColor"} />,
      subLinks: [
        {
          id: 41,
          name: "هەموو فرۆشەکان",
          link: "/Purchases",
          icon: <IoIosArrowBack />,
        },
        {
          id: 42,
          name: "زیادکردنی فرۆش",
          link: "/AddPurchase",
          icon: <IoIosArrowBack />,
        },
      ],
    },
    {
      id: 5,
      name: "قەرزەکان",
      link: "/Debts",
      icon: <DebtIcon fill={"currentColor"} />,
    },
    {
      id: 6,
      name: "خەرجیەکان",
      link: `/ExpensesBranch/${branch}`,
      icon: <ExpensesIcon />,
    },
    {
      id: 7,
      name: "دەخیلە",
      link: `/DaxilaBranch/${branch}`,
      icon: <DaxilaIcon />,
    },
    {
      id: 8,
      name: "ئاگاداریەکان",
      link: "/Notfications",
      icon: <NotficationIcon fill={"currentColor"} />,
    },
    {
      id: 9,
      name: "گەڕاندنەوەکان",
      link: "/Returns",
      icon: <ReturnsIcon fill={"currentColor"} />,
    },
  ];
  const branchAdminLinks = [
    {
      id: 1,
      name: "کڕیارەکان",
      link: "/Customers",
      icon: <CustomersIcon fill={"currentColor"} />,
    },
    {
      id: 2,
      name: "ماددەکان",
      link: "/BranchItems",
      icon: <ItemsIcon fill={"currentColor"} />,
    },
    {
      id: 3,
      name: "فرۆش",
      link: "#",
      icon: <PurchasesIcon fill={"currentColor"} />,
      subLinks: [
        {
          id: 41,
          name: "هەموو فرۆشەکان",
          link: "/Purchases",
          icon: <IoIosArrowBack />,
        },
        {
          id: 42,
          name: "زیادکردنی فرۆش",
          link: "/AddPurchase",
          icon: <IoIosArrowBack />,
        },
      ],
    },
    {
      id: 4,
      name: "قەرزەکان",
      link: "/Debts",
      icon: <DebtIcon fill={"currentColor"} />,
    },
    {
      id: 5,
      name: "خەرجیەکان",
      link: `/ExpensesBranch/${branch}`,
      icon: <ExpensesIcon />,
    },
    {
      id: 6,
      name: "ئاگاداریەکان",
      link: "/Notfications",
      icon: <NotficationIcon fill={"currentColor"} />,
    },
    {
      id: 7,
      name: "گەڕاندنەوەکان",
      link: "/Returns",
      icon: <ReturnsIcon fill={"currentColor"} />,
    },
  ];
  const [nav, setNav] = useState(false);
  const [route, setRoute] = useState("");
  const [openSubMenus, setOpenSubMenus] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [_id, setId] = useState("");

  const sidebarRef = useRef();
  const location = useLocation();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleEdit = async (id) => {
    setEditModal(true);
    setId(id);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setNav(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    const path = location.pathname;
    let currentLink = sideBarLinks.find((item) => item.link === path);

    if (!currentLink) {
      sideBarLinks.forEach((item) => {
        if (item.subLinks) {
          const currentSubLink = item.subLinks.find(
            (subLink) => subLink.link === path
          );
          if (currentSubLink) {
            currentLink = currentSubLink;
            setOpenSubMenus((prev) => ({ ...prev, [item.id]: true }));
          }
        }
      });
    }

    if (currentLink) {
      setRoute(currentLink.link);
    }
  }, [location.pathname, sideBarLinks]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/login");
  };

  const toggleSubMenu = (id) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const { data } = useGetResourceQuery(`/currencies`);
  let dinar = "";
  let dollar = "";
  let yuan = "";
  let id = "";
  if (data && data.data && data.data?.data[0]) {
    dinar = data.data?.data[0].dinar;
    dollar = data.data?.data[0].dollar;
    yuan = data.data?.data[0].yuan;
    id = data.data?.data[0]._id;
  }
  const currency = [
    {
      id: 1,
      text: "نرخی دۆلار :",
      name: ` ${dollar}`,
      icon: <DolarIcon />,
    },
    {
      id: 2,
      text: "نرخی دینار :",
      name: ` ${dinar}`,
      icon: "IQD",
    },
    {
      id: 3,
      text: "نرخی یۆن :",
      name: ` ${yuan}`,
      icon: <YuandIcon />,
    },
  ];

  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (role === "admin") {
      setLinks(adminLinks);
    } else if (role === "branchSuperAdmin") {
      setLinks(branchSuperAdminLinks);
    } else if (role === "branchAdmin") {
      setLinks(branchAdminLinks);
    } else {
      setLinks(sideBarLinks);
    }
  }, [role, branch]);
  return (
    <div>
      <header className="fixed top-0 left-0 right-0 z-50  bg-primary flex items-center p-6 w-full ">
        <div className="flex  items-center w-full">
          <div className="hidden md:block">
            <NavLink
              to={`/Profile/${userId}`}
              onClick={() => {
                setRoute("Profile");
                setNav(false);
              }}
              className="flex justify-start items-center cursor-pointer"
            >
              <div className="relative size-8 rounded-full overflow-hidden border-2">
                <MdAccountCircle className="w-full h-full object-cover text-white" />
              </div>
              <h1 className="mr-2 text-md font-bold text-white">{username}</h1>
            </NavLink>
          </div>

          <div
            className={`flex justify-center items-center w-full gap-2 lg:gap-10 ${
              role === "branchSuperAdmin" || role === "admin"
                ? "pointer-events-none cursor-not-allowed"
                : ""
            }`}
          >
            {currency.map((cur, idx) => (
              <h1
                key={idx}
                className="text-white/80 hover:text-white hover:scale-110 duration-300 transition-all ease-in-out text-xl lg:text-2xl flex items-center cursor-pointer"
                onClick={() => handleEdit(id)}
              >
                {cur.text}
                <p className="  px-1 lg:px-3 lg:text-2xl flex items-center">
                  {cur.name} <p className="hidden md:block"> {cur.icon}</p>
                </p>
              </h1>
            ))}

            {editModal && (
              <EditModal
                onCancel={() => setEditModal(false)}
                _editFields={editFields[0].currencyFields}
                initialData={data}
                id={_id}
                _query={"currencies"}
              />
            )}
          </div>
        </div>
      </header>
      <button
        onClick={handleNav}
        type="button"
        className="inline-flex items-center pt-24 px-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      <aside
        ref={sidebarRef}
        className={
          nav
            ? "fixed top-16 right-0 z-40 w-64 h-screen transition-transform md:translate-x-0"
            : "fixed top-16 right-0 z-40 w-64 h-screen transition-transform translate-x-full lg:translate-x-0"
        }
      >
        <div className="h-full flex flex-col justify-between px-3 py-4 overflow-y-auto bg-primary">
          <div className="block md:hidden">
            <NavLink
              to={`/Profile/${userId}`}
              onClick={() => {
                setRoute("Profile");
                setNav(false);
              }}
              className="flex justify-start items-center cursor-pointer"
            >
              <div className="relative size-8 rounded-full overflow-hidden border-2">
                <MdAccountCircle className="w-full h-full object-cover text-white" />
              </div>
              <h1 className="mr-2 text-md font-bold text-white">{username}</h1>
            </NavLink>
          </div>

          <ul className="space-y-4 font-medium">
            <hr className="w-full h-[1px] rounded-xl bg-secondary" />
            {links.map((item, index) => (
              <div key={index}>
                <div
                  onClick={() => {
                    if (item.subLinks) {
                      toggleSubMenu(item.id);
                    } else {
                      setRoute(item.link);
                      setNav(false);
                      navigate(item.link);
                    }
                  }}
                  className={`relative group flex w-full items-center p-2 text-white rounded-lg hover:bg-white hover:text-black cursor-pointer ${
                    route === item.link ? "bg-white text-black" : ""
                  }`}
                >
                  <span
                    className={`w-6 h-6 transition duration-75 ${
                      route === item.link
                        ? "text-black"
                        : "text-white group-hover:text-black"
                    }`}
                  >
                    {item.icon}
                  </span>

                  <span
                    className={`mr-3 text-xl flex-1 ${
                      route === item.link
                        ? "text-black"
                        : "text-white group-hover:text-black"
                    }`}
                  >
                    {item.name}
                  </span>
                  {item.name === "ئاگاداریەکان" && ( // Adjust this condition as needed
                    <div className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 bg-red-600 text-white text-m rounded-full">
                      {notficationNumber}
                    </div>
                  )}
                  {item.subLinks && (
                    <span className="flex items-center">
                      {openSubMenus[item.id] ? (
                        <MdExpandLess className="text-xl" />
                      ) : (
                        <MdExpandMore className="text-xl" />
                      )}
                    </span>
                  )}
                </div>
                {item.subLinks && openSubMenus[item.id] && (
                  <ul className="pl-8 space-y-2">
                    {item.subLinks.map((subLink, subIndex) => (
                      <NavLink
                        key={subIndex}
                        to={subLink.link}
                        onClick={() => {
                          setRoute(subLink.link);
                          setNav(false);
                        }}
                        className={({ isActive }) =>
                          `group flex w-full items-center p-2 text-white rounded-lg hover:bg-white hover:text-black my-3 ${
                            isActive ? "bg-white" : "text-black"
                          }`
                        }
                      >
                        <span
                          className={` transition duration-75 flex item ${
                            route === subLink.link
                              ? "text-black"
                              : "text-white group-hover:text-black"
                          }`}
                        >
                          {subLink.icon}
                        </span>

                        <span
                          className={`mr-3 text-md ${
                            route === subLink.link
                              ? "text-black"
                              : "text-white group-hover:text-black"
                          }`}
                        >
                          {subLink.name}
                        </span>
                      </NavLink>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </ul>
          <ul className="my-16 space-y-2 font-medium border-t border-gray-200">
            <a
              onClick={handleLogout}
              className="flex cursor-pointer items-center gap-2 p-2 text-white rounded-lg hover:bg-gray-100 hover:text-black "
            >
              <SignOut />
              <span className="flex-1 ml-3 whitespace-nowrap">
                چووندە دەرەوە
              </span>
            </a>
          </ul>
        </div>
      </aside>
      <div className="pt-16 lg:mr-64">
        <Outlet />
      </div>
    </div>
  );
};

export default Slidebar;
