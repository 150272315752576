import React from "react";
import {
  TableDropDowns,
  TableHeaders,
  addModalFields,
  editFields,
} from "../constants/Data";
import Table from "../components/Table";

const Daxila = () => {
  return (
    <div className="w-full ">
      <Table
        _totalItem={true}
        _addButton={true}
        actionButtonText={"بەکارهێنان"}
        pageTitle={"دەخیلە"}
        searchName={"دەخیلە"}
        query={"daxila"}
        head={TableHeaders[0].daxilaHeader}
        sortData={TableDropDowns[0].customerDropDowns}
        _textfield={addModalFields[0].daxilaFields}
        _editFields={editFields[0].daxilaFields}
      />
    </div>
  );
};

export default Daxila;
