import React from "react";
import {
  addModalFields,
  editFields,
  TableDropDowns,
  TableHeaders,
} from "../constants/Data";
import Table from "../components/Table";

const Banks = () => {
  const errorNames = [{ name: "name" }];

  return (
    <div>
      <Table
        pageTitle={"بانقەکان"}
        searchName={"بانقەکان"}
        head={TableHeaders[0].bankHeaders}
        query={"banks"}
        _editFields={editFields[0].customerFields}
        _addButton={true}
        _textfield={addModalFields[0].bankfields}
        sortData={TableDropDowns[0].customerDropDowns}
        errorNames={errorNames}
      />
    </div>
  );
};

export default Banks;
